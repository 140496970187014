import {
  CalendarFilled,
  CalendarOutlined,
  FrownOutlined,
  FundOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  MailOutlined,
  MehOutlined,
  PlusOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import BoxStatistic from "./BoxStatistic";
import useEncuestaAnalytics from "../hooks/useEncuestaAnalytics";
import useQuery from "../hooks/useQuery";
import useGroupedData from "../hooks/useGroupedData";
import useEncuesta from "../hooks/useEncuesta";
/*
General Analytics 
*/
export default function BoxList({
  filters = {},
  global = false,
  setFilters = null,
}) {
  const query = useQuery();

  const [filtersValue, setFiltersValue] = useState(global ? {} : filters);
  const idEncuesta =
    query.get("id").length > 12 ? query.get("id") : query.get("id_encuesta");
  const {
    results,
    loading: resultsLoading,
    error: resultsError,
  } = useEncuestaAnalytics(idEncuesta, filtersValue);

  useEffect(() => {
    if (
      JSON.stringify(filtersValue) !== JSON.stringify(filters) &&
      global !== true
    ) {
      setFiltersValue(filters);
    }
  }, [filters]);

  const {
    grouped_data: valoraciones,
    loading: loadingValoraciones,
    error: valoracionesError,
  } = useGroupedData(
    idEncuesta,
    filtersValue,
    "valoracion",
    Object.keys(filters).length === 0 ? true : false
  );
  const {
    encuestaLoaded,
    loading: loadingEncuesta,
    error: errorEncuesta,
  } = useEncuesta(idEncuesta);

  const { media } = results;
  const bien = valoraciones.find((valoracion) => valoracion._id === 5);
  const normal = valoraciones.find((valoracion) => valoracion._id === 3);
  const mal = valoraciones.find((valoracion) => valoracion._id === 1);
  const percentageMal =
    (mal?.count / (results.countCaducadas + results.countRespuestas)) * 100;
  const percentageNormal =
    (normal?.count / (results.countCaducadas + results.countRespuestas)) * 100;
  const percentageBien =
    (bien?.count / (results.countCaducadas + results.countRespuestas)) * 100;

  const preguntas =
    !loadingEncuesta &&
    !errorEncuesta &&
    encuestaLoaded?.preguntas
      ?.map((encuesta) => encuesta.preguntas)
      .flat(Infinity);

  if (errorEncuesta || resultsError || valoracionesError) {
    return (
      <>
        Ha ocurrido un error cargando el resumen {errorEncuesta.toString()}{" "}
        {resultsError.toString()} {valoracionesError.toString()}
      </>
    );
  }
  if (loadingEncuesta) {
    return <b>Cargando...</b>;
  }
  if (global === false && Object.keys(filtersValue).length === 0) {
    return <></>;
  }

  return (
    <>
      {" "}
      {Object.keys(filters).length === 0 && (
        <p className="subtitle">Estadísticas Generales</p>
      )}
      <div className="box-list">
        {global && (
          <>
            {" "}
            <BoxStatistic
              icon={<PlusOutlined className="box-icon" />}
              title="Generadas"
              subtitle={results?.countLanzadas}
              loading={resultsLoading}
            />
            <BoxStatistic
              icon={<MailOutlined className="box-icon" />}
              title="Enviadas"
              subtitle={results?.countEnviadas}
              loading={resultsLoading}
            />
          </>
        )}

        <BoxStatistic
          icon={<InboxOutlined className="box-icon" />}
          title="Respuestas"
          subtitle={results?.countRespuestas}
          loading={resultsLoading}
        />
        <BoxStatistic
          icon={
            filters.caducadas === "2" && global === false ? (
              <CalendarOutlined className="box-icon colRoj" />
            ) : (
              <CalendarOutlined className="box-icon" />
            )
          }
          title="Caducadas"
          subtitle={results?.countCaducadas}
          loading={resultsLoading}
          onClick={
            !global
              ? () => {
                  setFilters((filters) => ({
                    ...filters,
                    caducadas:
                      filters.caducadas && filters.caducadas === "2"
                        ? "1"
                        : "2",
                  }));
                }
              : null
          }
        />
        <BoxStatistic
          icon={<SmileOutlined className="box-icon colVer" />}
          title="Bien"
          subtitle={`${bien?.count ?? 0} (${
            isNaN(percentageBien.toFixed(2)) ? 0 : percentageBien.toFixed(2)
          } %)`}
          onClick={
            !global
              ? () => {
                  setFilters((filters) => ({ ...filters, media: "5" }));
                }
              : null
          }
          loading={resultsLoading && loadingValoraciones}
        />
        <BoxStatistic
          icon={<MehOutlined className="box-icon colNar" />}
          title="Normal"
          subtitle={`${normal?.count ?? 0} (${
            isNaN(percentageNormal.toFixed(2)) ? 0 : percentageNormal.toFixed(2)
          } %)`}
          onClick={
            !global
              ? () => {
                  setFilters((filters) => ({ ...filters, media: "3" }));
                }
              : null
          }
          loading={resultsLoading && loadingValoraciones}
        />
        <BoxStatistic
          icon={<FrownOutlined className="box-icon colRoj" />}
          title="Mal"
          subtitle={`${mal?.count ?? 0} (${
            isNaN(percentageMal.toFixed(2)) ? 0 : percentageMal.toFixed(2)
          } %)`}
          onClick={
            !global
              ? () => {
                  setFilters((filters) => ({ ...filters, media: "1" }));
                }
              : null
          }
          loading={resultsLoading && loadingValoraciones}
        />
        {resultsLoading === false &&
          media?.map((mediaValue, index) => {
            const heading = preguntas?.find(
              (pregunta) => pregunta.id_pregunta === mediaValue._id
            )?.heading;
            return (
              <BoxStatistic
                icon={<FundOutlined className="box-icon" />}
                title={
                  <>
                    {"Media " + (index + 1)}{" "}
                    <InfoCircleOutlined className="info-box" title={heading} />
                  </>
                }
                key={index}
                subtitle={mediaValue?.avg?.toFixed(2)}
                loading={resultsLoading}
              />
            );
          })}
      </div>
    </>
  );
}
