import React, { useEffect } from 'react';
import { Select,  } from 'antd';

const { Option } = Select;

const SelectCaracteristicas = ({ caracteristicas, filtraCaracteristica }) => {

   

    useEffect(() => {}, [ caracteristicas ] )

    function onChange(value) {
        filtraCaracteristica(value)
    }


    return (
        <div>
            <Select mode="tags" style={{ width: 250 }} onChange={onChange} tokenSeparators={[',']}>
                {/* <Option value="all" key="all">Sin filtrar</Option> */}
                 {
                    caracteristicas.map( _caracteristica => {
                        return (
                            <Option value={ _caracteristica.nombre } key={ _caracteristica._id }>{ _caracteristica.nombre }</Option>
                        )
                    })
                }
            </Select>
        </div>
    )
}

export default SelectCaracteristicas;