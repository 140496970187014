
import { Select } from "antd";
import cajache from "cajache";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import apiRequest from "../utils/apiRequest";
// import "./buscadorusuarios.scss";


const {Option} = Select;



/**
 * @typedef defaultUser
 * @property {string} key Key que se usará para buscar el usuario
 * @property {*} value Valor que tendrá que tener la key
*/

/**
 * @typedef FetchFncQuery
 * @property {"all" | "some" | "one"} type Tipo de consulta que se hará a `/top`.
 * @property {*} [filter] Filter para mongo.
 * @property {*} [projection] Projection para mongo.
*/

/**
 * @typedef Props
 * @property {string} className
 * @property {function} onChange Función que recibirá como argumento el usuario seleccionado.
 * @property {function | Array<function> | FetchFncQuery} fetchFnc
 * - Función que tendrá que devolver un listado de usuarios (se hace `await fetchFnc()` internamente).
 * - Si se da un array de funciones, se ejecutarán todas con `Promise.all`, se mergearán los resultados con `Array.flat()` y finalmente se filtrarán duplicados por la propiedad `id`.
 * - Si se da objeto `{type, filter, projection}` se generará una query stringificada tal que así: `GET /top/<type>/projection=<projection>&filter=<filter>`.
 * @property {defaultUser} defaultUser `{key: "username", value: "pepito"}` Usuario que se seleccionará por defecto, se usará `key` y `value` para buscarlo en la lista de usuarios.
 * @property {string} forceAddUser username del usuario que se quiere añadir forzadamente.
 * @property {function} userFilter Recibe el objeto del usuario como argumento y debe devolver true o false para que aparezca en la lista o no.
 * 
*/

/**
 * 
 * @param {Props} Props.
 * 
 * @example
 * 
 * // Todos los usuarios
 * <BuscadorUsuarios
 *		onChange={ usuario => console.log(usuario) }
 *	/>
 *
 * // Sólo usuarios activos y de rol 2 (opción 1)
 * <BuscadorUsuarios
 *		onChange={ usuario => console.log(usuario) }
 *		fetchFnc = {{
 *			type: "some",
 *			filter: {
 *				"estado.id": 1,
 *				"role": 2
 *			}
 *		}}
 * />
 * 
 * // Sólo usuarios activos y de rol 2 (opción 2)
 * <BuscadorUsuarios
 *		onChange={ usuario => console.log(usuario) }
 *		fetchFnc = () => apiRequest("get", '/top/some?&filter={"estado.id": 1, "role": 2}&projection={"nombre": 1, "apellidos": 1, "id": 1, "username": 1, "email": 1, "horario": 1, "role": 1}', null, true)
 * />
 * 
 * 
*/

export default function BuscadorUsuarios({
	className = "",
	classNameSelector = "w12em",
	onChange = () => {},
	fetchFnc =  () => apiRequest("get", '/top/all?&projection={"nombre": 1, "apellidos": 1, "id": 1, "username": 1, "email": 1, "horario": 1, "role": 1}', null, true),
	defaultUser, // {key: "username", value: "pepito"}
	userFilter = () => true,
	excludeTodos = false,
	disabled = false
}) {
	
	const [usuarios, setUsuarios] = useState([]);
	const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null);
	
	
	useEffect( () => {
		(async() => {
			
			let usuarios;
			
			if (Array.isArray(fetchFnc)) {
				
				const promesas = fetchFnc.map( _x => _x() ); // ejecuto todas las funciones y almaceno sus promesas
				const respuestas = await Promise.all(promesas);
				
				usuarios = respuestas.flat(1);
				
				usuarios = usuarios.filter( (val, idx, arr) => {
					// si por ejemplo estoy en el índice 2 y dice que lo ha encontrado en el 0, significa que el de ahora (2) está repe
					// sólo meto los elementos que encuentre en el índice actual
					return arr.findIndex(_x => _x.id === val.id) === idx;
				});
				
			} else if (typeof fetchFnc === "function") {
				
				usuarios = await cajache.use(
					["tops"],
					fetchFnc,
					{
						expire: dayjs().add(30, "seconds").unix(),
					}
				);
				
			} else {
				
				if (!fetchFnc?.type) throw TypeError("fetchFnc.type es requerido");
				
				const searchParams = new URLSearchParams();
				
				if (fetchFnc.projection) searchParams.append("projection", JSON.stringify(fetchFnc.projection));
				else searchParams.append("projection", '{"nombre": 1, "apellidos": 1, "id": 1, "username": 1, "email": 1, "horario": 1, "role": 1}');
				
				if (fetchFnc.filter) searchParams.append("filter", JSON.stringify(fetchFnc.filter));
				
				
				let decodedSearchParams = decodeURIComponent(decodeURIComponent(searchParams.toString()));
				
				usuarios = await apiRequest("get", `/top/${fetchFnc.type}?${decodedSearchParams}`, null, true);
				if (usuarios._error) usuarios = [];
				
			};
			
			
			usuarios = usuarios ?? [];
			usuarios = usuarios.filter( userFilter );
			
			
			// Guarda todos los usuarios exceptuando los que tengan el rol 3
			// usuarios = usuarios.filter( _usuario => _usuario.role !== 3 );
			
			
			setUsuarios(usuarios);
			
			
			
			if (!defaultUser) {
				setUsuarioSeleccionado("todos");
			} else {
				
				const {key, value} = defaultUser;
				const usuario = usuarios.find( _x => _x[key] === value);
				
				if (usuario) {
					setUsuarioSeleccionado(usuario._id);
					onChange(usuario);
				};
				
			};
			
		})();
	}, []);
	
	
	
	return (
		
		<div
			className={`main-buscadorusuarios ${className}`}
		>
			
			<Select
				className={classNameSelector}
				showSearch
				value={usuarioSeleccionado}
				filterOption={ (input, option) => {
				   
					if (option === "todos") return true;
					
					let usuario = usuarios.find( _x => _x._id === option.value); // busco por _id
					if (!usuario) return false;
					else usuario = {...usuario};
					
					
					// usuario.nombre = `${usuario.nombre} ${usuario.apellidos}`;
					delete usuario.apellidos;
					delete usuario.email;
					
					let values = Object.values(usuario);
					
					const regex = new RegExp(`.*${input}.*`, "gi");
					
					return values.some( _x => regex.test(_x) );
				}}
				onChange={ _id => {

					setUsuarioSeleccionado(_id);
					
					if (_id === "todos") return onChange("todos");
					const usuario = usuarios.find( _x => _x._id === _id); // busco por _id
					
					onChange(usuario);
				}}
				disabled = {disabled}
			>
				{ !excludeTodos && 
					<Option
						value={"todos"}
						key="todos"
					>
						Todos
					</Option>
				}
				
				{usuarios?.map( _x => {
					return <Option
						key={_x._id}
						value={_x._id}
					>
						{_x.nombre} {_x?.apellidos}
					</Option>
				})}
			</Select>
			
		</div>
		
	);
	
};


