
// import "./transfer.scss";
import { Transfer as AntTransfer } from 'antd';
import React, { useEffect, useState } from "react";



const mockData = [];

for (let i = 0; i < 20; i++) {
	mockData.push({
		key: "e45gd25f8a" + i,
		title: `TOP ${i + 1}`,
		description: `description of content${i + 1}`,
		disabled: false,
	});
};



/**
 * @typedef Props
 * @property {Array<*>} dataSource Array de objetos [{key, title, description} ...]
 * @property {Array<*>} value Array de keys transferidas.
 * @property {fuction} [setValue] Función que recibirá los ítems transferidos cada vez que el compnente sufra un cambio de transferencia.
 * @property {fuction} [render] Función que recibirá el ítem como argumento `(item) => item.nombre` y que tendrá que devolver un string.
 * @property {fuction} [rowKey] Función que recibirá el ítem como argumento `(item) => item.keyUnica` y que tendrá que devolver un string con la key única del ítem. Por defecto pilla la propiedad `_id`.
 * @property {fuction} [searchFnc] Función `(inputValue, option) => {...}` donde inputValue es el texto a buscar y option el ítem de `data`. Tendrá que devolver `boolean`. Por defecto busca en todas las propiedades de `option`.
 * @property {function} [sortFnc] Función sort para ordenar las listas.
 * @property {Array<string>} [titles] [tituloIzquierda, tituloDerecha]
*/

/**
 * @param {Props} Props
*/

export default function Transfer ({
	dataSource,
	render = (item) => item.title,
	rowKey,
	value = [],
	setValue = () => {},
	valueKey = "_id",
	searchFnc = (inputValue, option) => {
		const regex = new RegExp(`.*${inputValue}.*`, "i");
		
		for (const _x of Object.values(option)) {
			if (regex.test("" + _x)) return true;
		};
	},
	className= "" ,
	isHabilitada=false,
	sortFnc = () => 0,
	titles = ["", ""],
	...rest
}) {
	
	if (!dataSource || !Array.isArray(dataSource)) dataSource = [];
	dataSource = dataSource.sort(sortFnc);
	
	
	const mounting = !dataSource || dataSource.length === 0;
	
	
	useEffect( () => {
		if (mounting) return null;
		onInternalChange(value, null, []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataSource]);
	
	
	
	const [selectedKeys, setSelectedKeys] = useState([]);
	
	
	
	const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
		let arr = [...sourceSelectedKeys, ...targetSelectedKeys];
		setSelectedKeys(arr);
	};
	
	
	
	const onInternalChange = (nextTargetKeys, direction, moveKeys = []) => {
		
		// Creo un array para guardar objetos → [ {_id, nombre...} ]
		const arrElementos = [];
		
		// Recorro el array de keys (suele tener _ids)
		nextTargetKeys.forEach( _transferedKey => {
			const encontrado = dataSource.find( _x => _x[valueKey] === _transferedKey );
			if (encontrado) arrElementos.push(encontrado);
		});
		
		// Ordeno el array
		arrElementos.sort(sortFnc);
		
		// Saco sus ids para tenerlo como antes, pero ordenado
		nextTargetKeys = arrElementos.map( _x => _x[valueKey] );
		
		
		if (setValue) setValue(nextTargetKeys, direction, moveKeys);
	};
	
	
	
	return (
		<div
			className="main-transfer"
		>
			<AntTransfer
				className={className}
				dataSource={dataSource}
				titles={titles}
				targetKeys={value}
				selectedKeys={selectedKeys}
				onChange={onInternalChange}
				onSelectChange={onSelectChange}
				render={ item => render(item) }
				disabled={ isHabilitada }
				rowKey={ rowKey
					? item => rowKey(item)
					: item => item._id
				}
				filterOption={ (inputValue, option) => searchFnc(inputValue, option) }
				locale={{
					itemUnit: "",
					temsUnit: "",
					searchPlaceholder: "Buscar",
					// notFoundContent: "Vacío"
				}}
				listStyle={{
					width: "100%",
					height: 500,
				}}
				oneWay
				showSearch
				{...rest}
			/>
		</div>

	);

};


