import React from "react";
import useTopsData from "./hooks/useTopsData";
import { Button, Input, Table } from "antd";
import { FiltroTablas } from "components/Tablas/partials/Filtros";
import { useHistory } from "react-router-dom";

export default function TablaPorUsuarios({ id_encuesta, filters = {} }) {
  const { tops, loading, error } = useTopsData(filters, id_encuesta);
  const history = useHistory();
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      onFilter: (filteringValue, record) =>
        record.nombre.toLowerCase().includes(filteringValue.toLowerCase()),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        const inputProps = {
          value: selectedKeys,
          placeholder: "Busca Nombre",
          style: { width: 250, marginTop: 15 },
          onChange: (selected) => setSelectedKeys([selected.target.value]),
        };

        return (
          <FiltroTablas
            filtroInput={<Input {...inputProps} />}
            filtrar={confirm}
            limpiar={clearFilters}
          />
        );
      },
    },
    {
      title: "Nº encuestas",
      dataIndex: "numEncuestas",
      key: "numEncuestas",
      sorter: (a, b) => a.numEncuestas - b.numEncuestas,
    },
    {
      title: "Media",
      dataIndex: "avg",
      sorter: (a, b) => a.avg - b.avg,
      key: "avg",
    },
    {
      title: "Ver respuestas",
      render: (record) => (
        <Button
          type="link"
          onClick={() =>
            history.push(
              "/encuestas_resumen?id_encuesta=" +
                id_encuesta +
                "&id=" +
                record.id
            )
          }
        >
          Ver resultados
        </Button>
      ),
    },
  ];
  if (loading || error) {
    return <div>Cargando...</div>;
  }
  return <Table dataSource={tops} columns={columns} />;
}
