import { Button, message, Select, Table } from 'antd'
import { FiltroTablas } from 'components/Tablas/partials/Filtros';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react'
import apiRequest from 'utils/apiRequest';



const TopsHabilitar = () => {

  const [tops, setTops] = useState([]);
  const [caracteristicasOpt, setCaracteristicasOpt] = useState([]);
  const [update, setUpdate] = useState(false);


  const cargarTops = useCallback(async () => {
    const { error, msg, tops } = await apiRequest("get", `/topsHabilitarList`, null, true);
    
    if (error) {

      return message.error("Error : ", msg);
    } 
    const optionsCaract = [];

    tops.forEach(_top => {
      _top?.caracteristicas?.map(_c => {
        if (!optionsCaract.filter(_x => _x.value === _c.nombre).length) return optionsCaract.push({ key: _c.nombre, value: _c.nombre, label: _c.nombre });
      });
    })

    setCaracteristicasOpt(optionsCaract);
    setTops(tops);

  }, [update])

  useEffect(() => {
    cargarTops();
  }, [update]);


  const actualizarDatos = async (id, accion) => {
     const {error, msg } = await apiRequest("post", `/modificarEstadoCaracteristicas`, {
      id,
      accion
    }, true);

    if (error) {
      return message.error("Error : ", msg);
    }

    message.success("Datos actualizados correctamente");
  }

    const columns = [
      {
        "title": "Turno",
        "key": "turno",
        "dataIndex": "turno",
        onFilter: (filteringValue, record) => filteringValue.includes(record.turno),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          // confirm lanzará la funcion onFilter
          const inputProps = {
            mode: "multiple",
            allowClear: true,
            value: selectedKeys,
            placeholder: "Busca Campo",
            style: { width: 250, marginTop: 15 },
            onChange: (selected) => setSelectedKeys(selected),
            options: [
              {
                key: "AM", value: "AM", label: "AM",
              },
              {
                key: "PM", value: "PM", label: "PM",
              }
            ],
          };

          return <FiltroTablas
            filtroInput={<Select {...inputProps} />}
            filtrar={confirm}
            limpiar={clearFilters}
          />
        }
      },
        {
          title: 'Agente',
          dataIndex: 'nombre',
          key: 'nombre',
          onFilter: (filteringValue, record) => {
          return filteringValue.includes(record.nombre)
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          // confirm lanzará la funcion onFilter

          const options = [];
          const settOptions = new Set(tops.map(_c => { return _c.nombre }))

          for (const _option of settOptions) {
            options.push({ key: _option, value: _option, label: _option });
          }

          const inputProps = {
            mode: "multiple",
            allowClear: true,
            value: selectedKeys,
            placeholder: "Busca Campo",
            style: { width: 250, marginTop: 15 },
            onChange: (selected) => setSelectedKeys(selected),
            options: options
          };

          return <FiltroTablas
            filtroInput={<Select {...inputProps} />}
            filtrar={confirm}
            limpiar={clearFilters}
          />
        }
        },
      {
        "title": "Caracteristica",
        "key": "caracteristica",
        "dataIndex": "caracteristicas",
        "render": (caracteristica) => {

          if(caracteristica?.length > 1) {
            return caracteristica[caracteristica.length - 1].nombre.toString()
          }
        },
        onFilter: (filteringValue, record) => record?.caracteristicas?.filter(_f => _f.nombre === filteringValue).length,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          // confirm lanzará la funcion onFilter

          const inputProps = {
            mode: "multiple",
            allowClear: true,
            value: selectedKeys,
            placeholder: "Busca Campo",
            style: { width: 250, marginTop: 15 },
            onChange: (selected) => setSelectedKeys(selected),
            options: caracteristicasOpt
          };

          return <FiltroTablas
            filtroInput={<Select {...inputProps} />}
            filtrar={confirm}
            limpiar={clearFilters}
          />
        },
        

      },
      {
        "title": "Hora último estado",
        "key": "horaEstado",
        "dataIndex": "fechaModificacion",
        "render": (fechaModificacion) => {
          return fechaModificacion? dayjs(fechaModificacion.ts * 1000).format("DD/MM/YYYY HH:mm:ss") : ""
        },
        sorter: (a, b) => a.fechaModificacion.ts - b.fechaModificacion.ts,
        sortDirections: ['descend', 'asc'],

      },
      {
        "title": "Estado",
        "key": "estado",
        "render": (record) => record?.deshabilitado ? "Deshabilitado" : "Habilitado",
        onFilter: (filteringValue, record) => {
          if (filteringValue === "Habilitado") {
            return record?.deshabilitado === false;
          } else {
            return record?.deshabilitado === true;
          }
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          // confirm lanzará la funcion onFilter
          const inputProps = {
            mode: "multiple",
            allowClear: true,
            value: selectedKeys,
            placeholder: "Busca Campo",
            style: { width: 250, marginTop: 15 },
            onChange: (selected) => setSelectedKeys(selected),
            options: [
              {
                key: "Habilitado", value: "Habilitado", label: "Habilitado",
              },
              {
                key: "Deshabilitado", value: "Deshabilitado", label: "Deshabilitado",
              }
            ],
          };

          return <FiltroTablas
            filtroInput={<Select {...inputProps} />}
            filtrar={confirm}
            limpiar={clearFilters}
          />
        }


      },
      {
        "title": "Acciones",
        "key": "acciones",
        "render": (record) => {

          if (record?.deshabilitado) {
            return (
              <div>

                <Button
                  type='primary'
                  className="ml3"
                  onClick={async () => {
                    await actualizarDatos(record._id, "habilitar");
                    setUpdate(!update);
                  }}
                >
                  Habilitar
                </Button>

              </div>
            )
          } else {
            return (
              <div>

                <Button
                  type='secondary'
                  className="ml3 "
                  onClick={async () => {
                    await actualizarDatos(record._id, "deshabilitar");
                    setUpdate(!update);
                  }}
                >
                  Deshabilitar
                </Button>

              </div>
            )
          }
        }
      }
      
      ];

    return (
        <div>
        
            <h1 className='mt2 mb1'>Tabla de Habilitación / Deshabilitacion de características de TOPS</h1>
         
              <Table
                dataSource={tops}
                columns={columns}
                pagination={{
                  pageSizeOptions: ['10', '20', '30', '50', '100'],
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                  defaultPageSize: '50'
                }}
                rowClassName={(record, indx) => {
                  return record?.deshabilitado ? "bg-lightred" : "bg-lightgreen";
                }}
          
              />
          
          
        </div>
    )
}

export default TopsHabilitar