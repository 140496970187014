
import { Button, message, Select, Table } from "antd";
import FlatCard from "components/FlatCard/FlatCard";
import { FiltroTablas } from "components/Tablas/partials/Filtros";
import dayjs from "dayjs";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import apiRequest from "utils/apiRequest";
import freno from "utils/freno";
import "./TopsEnLinea.scss"



export const TopsEnLinea = ({
    tipo = "tops"
}) => {
    

    const [tops, setTops] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(null);

    const [caracteristicasOpt, setCaracteristicasOpt] = useState([]);

    const [cargando, setCargando] = useState(true);


    const columns = [
        {
            "title":"Turno",
            "key":"turno",
            "dataIndex":"turno",
            onFilter: ( filteringValue, record ) => filteringValue.includes( record.turno ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
              // confirm lanzará la funcion onFilter
              const inputProps = {
                mode: "multiple",
                allowClear: true,
                value: selectedKeys,
                placeholder: "Busca Campo",
                style:{ width: 250, marginTop: 15 },
                onChange: ( selected ) => setSelectedKeys( selected ),
                options: [
                    {
                        key:"AM",value:"AM",label:"AM",
                    },
                    {
                        key:"PM",value:"PM",label:"PM",
                    }
                ],
              };
      
              return <FiltroTablas
                filtroInput={ <Select {...inputProps} /> }
                filtrar={ confirm }
                limpiar={ clearFilters }
              />
            }
        },
        {
            "title":"Agente",
            "key":"nombre",
            "dataIndex":"nombre",
            onFilter: ( filteringValue, record ) => {
                return filteringValue.includes( record.nombre )
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
              // confirm lanzará la funcion onFilter
      
              const options = [];
              const settOptions = new Set( tops.map( _c => {  return _c.nombre} ) )
              
              for (const _option of settOptions) {
                options.push({ key: _option, value: _option, label: _option});
              }

              const inputProps = {
                mode: "multiple",
                allowClear: true,
                value: selectedKeys,
                placeholder: "Busca Campo",
                style:{ width: 250, marginTop: 15 },
                onChange: ( selected ) => setSelectedKeys( selected ),
                options: options
              };
      
              return <FiltroTablas
                filtroInput={ <Select {...inputProps} /> }
                filtrar={ confirm }
                limpiar={ clearFilters }
              />
            }
        },
        {
            "title":"Caracteristica",
            "key":"caracteristica",
            "dataIndex":"caracteristicas",
            "render": (caracteristica) => {
                return caracteristica.map( _c => _c.nombre).toString()
            },
            onFilter: ( filteringValue, record ) =>  record.caracteristicas.filter( _f => _f.nombre === filteringValue ).length,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
              // confirm lanzará la funcion onFilter

              const inputProps = {
                mode: "multiple",
                allowClear: true,
                value: selectedKeys,
                placeholder: "Busca Campo",
                style:{ width: 250, marginTop: 15 },
                onChange: ( selected ) => setSelectedKeys( selected ),
                options: caracteristicasOpt
              };
      
              return <FiltroTablas
                filtroInput={ <Select {...inputProps} /> }
                filtrar={ confirm }
                limpiar={ clearFilters }
              />
            }
            
        },
        {
            "title":"Estado",
            "key":"estado",
            "dataIndex":"ultimoEvento",
            render : (ultimoEvento,record) => {
                if( !ultimoEvento.nombre && !ultimoEvento.cuando) return  <span style={{color:"red"}}>-</span> 
                return ultimoEvento.nombre;
            },
            onFilter: ( filteringValue, record ) =>  filteringValue.includes(record.ultimoEvento.nombre),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => { 
              // confirm lanzará la funcion onFilter
              const estadosDisponibles = [ "baño","formacion","descanso","incidenciaTecnica","tareas","login","logout","en lead" ];
              const opt = estadosDisponibles.map(_x => {
                return  { key : _x , value : _x , label : _x }
              })

              const inputProps = {
                mode: "multiple",
                allowClear: true,
                value: selectedKeys,
                placeholder: "Busca Campo",
                style:{ width: 250, marginTop: 15 },
                onChange: ( selected ) => setSelectedKeys( selected ),
                // options: estadoTops.filter(_x => !!_x.value)
                options: opt
              };
      
              return <FiltroTablas
                filtroInput={ <Select {...inputProps} /> }
                filtrar={ confirm }
                limpiar={ clearFilters }
              />
            }
        },
        {
            "title":"Hora estado",
            "key":"horaestado",
            "dataIndex":"ultimoEvento",
            defaultSortOrder: 'descend',
            sorter : (a,b) =>{
                if( a.ultimoEvento.cuando && a.ultimoEvento.cuando.ts && b.ultimoEvento.cuando && b.ultimoEvento.cuando.ts ) return Number(a.ultimoEvento.cuando?.ts) - Number(b.ultimoEvento.cuando?.ts)
                else if ( a.ultimoEvento.cuando && a.ultimoEvento.cuando.ts) return 1;
                else if ( b.ultimoEvento.cuando && b.ultimoEvento.cuando.ts) return -1;
                
                return 0;
                
            },
            render: (ultimoEvento,record) =>  ultimoEvento.cuando && ultimoEvento.cuando.str
        },
        {
            "title":"Campaña Lead",
            "key":"campanaLead",
            "dataIndex":"campanaLead",
            "render": (campana) =>{
                return campana
            }
        },
        {
            "title":"Lead",
            "key":"idLead",
            "dataIndex":"idLead",

        },
        {
            "title":"Hora Lead",
            "key":"horaLead",
            "dataIndex":"horaLead",
            sorter : (a,b) =>{
                if( a.horaLead && a.horaLead?.ts && b.horaLead && b.horaLead?.ts ) return Number(a.horaLead?.ts) - Number(b.horaLead?.ts)
                else if ( a.horaLead && a.horaLead?.ts) return 1;
                else if ( b.horaLead && b.horaLead?.ts) return -1;
                
                return 0;
                
            },
            render: (horaLead,record) =>  horaLead && horaLead.str
        },
            
    ]

    const cargarTops = useCallback( async ()=>{
        setCargando(true);
        const {  ok , msg , tops } = await  apiRequest("get",`/topsEnLinea?tipo=${tipo}`,null, true);
        setLastUpdate(dayjs());

        const optionsCaract = [];

            
        tops.forEach( _top => {
          _top.caracteristicas.map( _c => { 
             if ( !optionsCaract.filter(_x => _x.value === _c.nombre).length ) return optionsCaract.push({ key: _c.nombre, value: _c.nombre, label: _c.nombre});
         }); 
        })

        setCaracteristicasOpt(optionsCaract);
        setCargando(false);
        if ( ok ) return setTops(tops);

        return message.error("Error : ",msg);

    } ,[tipo])

    useEffect( () => {
        cargarTops();
    }, [tipo]);



    return (
        <FlatCard
            loading={cargando}
            title={
                <div className="fdr aic mb3">
                    
                    <span className="bold">{`Leads en uso (${tops?.length})`}</span>
                    
                    <Button
                        className="ml3"
                        onClick={ () => {
                            freno(5000, cargarTops);
                        }}
                    >
                        Actualizar
                    </Button>
                    
                    <span className="ml1 colGri fs2">última actualización {lastUpdate?.format("HH:mm:ss")}</span>
                    
                </div>
            }
        >

            <div
                className="main-topsenlinea"
            >   
                <Table
                    dataSource={tops}
                    columns={columns}
                    pagination={{
                        pageSizeOptions: [ '10', '20', '30','50', '100' ],
                        showSizeChanger: true,
                        locale: { items_per_page: "" },
                        defaultPageSize: '50'
                    }}
                    rowClassName={(record,indx) => {

                        switch( record.ultimoEvento.nombre){

                            case "en lead":
                                return "bg-lightgreen"
                            case "logout":
                                return "bg-grey"
                            case "baño":
                            case "formacion":
                            case "descanso":
                            case "incidenciaTecnica":
                            case "tareas":
                            case "descanso":
                                return "bg-lightred"
                            case null:
                                return "bg-lightyellow"
                            default:
                                break;

                        }


                    }}
                    /* expandable= {{ 
                        expandedRowRender: (record) => {
                            
                            const columnas = [
                                {
                                    "title":"Campaña Lead",
                                    "key":"campana",
                                    "dataIndex":"campana",
                                    "render": (campana) =>{
                                        return campana.nombre
                                    }
                                },
                                {
                                    "title":"Lead",
                                    "key":"idLead",
                                    "dataIndex":"idLead",

                                },
                                {
                                    "title":"Hora Lead",
                                    "key":"cuando",
                                    "dataIndex":"cuando",
                                    "render": (cuando) => {
                                        return cuando.str
                                    }
                                },
                            ]

                            const formattedSource = record.leadUso.map( (_leadUso,indx) =>{

                                return {
                                    key: indx,
                                    ..._leadUso
                                }

                            })

                            return <>
                            
                                <Table
                                    columns={columnas}
                                    // dataSource={record.leadUso}
                                    dataSource={formattedSource}
                                    
                                />
                            </>

                        },
                        rowExpandable: (record) => record.leadUso.length ,
                    }} */
                />

            </div>
            
        </FlatCard>
        
    );
    
};


