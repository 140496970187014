
import { InfoCircleOutlined } from "@ant-design/icons";
import { Input, InputNumber, Select, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useMemo, useState } from "react";
import { validame } from "validame";
import Void from "../atomicComponents/Void";
import { Checkbox } from "antd";
const { Option } = Select;



export default function Entrada({
	title,
	type,
	value,
	list = [],
	className = '',
	tooltip,
	tooltipIcon = <InfoCircleOutlined />,
	tooltipProps = {},
	selectConfig = { key: '_id', value: 'name' },
	setValue = () => { },
	onError = () => { },
	onInputKeyDown = () => { },
	propsInput = {},
	validation = {},
	disabled = false,
	endingVoid = true,
	processReturn = value => value,
	children, // recibe el html dentro del componente
	...props
}) {

	const [touched, setTouched] = useState(false);

	const error = useMemo(() => {

		const _error = validame("" + value, validation);
		onError(_error !== "");
		return _error;

	}, [value]);

	const isError = error !== "";


	const fncSet = (value) => {
		if (!touched) setTouched(true);
		setValue(value);
		onError(isError);
	};


	const titlePrefix = validation?.req
		? <span className="colRoj mr1">*</span>
		: null
		;



	tooltipProps = {
		placement: "top",
		...tooltipProps,
	};


	const nodoInput = (() => {
		switch (type) {
			case "text":
				const { onKeyDown } = props;
				return (
					<Input
						{...propsInput}
						disabled={disabled}
						value={value}
						onKeyDown={onKeyDown}
						onChange={ev => {

							return fncSet(processReturn(ev.target.value));

						}
						}
					/>
				)
			/* switch (accesKey) {
				case "nombreTop": 
				return (
					<Input
						{...propsInput}
						disabled={disabled}
						value={value}
						onChange={ ev => fncSet(ev.target.value?.toUpperCase()) }
					/>
				)
				case "apellidosTop":
				return (
					<Input
						{...propsInput}
						disabled={disabled}
						value={value}
						onChange={ ev => fncSet(ev.target.value?.toUpperCase()) }
					/>
				)
				default: 
				return (
					<Input
						{...propsInput}
						disabled={disabled}
						value={value}
						onChange={ ev => fncSet(ev.target.value) }
					/>
				);	
			} */
			case "textarea": return (
				<TextArea
					{...propsInput}
					disabled={disabled}
					value={value}
					//onChange={ ev => fncSet( processReturn(ev.target.value) ) }
					onChange={ev => fncSet(ev.target.value)}
				/>
			);
			case "checkbox": return (
				<Checkbox
					{...propsInput}
					disabled={disabled}
					value={value}
					//onChange={ ev => fncSet( processReturn(ev.target.value) ) }
					onChange={ev => fncSet(ev.target.checked)}
				/>
			);
			case "number": return (
				<InputNumber
					{...propsInput}
					disabled={disabled}
					value={value}
					onChange={val => fncSet(val)}
				/>
			);
			case "select":
				if (Array.isArray(selectConfig.value)) {
					const { placeholder } = props;
					return (list?.find(elem => elem[selectConfig.key] !== undefined)
						? (<Select
							{...propsInput}
							disabled={disabled}
							value={value}
							placeholder={placeholder}
							onChange={val => fncSet(val)}
						>
							{list.map(elem => <Option key={elem[selectConfig.key]} value={JSON.stringify(elem)}>{selectConfig.value.map((val) => elem[val]).join(", ")}</Option>)}
						</Select>
						)
						: <p>Empieza a buscar</p>
					);
				}
				else {
					const { placeholder } = props;

					return (list?.find(elem => elem[selectConfig.key] !== undefined && elem[selectConfig.value] !== undefined)
						? (<Select
							{...propsInput}
							disabled={disabled}
							value={value}
							placeholder={placeholder}
							onChange={val => fncSet(val)}
						>
							{list.map(elem => <Option key={elem[selectConfig.key]} value={elem[selectConfig.key]}>{elem[selectConfig.value]}</Option>)}
						</Select>
						)
						: <p>Invalid list config</p>
					);
				}

			case "tags": return (
				<Select
					{...propsInput}
					mode="tags"
					disabled={disabled}
					value={value}
					onInputKeyDown={onInputKeyDown}
					onChange={val => fncSet(val)}
					tokenSeparators={[" ", ","]}
				>
					{list.map(elem => <Option key={elem[selectConfig.key]} value={elem[selectConfig.key]}>{elem[selectConfig.value]}</Option>)}
				</Select>
			)

			default: return <p>Invalid type</p>
		}
	})();



	return (

		<div className={'fdc ' + className}>
			{title && <span className="bold fdr aic">
				{titlePrefix}{title}

				{tooltip &&
					<Tooltip
						title={tooltip}
						{...tooltipProps}
					>
						<div className="ml1">{tooltipIcon}</div>
					</Tooltip>
				}
			</span>
			}

			{children || nodoInput}

			{touched &&
				<p className="colRoj">{error}</p>
			}

			{endingVoid && <Void />}
		</div>

	);

};


