import Modal from "antd/lib/modal/Modal";
import { observer } from "mobx-react-lite";
import { mbxUi } from "MobX/root";
import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.less";
import Routes from "./routes/routes";
import "./styles/global.scss";



// https://ant.design/components/modal/#API
const GlobalModal = observer( () => {
	
	return (
		<Modal
			visible={mbxUi.modal}
			onCancel={ () => mbxUi.modal = null }
			destroyOnClose={mbxUi.destroyOnClose ?? true}
			footer={null}
			{...mbxUi.modal}
		>
			{mbxUi?.modal?.content}
		</Modal>
	);
});

const GlobalModalPausa = observer( () => {
	
	return (
		<Modal
			visible={mbxUi.modalPausa}
			footer={null}
			{...mbxUi.modalPausa}
		>
			{mbxUi?.modalPausa?.content}
		</Modal>
	);
});



function App() {
	
	return (
		<div>
			<BrowserRouter>
				<Suspense
					fallback={
						<div
							style={{ width: "100vw", height: "100vh", fontSize: "3em" }}
							className="fdc jcc aic"
						>
							{/* <span role="img" aria-label="reloj de arena">⏳</span> */}
							<img alt="cargando" src="img/cargandoGana01.gif" style={{ width: "4em" }}></img>
							<span>Cargando...</span>
						</div>
					}
				>
					<Routes />
					
					<GlobalModal />
					<GlobalModalPausa />
					
				</Suspense>
				
			</BrowserRouter>
		</div>
	);
}

export default App;
