import React, { useEffect, useState } from 'react';
import apiRequest from 'utils/apiRequest';
import { Button, Col, Row, Table } from 'antd';
import debounce from 'utils/debounce';
import dayjs from 'dayjs';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import SelectMultiFiltro from 'components/selectMultiFiltro';
import BuscadorUsuarios from 'components/BuscadorUsuarios';

// Parsea los leads dados para añadirles los campos nombre y apellidos
const parseaLeads = ( leads, tops ) => {
    let ret = leads.map( _lead => {
        const { username } = _lead?.quien;

        if ( username ) {
            let top = tops.find( _top => _top.username === username);

            
            if (top) {
                return {
                    ..._lead,
                    quien: {
                        username: username,
                        nombre: top.nombre,
                        apellidos: top.apellidos
                    }
                }

            } else return null;
        } else return null;
		
    });
	
	return ret;
}

// Función encargada de eliminar duplicados.
const eliminaDuplicados = (arrayDuplicados, valor) => {
    switch (valor) {
        case "oid": 
            let arrayOids = [];
            arrayDuplicados.forEach( _x => {
                const oid = _x._id;

                const idxEncontrado = arrayOids.findIndex( _idx => _idx.value === oid);

                if ( idxEncontrado === -1 ) {
                    arrayOids.push({
                        text: oid,
                        value: oid
                    });
                }
            });
        return arrayOids;
        case "campana": 
            let arrayCampanas = [];
            arrayDuplicados.forEach( _x => {
                const campana = _x.campana;

                const idxEncontrado = arrayCampanas.findIndex( _idx => _idx.value === campana._id);

                if ( idxEncontrado === -1 ) {
                    arrayCampanas.push({
                        text: campana.nombre,
                        value: campana._id
                    });
                }
            });
        return arrayCampanas;
        case "estado":
            let arrayEstados = [];
            arrayDuplicados.forEach( _x => {
                const estado = _x.estado;

                const idxEncontrado = arrayEstados.findIndex( _idx => _idx.value === estado._id);

                if ( idxEncontrado === -1 ) {
                    arrayEstados.push({
                        text: estado.nombre,
                        value: estado._id
                    });
                }
            });
        return arrayEstados;
        case "peso": 
            let arrayPesos = [];
            arrayDuplicados.forEach( _x => {
                const peso = _x.peso;

                const idxEncontrado = arrayPesos.findIndex( _idx => _idx.value === peso);

                if ( idxEncontrado === -1 ) {
                    arrayPesos.push({
                        text: peso,
                        value: peso
                    });
                }
            });
        return arrayPesos;
        case "agente":
            let arrayAgentes = [];
            arrayDuplicados.forEach( _x => {
                const agente = _x.quien?.nombre + " " + _x.quien?.apellidos;

                const idxEncontrado = arrayAgentes.findIndex( _idx => _idx.value === agente );

                if ( idxEncontrado === -1 ) {
                    arrayAgentes.push({
                        text: agente,
                        value: agente
                    });
                }
            });
        return arrayAgentes;
        case "telefono": 
            let arrayTelefonos = [];
            arrayDuplicados.forEach( _x => {
                const telefono = _x.campos.telefono1.valor;

                const idxEncontrado = arrayTelefonos.findIndex( _idx => _idx.value === telefono);

                if ( idxEncontrado === -1 ) {
                    arrayTelefonos.push({
                        text: telefono,
                        value: telefono
                    });
                }
            });
        return arrayTelefonos;
        default: 
        break;
    }
}

const Ultimos50Leads = () => {
    const [leads, setLeads] = useState([]);
    const [leadsTop, setLeadsTop] = useState([]);
    const [tops, setTops] = useState([]);

    const [leadsFiltrados, setLeadsFiltrados] = useState([]);
    const [oids, setOids] = useState([]);
    const [campanas, setCampanas] = useState([]);
    const [estados, setEstados] = useState([]);
    const [pesos, setPesos] = useState([]);
    const [telefonos, setTelefonos] = useState([]);
    const [agentes, setAgentes] = useState([]);
    const [filtros, setFiltros] = useState([]);


    const [fechaActualizacion, setFechaActualizacion] = useState("--");
    const [refrescar, setRefrescar] = useState(false);
    const [cargaLeads, setCargaLeads] = useState(false);

    useEffect(() => {

        debounce( 0, async () => {
            setCargaLeads(true);
            let promises = await Promise.all([
                await apiRequest( 'get', '/lead/ultimos50', null, true ),
                await apiRequest('get', '/top/all?projection={"nombre": 1, "apellidos": 1, "username": 1, "id": 1, "_id":0 }', null, true)
            ]);
            if ( !promises ) throw Error( promises._response );
            let [ ultimosLeads, tops ] = promises;

            let ultimosLeadsParseados = parseaLeads(ultimosLeads, tops);
        
            
            setTops ( tops );
            setLeads( ultimosLeadsParseados );
            
            setFechaActualizacion( dayjs().format( "DD/MM/YYYY HH:mm" ) );
            setCargaLeads( false );
        });

    }, []);

    useEffect(() => {

        const { telefono, campana, estado, peso, agente, oid } = filtros;
         
        // Importante: ""data"" determina si se ha de filtrar por los 50 últimos leads generales o de un top en específico.
        const data = leadsTop.length > 0 ? leadsTop : leads;


        /* 
            Filtro según las opciones de los filtros, en caso de que no hayan opciones establecidas, mostrará todos los datos. (Incluyendo duplicados)
            Nota: 
            "Al existir diferentes leads con mismos datos en común, como por ejemplo la campaña, a la hora de establecer los selects, pueden haber datos duplicados"
        */
     
        let filtrados = data.filter( _lead => {

            let oidOk = !oid || ( oid && oid.includes( _lead._id ) );

            let pesoOk = !peso || ( peso && String( peso ).includes( _lead.peso ) );
           
            let telefonoOk = !telefono || ( telefono && String( telefono ).includes( _lead.campos.telefono1.valor ) );

            let campanaOk = !campana || ( campana.length > 0 && campana.includes( _lead.campana._id ) );

            let estadoOk = !estado || estado.length === 0 || ( estado.length > 0 && estado.includes( _lead.estado._id ) );
            
            let agenteOk = !agente || ( agente.length > 0 && agente.includes( _lead?.quien?.nombre + " " + _lead?.quien?.apellidos ) );

            return pesoOk && telefonoOk && campanaOk && estadoOk && agenteOk && oidOk;
          
        });


        // ELIMINA DATOS DUPLICADOS
        let oidUnicos = eliminaDuplicados( filtrados, "oid" );

        let campanasUnicas = eliminaDuplicados( filtrados, "campana" );
        
        let estadosUnicos = eliminaDuplicados( filtrados, "estado" );

        let pesosUnicos = eliminaDuplicados( filtrados, "peso" );

        let agentesUnicos = eliminaDuplicados( filtrados, "agente" );

        let telefonosUnicos = eliminaDuplicados( filtrados, "telefono" );

        
        // DATOS FILTRADOS DE LA TABLA
        let ultimosLeadsFiltradosParseados = parseaLeads(filtrados, tops);
        setLeadsFiltrados( ultimosLeadsFiltradosParseados );

        // DATOS DE LOS SELECTS ( En base a los filtros seleccionados )
        setOids( oidUnicos );
        setCampanas( campanasUnicas );
        setEstados( estadosUnicos );
        setPesos( pesosUnicos );
        setAgentes( agentesUnicos );
        setTelefonos( telefonosUnicos );

    }, [ filtros, leads, leadsTop, tops ] );


    // Función encargada de establecer los estados con la información de los filtros.
    const tableChange = ( pagination, filters ) => {
        let { campana, estado, peso, campos: telefono, quien: agente, _id: oid } = filters;

        setFiltros({
            ...filtros,
            estado,
            campana,
            peso,
            telefono,
            agente,
            oid
        });

    }


    // Función encargada de recargar la página.
    const reload = async () => {

        setRefrescar(true);

        const leads = await apiRequest('get', '/lead/ultimos50', null, true);
        
        let ultimosLeadsParseados = parseaLeads(leads, tops);
        setLeadsFiltrados(ultimosLeadsParseados)

        setFechaActualizacion(dayjs().format("DD/MM/YYYY HH:mm"));
        setRefrescar(false);
    }


    // Opciones de los filtros.
    const handleSearch = ( selectedKeys, confirm, dataIndex ) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };
    

    // Callback de buscador usuarios -> ( Busca los próximos 50 leads de un Agente en concreto )
    const filtraLeadsUsuario = async ( user = {} ) => {


        let promises = await Promise.all([
            await apiRequest('get', `/lead/ultimos50/${ ( user?.id ) ? user.id : 'todos' }`, null, true)
        ]);

        let [ultimosLeads] = promises; 

        // Si no tiene ningún lead, no mostrará nada.
        if (ultimosLeads.length === 0) {
            setLeadsFiltrados(ultimosLeads);
        } else {

            let ultimosLeadsParseados = parseaLeads(ultimosLeads, tops);
            setLeadsTop(ultimosLeadsParseados);
        }

    }


    // Abre una nueva pestaña apuntando a el lead seleccionado
    const irAlLead = (value) => {
        window.open(`/llamada?_id=${value}`, "_blank");
    }
    
    const columns = [
        {
            title: ( 'Campaña' ),
            dataIndex: 'campana',
            filters: campanas.map( _campana => _campana ),
            filteredValue: filtros.campana || null,
            render: ( value, data ) => value.nombre,
            sorter: ( a, b ) => a.campana.nombre.localeCompare( b.campana.nombre ),
            sortDirections: [ 'ascend','descend' ],
            filterDropdown: ( { setSelectedKeys, selectedKeys, confirm, clearFilters } ) => {
                return(
                    <>
                        <Row>
                            <Col>
                                <SelectMultiFiltro
                                    is50={true}
                                    placeHolder={ "Selecciona campaña" }
                                    data = { campanas.map( _campana => _campana ) }
                                    style = { { width: 260, margin:5 } }
                                    onChange={ ( e, data1 ) => setSelectedKeys( e ) }
                                    value={ selectedKeys }
                                />
                            </Col>
                            <Col>
                                <Button 
                                    onClick = { ()=> handleSearch( selectedKeys, confirm ) } 
                                    style={ { display:"block", width:"95%", margin:"5px 5px 5px 0px" } }
                                >
                                    Filtrar
                                </Button>
                                <Button 
                                    onClick={ () => handleReset( clearFilters ) }
                                    style={ { display:"block", width:"95%", margin:"5px 5px 5px 0px" } }
                                >
                                    Limpiar
                                </Button>
                            </Col>
                        </Row>
                    </>
                )
            },
        },
        {
            title: ( 'Estado' ),
            filters: estados.map( _estado => _estado ),
            dataIndex: 'estado',
            filteredValue: filtros.estado || null,
            render: ( value, data ) => value.nombre,
            sorter: ( a, b ) => a.estado.nombre.localeCompare( b.estado.nombre ),
            sortDirections: [ 'ascend','descend' ],
            filterDropdown: ( { setSelectedKeys, selectedKeys, confirm, clearFilters } ) => {
                return(
                    <>
                        <Row>
                            <Col>
                                <SelectMultiFiltro
                                    is50={true}
                                    placeHolder={ "Selecciona estado" }
                                    data = { estados.map( _estado => _estado ) }
                                    style = { { width: 260, margin:5 } }
                                    onChange = { ( e,data1 ) => setSelectedKeys( e ) }
                                    value={ selectedKeys }
                                />
                            </Col>
                            <Col>
                                <Button 
                                    onClick = { () => handleSearch( selectedKeys, confirm ) } 
                                    style = { { display:"block", width:"95%", margin:"5px 5px 5px 0px" } }
                                >
                                    Filtrar
                                </Button>
                                <Button 
                                    onClick = { () => handleReset( clearFilters ) } 
                                    style = { { display:"block", width:"95%", margin:"5px 5px 5px 0px" } }
                                >
                                    Limpiar
                                </Button>
                            </Col>
                        </Row>
                    </>
                )
            },
        },
        {
            title: ('Prioridad'), 
            filters: pesos.map( _p => _p),
            filteredValue: filtros.peso || null,
            dataIndex: 'peso',
            render: (value, data) => value,
            sorter: (a, b) => a.peso - b.peso,
            sortDirections: [ 'ascend' ],
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return(
                    <>
                        <Row>
                            <Col>
                                <SelectMultiFiltro
                                    is50={true}
                                    placeHolder={"Selecciona prioridad"}
                                    data={pesos.map( _e => _e )}
                                    style={{width: 260,margin:5}}
                                    onChange={(e,data1)=>{
                                        setSelectedKeys(e)
                                    }}
                                    value={selectedKeys}
                                />
                            </Col>
                            <Col>
                                <Button onClick={()=>{
                                    handleSearch(selectedKeys, confirm);
                                }} style={{display:"block", width:"95%", margin:"5px 5px 5px 0px"}}>Filtrar</Button>
                                <Button onClick={() => {
                                    handleReset(clearFilters)
                                }} 
                                style={{display:"block", width:"95%", margin:"5px 5px 5px 0px"}}>Limpiar</Button>
                            </Col>
                        </Row>
                    </>
                )
            },
        },
        {
            title: ( 'Agente' ),
            dataIndex: 'quien',
            filters: agentes.map( _a => _a),
            filteredValue: filtros.agente || null,
            render: (value, data) => value.nombre + " " + value.apellidos || "Todos",
            sorter: (a, b) => a.quien?.nombre.localeCompare(b.quien?.nombre),
            sortDirections: [ 'ascend','descend' ],
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return(
                    <>
                        <Row>
                            <Col>
                                <SelectMultiFiltro
                                    is50={true}
                                    placeHolder={"Selecciona agente"}
                                    data={agentes.map( _a => _a )}
                                    style={{width: 260,margin:5}}
                                    onChange={(e,data1)=>{
                                        setSelectedKeys(e)
                                    }}
                                    value={selectedKeys}
                                />
                            </Col>
                            <Col>
                                <Button onClick={()=>{
                                    handleSearch(selectedKeys, confirm);
                                }} style={{display:"block", width:"95%", margin:"5px 5px 5px 0px"}}>Filtrar</Button>
                                <Button onClick={() => {
                                    handleReset(clearFilters)
                                }} 
                                style={{display:"block", width:"95%", margin:"5px 5px 5px 0px"}}>Limpiar</Button>
                            </Col>
                        </Row>
                    </>
                )
            }
        },
        {
            title: ('ID Lead'), 
            filters: oids.map( _oid => _oid),
            filteredValue: filtros.oid || null,
            dataIndex: '_id',
            render: (value, data) => <span style={{cursor: "pointer", color: "blue"}} onClick={(e) => irAlLead(value)}>{value}</span>,
            /* sorter: (a, b) => a. - b.peso, */
            sortDirections: ['ascend'],
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return(
                    <>
                        <Row>
                            <Col>
                                <SelectMultiFiltro
                                    is50={true}
                                    placeHolder={"Selecciona ID Lead"}
                                    data={oids.map( _oid => _oid )}
                                    style={{width: 260,margin:5}}
                                    onChange={(e,data1)=>{
                                        setSelectedKeys(e)
                                    }}
                                    value={selectedKeys}
                                />
                            </Col>
                            <Col>
                                <Button onClick={()=>{
                                    handleSearch(selectedKeys, confirm);
                                }} style={{display:"block", width:"95%", margin:"5px 5px 5px 0px"}}>Filtrar</Button>
                                <Button onClick={() => {
                                    handleReset(clearFilters)
                                }} 
                                style={{display:"block", width:"95%", margin:"5px 5px 5px 0px"}}>Limpiar</Button>
                            </Col>
                        </Row>
                    </>
                )
            },
        },
        {
            title: ( 'Teléfono' ),
            dataIndex: 'campos',
            filters: telefonos.map( _t => _t),
            filteredValue: filtros.telefono || null,
            render: (value, data) => value.telefono1.valor,
            sorter: (a, b) => a.campos.telefono1.valor - b.campos.telefono1.valor,
            sortDirections: [ 'ascend' ],
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return(
                    <>
                        <Row>
                            <Col>
                                <SelectMultiFiltro
                                    is50={true}
                                    placeHolder={"Selecciona teléfono"}
                                    data={telefonos.map( _t => _t )}
                                    style={{width: 260,margin:5}}
                                    onChange={(e,data1)=>{
                                        setSelectedKeys(e)
                                    }}
                                    value={selectedKeys}
                                />
                            </Col>
                            <Col>
                                <Button onClick={()=>{
                                    handleSearch(selectedKeys, confirm);
                                }} style={{display:"block", width:"95%", margin:"5px 5px 5px 0px"}}>Filtrar</Button>
                                <Button onClick={() => {
                                    handleReset(clearFilters)
                                }} 
                                style={{display:"block", width:"95%", margin:"5px 5px 5px 0px"}}>Limpiar</Button>
                            </Col>
                        </Row>
                    </>
                )
            },
        },

    ];

    return (
        <div>
            <h1>Últimos Leads</h1>
            <div className="aic">
                <div>
                    Datos actualizados :
                    <span style={{backgroundColor:"lightblue", padding:"5px", marginRight:"5px"}}>
                    {fechaActualizacion}
                    </span>
                    { !refrescar &&
                    <>
                        <ReloadOutlined style={{cursor:"pointer",color:"blue"}} onClick={reload}/>
                    </>
                    }
                    { refrescar &&
                    <>
                        <LoadingOutlined style={{cursor:"pointer",color:"blue"}}/>
                    </>
                    }
                </div>
                <div style={{marginLeft: "20px"}}>
                    <BuscadorUsuarios 
                        userFilter={ _user => _user.role !== 3 }    
                        onChange={
                            (usuario) => filtraLeadsUsuario(usuario)
                        }
                    />
                </div>
            </div>
            <div className="panel-body">
                <Table
                    className="mt3"
                    rowKey={ data => data._id }
                    loading={ cargaLeads }
                    dataSource={ leadsFiltrados }
                    pagination={
                        {
                            total: (leadsFiltrados !== null ? leadsFiltrados?.length : 0),
                            defaultPageSize: 50,
                            pageSizeOptions: ['10', '20', '50'],
                            showSizeChanger: true,
                            locale: { items_per_page: "" },
							hideOnSinglePage: true,
                        }
                    }
                    onChange={ tableChange }
                    columns={ columns }
                />
            </div>
        </div>
    )
}

export default Ultimos50Leads;