import React, { useReducer } from "react";
import margenesReducer, { initialState } from "./margenes_reducer";
import { Col, Row, Select, Radio, Button, DatePicker, message } from "antd";
import "./Margenes.css";
import { fetchList, ranges } from "./utils";
import DebounceSelect from "components/DebounceSelect";
import dayjs from "dayjs";
import apiRequest from "utils/apiRequest";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function FiltrosMargenes({state, dispatch}) {
  const calcularMargenes = async () => {
    // Quitamos el resultado previo del state y gastamos solo filtros
    const { resultado_general, ...filters } = state;
    dispatch({type: "SET_CARGANDO", payload: true});
    const resultadoGeneral = await apiRequest("post", "/margenes", filters, true);
    dispatch({type:"SET_RESULTADO_GENERAL", payload: resultadoGeneral});
    dispatch({type: "SET_CARGANDO", payload: false});
  };

  return (
    <Row flex={1}>
      <Col flex={1} className="bloque">
        <div className="linea">
          Factura
          <DebounceSelect
            className="select"
            mode="multiple"
            value={state.facturas}
            placeholder="Introduce factura"
            fetchOptions={(value) => fetchList(value, "factura")}
            onChange={(newValue) => {
              dispatch({ type: "SET_FACTURAS", payload: newValue });
            }}
            disabled={state.cargando}
          />
        </div>
        <div className="linea">
          DNI
          <DebounceSelect
            className="select"
            mode="multiple"
            value={state.dnis}
            placeholder="Introduce DNI"
            fetchOptions={(value) => fetchList(value, "dni")}
            onChange={(newValue) => {
              dispatch({ type: "SET_DNIS", payload: newValue });
            }}
            disabled={state.cargando}
          />
        </div>
        <div className="linea">
          CUPS
          <DebounceSelect
            className="select"
            mode="multiple"
            value={state.cups}
            placeholder="Introduce cups"
            fetchOptions={(value) => fetchList(value, "cups")}
            onChange={(newValue) => {
              dispatch({ type: "SET_CUPS", payload: newValue });
            }}
            disabled={state.cargando}
          />
        </div>
        <div className="linea">
          Tarifa
          <DebounceSelect
            className="select"
            mode="multiple"
            value={state.tarifas}
            placeholder="Introduce tarifa"
            fetchOptions={(value) => fetchList(value, "tarifa")}
            onChange={(newValue) => {
              dispatch({ type: "SET_TARIFAS", payload: newValue });
            }}
            start={state?.tarifas.length === 0 ? true : false}
            disabled={state.cargando}
          />
        </div>
      </Col>
      <Col flex={1} className="bloque">
        <div className="linea">
          Atr
          <DebounceSelect
            className="select"
            mode="multiple"
            value={state.atrs}
            placeholder="Introduce atr"
            fetchOptions={(value) => fetchList(value, "atr")}
            onChange={(newValue) => {
              dispatch({ type: "SET_ATRS", payload: newValue });
            }}
            start={state.atrs.length === 0 ? true : false}
            disabled={state.cargando}
          />
        </div>
        <div className="linea">
          Origen Venta
          <Select
            className="selector"
            defaultValue={"Todos"}
            value={state.origenes}
            onChange={(newValue) => {
              dispatch({ type: "SET_ORIGENES", payload: newValue });
            }}
            disabled={state.cargando}
          >
            <Option value="-1">Todos</Option>
            <Option value="0">Interno</Option>
            <Option value="1">Colaborador</Option>
            <Option value="2">Agencia</Option>
            <Option value="3">Web</Option>
          </Select>
        </div>
        <div className="linea">
          Fijo / Indexado
          <Select
            className="selector"
            defaultValue={"Ambos"}
            value={state.fijo}
            onChange={(newValue) => {
              dispatch({ type: "SET_FIJO", payload: newValue });
            }}
            disabled={state.cargando}
          >
            <Option value="-1" selected>
              Ambos
            </Option>
            <Option value="0">Fijo</Option>
            <Option value="1">Indexado</Option>
          </Select>
        </div>
        <div className="linea">
          Luz / Gas
          <Select
            className="selector"
            defaultValue={"Ambos"}
            value={state.luz}
            onChange={(newValue) => {
              dispatch({ type: "SET_LUZ", payload: newValue });
            }}
            disabled={state.cargando}
          >
            <Option value="-1">Ambos</Option>
            <Option value="0">Luz</Option>
            <Option value="1">Gas</Option>
          </Select>
        </div>
      </Col>
      <Col flex={1} className="bloque">
        <div className="linea">
          Periodo facturación
          <RangePicker
            className="rangePicker"
            onChange={(fecha, data1) => {
              // comprobamos si la diferencia es mayor a 31 dias
              // si es asi, no dejamos seleccionar
              let f = fecha ? fecha : "";
              let finicio = f[0];
              let ffin = f[1];
              let diferencia = dayjs(ffin).diff(dayjs(finicio), "day") + 1;

              if (diferencia > 31) {
                message.error(
                  "El periodo de facturación no puede ser mayor a 31 días"
                );
                return;
              }
              dispatch({ type: "SET_PERIODO_FACTURACION", payload: f });
            }}
            disabledDate={(current) => {
              return current && current >= dayjs().subtract(5, "days");
            }}
            disabled={state.periodoEmision !== ""}
            value={state.periodoFacturacion}
            ranges={ranges}
          ></RangePicker>
        </div>
        <div className="linea">
          Periodo emisión
          <RangePicker
            className="rangePicker"
            onChange={(fecha, data1) => {
              let f = fecha ? fecha : "";
              dispatch({ type: "SET_PERIODO_EMISION", payload: f });
            }}
            disabled={state.periodoFacturacion !== ""}
            disabledDate={(current) => {
              return current && current >= dayjs().subtract(5, "days");
            }}
            value={state.periodoEmision}
            ranges={ranges}
          ></RangePicker>
        </div>
        <div className="linea">
          Con descuentos
          <Radio.Group
            defaultValue={state.descuentos}
            className="fullWidth"
            size="medium"
            value={state.descuentos}
            onChange={(opcion) => {
              dispatch({
                type: "SET_DESCUENTOS",
                payload: opcion.target.value,
              });
            }}
            disabled={state.cargando}
          >
            <Radio.Button value="-1">Indiferente</Radio.Button>
            <Radio.Button value="1">Si</Radio.Button>
            <Radio.Button value="0"> No</Radio.Button>
          </Radio.Group>
          Tipo consulta
          <Radio.Group
            defaultValue={state.excluyente}
            className="fullWidth"
            size="medium"
            value={state.excluyente}
            onChange={(opcion) => {
              dispatch({
                type: "SET_EXCLUYENTE",
                payload: opcion.target.value,
              });
            }}
            disabled={state.cargando}
          >
            <Radio.Button value="0">Incluyente (... Y ...)</Radio.Button>
            <Radio.Button value="1">Excluyente (... O ...)</Radio.Button>
          </Radio.Group>
        </div>
        <div className="linea right">
          <Button
            onClick={() => dispatch({ type: "CLEAN_STATE", payload: "Limpio" })}
            disabled={state.cargando}
          >
            Limpiar
          </Button>
          <Button
            type="primary"
            onClick={() => calcularMargenes()}
            disabled={state.cargando}
          >
            Calcular
          </Button>
        </div>
      </Col>
    </Row>
  );
}
