import React from "react";
import useEncuestaAnalytics from "../hooks/useEncuestaAnalytics";
import useGroupedData from "../hooks/useGroupedData";
import useQuery from "../hooks/useQuery";

export default function TablaFiltrados({ preguntas = [], filters = {} }) {
  const query = useQuery();
  const {
    results,
    loading: resultsLoading,
    error: resultsError,
  } = useEncuestaAnalytics(query.get("id"), filters);
  const { grouped_data: valoraciones } = useGroupedData(
    query.get("id"),
    filters,
    "valoracion"
  );
  if (resultsError) {
    return <h1>Ha ocurrido un error</h1>;
  }
  return (
    <table className="table-analytics">
      <thead>
        <tr>
          <th>Estadisticas filtradas</th>
        </tr>
      </thead>

      <tbody>
        {/*         <tr className="row-analytics">
          <td className="row-content-analytics row-caracteristica">
            Encuestas generadas/creadas
          </td>
          <td className="row-content-analytics">
            {resultsLoading && "Cargando..."}
            {(resultsLoading || results.countLanzadas) ?? ""}
          </td>
        </tr>
        <tr className="row-analytics">
          <td className="row-content-analytics row-caracteristica">
            Encuestas enviadas por correo
          </td>
          <td className="row-content-analytics">
            {resultsLoading && "Cargando..."}
            {(resultsLoading || results.countEnviadas) ?? ""}
          </td>
        </tr> */}
        <tr className="row-analytics">
          <td className="row-content-analytics row-caracteristica">
            Encuestas respondidas
          </td>
          <td className="row-content-analytics">
            {resultsLoading && "Cargando..."}
            {(resultsLoading || results.countRespuestas) ?? ""}
          </td>
        </tr>
        <tr className="row-analytics">
          <td className="row-content-analytics row-caracteristica">
            Encuestas cerradas sin respuesta
          </td>
          <td className="row-content-analytics">
            {resultsLoading && "Cargando..."}
            {(resultsLoading || results.countCaducadas) ?? ""}
          </td>
        </tr>
        <tr className="row-analytics">
          <td className="row-content-analytics row-caracteristica">
            Valoraciones
          </td>
          <td className="row-content-analytics">
            {valoraciones?.map((valoracion, index) => {
              const translations = {
                1: (
                  <p className="question-heading" style={{ color: "red" }}>
                    :(
                  </p>
                ),
                3: (
                  <p className="question-heading" style={{ color: "orange" }}>
                    :|
                  </p>
                ),
                5: (
                  <p className="question-heading" style={{ color: "green" }}>
                    :)
                  </p>
                ),
              };
              return (
                <p key={index}>
                  {translations[valoracion?._id[0]]} {valoracion?.count} (
                  {(
                    (valoracion?.count /
                      (results.countRespuestas + results.countCaducadas)) *
                    100
                  ).toFixed(2)}{" "}
                  %)
                </p>
              );
            })}
          </td>
        </tr>
        {results?.media?.map((media, index) => {
          return (
            <tr className="row-analytics" key={index}>
              <td className="row-content-analytics row-caracteristica">
                Media{" "}
                {
                  preguntas.find(
                    (pregunta) => pregunta.id_pregunta === media._id
                  )?.heading
                }
              </td>
              <td className="row-content-analytics">
                {resultsLoading && "Cargando..."}
                {(resultsLoading || media.avg.toFixed(3)) ?? ""}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
