
/**
 * @typedef ClassNameObj
 * @property {boolean} className
*/

/**
 * Genera una className (string) a partir de un array de strings y objetos.
 * @param {Array<string|ClassNameObj>} arrClassNames Array de classNames u objetos. Por ejemplo: `["a", {"b": true}, {"c": false}]`.
 * @returns {string}
 * 
 * @example
 * buildClassName(["a", "b", {"c": false}, {"d": false, "e": true}]); // "a b e"
 * buildClassName(["a b c", {"d": false, "e": true}]); // "a b c e"
 * buildClassName(["a a", "a", {"a": true, "b": true}]); // "a b"
*/
export default function buildClassName(arrClassNames) {
	
	let finalClassNames = [];
	
	
	for (const _x of arrClassNames) {
		
		if (typeof _x === "string") {
			finalClassNames = [...finalClassNames, ..._x.trim().split(" ")];
			continue;
		};
		
		if (typeof _x === "object" && _x !== null) {
			for (const _key of Object.keys(_x)) {
				if (!!_x[_key]) finalClassNames.push(_key);
			};
		};
		
	};
	
	finalClassNames = [...new Set(finalClassNames)];
	return finalClassNames.join(" ");
	
};

