import React, { useEffect, useState } from "react";
import useQuery from "./hooks/useQuery";
import useEncuesta from "./hooks/useEncuesta";
import useEncuestaAnalytics from "./hooks/useEncuestaAnalytics";
import apiRequest from "utils/apiRequest";
import { Pie } from "@ant-design/plots";
import { Tabs, Empty, Select } from "antd";
import "./styles.scss";
import TablaPorUsuarios from "./TablaPorUsuarios";
import { DatePicker, Button, Card } from "antd";
import TablaFiltrados from "./subcontainers/TablaFiltrados";
import moment from "moment";
import TablaResultados from "./TablaResultados";
import useGroupedData from "./hooks/useGroupedData";
import useEncuestaData from "./hooks/useEncuestaData";
import getDateRanges from "utils/getDateRanges";
import BoxList from "./subcontainers/BoxList";
import FilterCreator from "./subcontainers/FilterCreator";
import BoxStatistic from "./subcontainers/BoxStatistic";
import BoxQuestion from "./subcontainers/BoxQuestion";
import { Chart } from "react-google-charts";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

export default function EncuestaResultados() {
  const query = useQuery();
  const [filters, setFilters] = useState({
    fecha_inicio_respuesta: moment().subtract(7, "d").unix(),
    fecha_fin_respuesta: moment().unix(),
    caducadas: "2",
  });
  const { encuestaLoaded, loading, error } = useEncuesta(query.get("id"));
  //Obtenemos los resultados sin filtrar
  const {
    results,
    loading: resultsLoading,
    error: resultsError,
  } = useEncuestaAnalytics(query.get("id"), {});
  const preguntas =
    !loading &&
    !error &&
    encuestaLoaded?.preguntas
      ?.map((encuesta) => encuesta.preguntas)
      .flat(Infinity);

  const { loadingView, resultsEncuesta, resultsTextEncuesta } = useEncuestaData(
    results,
    preguntas,
    filters
  );
  const options = {
    title: "",
    legend: { position: "right" },
    pieSliceText: "value-and-percentage",
    pieSliceTextStyle: {
      fontSize: 14,
      color: "white",
      textAlign: "left",
    },
    tooltip: {
      trigger: "hover",
      textStyle: {
        fontSize: 12,
        textAlign: "left",
      },
      formatter: ({ formattedValue, percentage }) =>
        `${formattedValue} (${percentage.toFixed(2)}%)`,
    },
    chartArea: {
      width: "fit-content",
      height: "80%",
    },
    pieSliceVisibility: "visibleInLegend",
    minSlicePercentage: 10,
    sliceVisibilityThreshold: 0,
  };

  const colors = {
    Bien: "#0da729",
    Mal: "#eb002b",
    Normal: "#F9AA33",
  };

  function getColorForOption(option) {
    return colors[option] || getRandomColor();
  }

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  if (loading || resultsLoading) {
    return <p>Cargando...</p>;
  }
  if (error || !query.get("id") || resultsError) {
    return <p>Ha ocurrido un error</p>;
  }
  return (
    <>
      <b className="title">
        Análisis de los resultados de la encuesta "
        {encuestaLoaded?.nombre_encuesta}"
      </b>
      <BoxList filters={filters} global={true} setFilters={setFilters} />
      <br />
      <FilterCreator setFilters={setFilters} filters={filters} />
      <br />
      <BoxList filters={filters} setFilters={setFilters} />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Resumen por preguntas" key="1">
          <div className="grid-questions">
            {preguntas &&
              preguntas.map((pregunta) => {
                const parent =
                  (pregunta.parent &&
                    preguntas.find(
                      (preguntaAux) =>
                        preguntaAux.id_pregunta === pregunta.parent
                    )) ??
                  "";
                if (
                  resultsEncuesta[pregunta.id_pregunta] ||
                  resultsTextEncuesta[pregunta.id_pregunta]
                ) {
                  const chartData = resultsEncuesta[pregunta.id_pregunta]?.map(
                    ({ type, value }) => [type, value]
                  );

                  return (
                    <BoxQuestion
                      title={pregunta.heading}
                      subtitle={
                        pregunta.parent
                          ? "Pregunta padre: " + parent?.heading ?? ""
                          : ""
                      }
                      loading={loadingView}
                      halfSize={
                        (resultsEncuesta[pregunta.id_pregunta] &&
                          !resultsTextEncuesta[pregunta.id_pregunta]) ||
                        (resultsTextEncuesta[pregunta.id_pregunta] &&
                          !resultsEncuesta[pregunta.id_pregunta])
                      }
                    >
                      <div className="flex-list">
                        {resultsEncuesta[pregunta.id_pregunta] &&
                          Object.keys(resultsEncuesta[pregunta.id_pregunta])
                            .length > 0 && (
                            <div className="flex-list-item">
                              <Chart
                                chartType="PieChart"
                                data={[["1", "2"], ...chartData]}
                                options={options}
                                graph_id={"PieChart" + pregunta.id_pregunta}
                                width={"100%"}
                                height={"400px"}
                              />
                            </div>
                          )}
                        {resultsTextEncuesta[pregunta.id_pregunta] &&
                          Object.keys(resultsTextEncuesta[pregunta.id_pregunta])
                            .length > 0 && (
                            <div className="flex-list-item">
                              <TablaResultados
                                data={resultsTextEncuesta[pregunta.id_pregunta]}
                                preguntas={pregunta}
                              />
                            </div>
                          )}
                      </div>
                    </BoxQuestion>
                  );
                }
              })}
          </div>
        </TabPane>

        <TabPane tab="Resumen por Tops" key="3">
          <TablaPorUsuarios filters={filters} id_encuesta={query.get("id")} />
        </TabPane>
      </Tabs>
    </>
  );
}
