import { mbxGlobal } from "../MobX/root";



/**
 * Hace todo lo necesario para salir de la cuenta actual.
 * @param {string} [redirectTo="/login"] Redirección tras logout. Usar null para evitar la redirección.
*/

export const logout = async (redirectTo = "/login") => {
	
	try {
		
		mbxGlobal.user = null;
		localStorage.removeItem("user");
		localStorage.removeItem("global");
		
		if (redirectTo) {
			if (window.location.pathname !== redirectTo) {
				window.location.href = redirectTo;
			};
		};
		
	} catch (err) {
		
		console.log( err );
		
	};
	
};
