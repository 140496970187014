
import { makeAutoObservable } from "mobx";



export default class MbxUi {
	
	modal = null;
	modalPausa = null;
	
	
	constructor() {
		makeAutoObservable(this);
	};
	
};
