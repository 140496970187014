
import { Button } from "antd";
import React, { useEffect, useState } from "react";



/**
 * @typedef OnChange
 * @type {function} 
 * 
 * @param {string | Array<string>} value
*/

/**
 * @typedef Props
 * @property {"single" | "multiOrder"} mode
 * @property {*} value
 * @property {OnChange} onChange
 * @property {Array} options
 * @property {boolean} allowNull
*/

/**
 * Grupo de opciones seleccionables en forma de botón.
 * @param {Props} Props.
*/
export default function ButtonGroup({
	mode = "single",
	value,
	onChange = () => {},
	options = [],
	allowNull = false,
	disabled = false
}) {
	
	return (
		<Button.Group
			className="fdc"
		>
			
			<div className="fdr">
				{options.map( _x => {
					
					const _value = _x.value ?? (_x.label).toLowerCase();
					
					return (
						<Button
							key={_x.label + _x.value}
							type={ 
								(
									value &&
									(
										value === _value ||
										value.includes(_value)
									)
								) ? "primary" : "default"
							}
							onClick={ () => {
								
								
								switch (mode) {
									
									case "single": {
										// if (allowNull && value === _value) return onChange(selected);
										onChange(_value);
										return;
									};
									
									case "multiOrder": {
										
										const _selected = [...value ?? []];
										const idxEncontrado = _selected.findIndex( _x => _x === _value);
										
										if (idxEncontrado === -1) _selected.push(_value);
										else _selected.splice(idxEncontrado, 1);
										
										onChange(_selected);
										return;
									};
									
								};
								
								
							}}
							disabled={disabled}
						>
							{_x.label}
						</Button>
					)
				})}
			</div>
			
			
			
			<div className="fdr">
				{value && Array.isArray(value) && value.map( (_x, _idx) => {
					
					const _value = _x ?? (_x).toLowerCase();
					
					return <div
						key={_x}
					>
						<a	
							onClick={ () => {
								
								const _selected = [...value];
								const idxEncontrado = _selected.findIndex( _x => _x === _value);
								
								if (idxEncontrado === -1) _selected.push(_value);
								else _selected.splice(idxEncontrado, 1);
								
								onChange(_selected);
								
							}}
						>
							{_x}
						</a>
						
						{ _idx < (value && Array.isArray(value) && value.length - 1) &&
							<span className="ml2 mr2 colGriCla">→</span>
						}
						
					</div>
					
				})}
			</div>
			
		</Button.Group>
	);
	
};


