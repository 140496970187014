import React from "react";

/**
 * @typedef FiltroTablas
 * @property {React.Component} filtroInput
 * @property {function} filtrar
 * @property {function} limpiar
 */

const { Row, Col, Button } = require("antd");

/**
 * Genera el filtro para las Tablas
 * @param {FiltroTablas} props
 * @returns
 */
export function FiltroTablas({
  filtroInput = <span>Rellenar Input</span>,
  filtrar = () => {},
  limpiar = () => {},
  hideLimpiar = false,
}) {
  const style = {
    display: "block",
    width: "95%",
    margin: "5px 5px 5px 0px",
  };
  return (
    <>
      <Row>
        <Col>{filtroInput}</Col>
        <Col>
          <Button onClick={filtrar} style={style}>
            Filtrar
          </Button>
          {hideLimpiar === false && (
            <Button onClick={limpiar} style={style}>
              Limpiar
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}
