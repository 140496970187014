import React from 'react';
import { CloseOutlined } from "@ant-design/icons";
import { Card, Collapse } from "antd";
import { shadeGenerator } from "../../utils/generador";

const NoHayDatos = (<div><p>No hay datos</p></div>)

const { Panel } = Collapse;

export default function Tarjeta({
    titulo = '',
    className = '',
    collapseDefaultOpen = false,
    color = '#ffffff', // siempre un hexadecimal
    contenido = NoHayDatos,
    children,
    onCollapseChange = ( open ) => { /*console.log( "OPEN: ", open.length ? true : false );*/ },
    stopPropagationOnClose = true,
    small = false,
    onClose = null,
    collapse = false,
    ...options
}) {

    const { light, dark } = titulo ? shadeGenerator({ color: color, intensity: 2 }) : { light: color, dark: color }

    if (collapse) return (

        <div
            className={className}
        >
            <Collapse className='p3' onChange={onCollapseChange} defaultActiveKey={ collapseDefaultOpen ? [] : ['1']}>

                <Panel
                    key={1}
                    header={<span className={`${small ? 'fs4 bold' : 'fs6'}`}>{titulo}</span>}
                    children={children || contenido}
                />
            </Collapse>
        </div>

    )
    return <div
        className={className}
    >
        <Card
            className='bRad3'
            title={<span className={`${small ? 'fs4 bold' : 'fs6'}`}>{titulo}</span>}
            headStyle={{ backgroundColor: dark }}
            bodyStyle={{ backgroundColor: light }}
            size={small ? 'small' : 'default'}
            extra={onClose && (
                <CloseOutlined
                    onClick={(event) => {
                        // frenamos la propagacion de eventos para evitar que se ejecute el onClick de cosas por encima
                        stopPropagationOnClose && event.stopPropagation();
                        onClose();
                    }}
                />
            )}
            {...options}
            children={children || contenido}
        />
    </div>
};


