
/*
	Convierte un número de más de 3 dígitos en un string que separa las centenas con un punto.
	
	Import 
		import numToStr from "./utils/numToStr"
	
	Ejemplo:
		numToStr(1000);			// "1.000"
		numToStr(1000.4, true);	// "1.000,40"
	.
*/

export default function numToStr(numero, forzar2decimales = false, separador = ".") {
	
	if ( !numero ) return 0; 
	if ( Array.isArray(numero) ) return 0;

	let sufix = null;

	
	if(isNaN(numero)) {
		let splitChars = numero.split(" ");
		numero = splitChars[0];
		sufix = splitChars[1];
	}

	// Existe?
	if (! numero || Number(numero) === 0) {
		if(forzar2decimales) return (sufix)? `0,00 ${sufix}` : "0,00";
		return (sufix)? `0 ${sufix}` : "0";
	};
	
	const esNegativo = Number(numero) < 0;

	// Convierto si es numero
	let strNum = "" + Math.abs(numero);
	
	// Busco si tiene una coma
	let [int, decimal] = strNum.split(["."]);
	
	// Montamos los decimales si hemos seleccionado la opcion
	if (forzar2decimales) {
		if (!decimal) decimal = "00";
		else decimal = decimal.padEnd(2, "0");				
	};
	
	// Si la longitud es inferior a 3 monto el string
	// sin separador de miles
	if (strNum.length <= 3) {

		let numToReturn = int;
		if (esNegativo) numToReturn =  "-" + numToReturn;
		if (decimal) numToReturn = `${numToReturn},${decimal}`;
		if (sufix) numToReturn += " " + sufix;
		return numToReturn;
	};
	
	// Itero del revés
	// para montar la separacion de miles
	let arrNumeros = [];
	let digitos = 0;
	
	for (let _i = int.length - 1; _i >= 0; _i --) {
		
		arrNumeros.unshift( int.charAt(_i) );
		digitos ++;
		
		if (digitos === 3) {
			if (_i === 0) break;
			arrNumeros.unshift( separador );
			digitos = 0;
		};
		
	};
	
	let numFinal = arrNumeros.join("");
	
	if (esNegativo) numFinal = "-" + numFinal;
	if (decimal) numFinal = `${numFinal},${decimal}`;
	if (sufix) numFinal += " " + sufix;
	
	return numFinal;
	
};