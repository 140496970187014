import apiRequest from "utils/apiRequest";
import getDateRanges from "utils/getDateRanges";

export async function fetchList(valor, tipo) {
    let datosMargenes = await apiRequest(
        "get",
        "/datos?d=" + tipo + "&v=" + valor,
        null,
        true
    );

    let arrayData = [];
    if (datosMargenes) {
        datosMargenes.datos.forEach((dato) => {
            let tmp = {
                label: `${dato}`,
                value: `${dato}`,
            };
            arrayData.push(tmp);
        });
    }

    return arrayData;
}


export const ranges = {...getDateRanges()};

delete ranges["Hoy"];
delete ranges["Ayer"];