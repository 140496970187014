
import { Select } from "antd";
import React from "react";
// import "./buscadorestados.scss";



/**
 * @typedef Props
 * @property {Array.<object>} listado
 * @property {function} onChange
 * @property {string} estplaceholderados
 * @property {"multiple" | "tags"} mode Default `null`. Usar `null` para que sea un selector único.
*/

/**
 * Permite buscar y seleccionar uno o más estados.
 * @param {Props} Props
*/
export default function BuscadorGenerico({
	className = "",
	listado = [],
	onChange = () => {},
	value = [],
	placeholder = "Filtra en el listado",
	mode = null,
	allowClear = true,
}) {
	
	return (
		
		<Select
			className={`minw15em ${className}`}
			placeholder={placeholder}
			mode={mode}
			showSearch
			value={value}
			allowClear={allowClear}
			filterOption={ (input, option) => {
				// en el filter optios usamos la propieda custom `label` para realizar las busquedas de nombre
				return option.label.toLowerCase().includes( ( "" + input ).toLowerCase() );
			}}
			onChange={ _selected => {
				onChange(_selected);
			}}
		>
			{ listado.length > 0 && listado.map( _x => {
				return (
					<Select.Option
						key={ _x._id || _x.id }
						value={ _x._id || _x.id }
						label={_x.nombre} // añadimos el campo label para despues poder usarlo en el filtro
					>
						{_x.nombre}
					</Select.Option>
				);
			})}
		</Select>
		
	);
	
};


