
import { BellOutlined, CalendarOutlined, EditOutlined, IdcardOutlined, PhoneOutlined, SearchOutlined, StopOutlined, TagOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, message, Modal, Table } from "antd";
import TextArea from "antd/lib/input/TextArea";
import BuscadorGenerico from "components/BuscadorGenerico";
import Entrada from "components/Entrada";
import FlatCard from "components/FlatCard/FlatCard";
import React, { useEffect, useState } from "react";
import apiRequest from "utils/apiRequest";
import getDateRanges from "utils/getDateRanges";
import moment from 'moment';
import dayjs from "dayjs";

const { RangePicker } = DatePicker

export const BloquearLeads = () => {
    
    const [leadBuscar, setLeadBuscar] = useState(null);
    const [leadBuscarBloqueado, setLeadBuscarBloqueado] = useState({});
    
    const [leadEncontrado, setleadEncontrado] = useState(null);

    const [leadBloqueado, setLeadBloqueado] = useState(null);

    const [bloquearLeadModal, setBloquearLeadModal] = useState(null);
    const [campanas, setCampanas] = useState([]);
    

    useEffect( () => {
        
        ( async() =>{

            const campanas = await apiRequest("get","/campana",null,true);
            setCampanas(campanas);


        })();


    }, []);



    const buscarLead = async() => {
        
        const data = await apiRequest("get",`/lead?filter={"_id":"${leadBuscar}"}`,null,true);

        if( data.length === 0  || data._error ) return message.error("Lead no encontrado")

        setleadEncontrado(data[0]);
        setLeadBloqueado(null);
    };

    const buscarLeadBloqueado = async() => {

        
        const data = await apiRequest("get",`/leadBloqueado?filter=${JSON.stringify(leadBuscarBloqueado)}`,null,true);

        if( data.length === 0 || data._error ) return message.error("Lead no encontrado");
        
        setLeadBloqueado(data.lead);
        setleadEncontrado(null);

    };


    const bloquearLead = async (motivo) => {
        
        const data = await apiRequest("post","/bloquearLead",{ _id : leadEncontrado._id , motivo:motivo },true);

        if( data._error ){
            setBloquearLeadModal(false);
            return message.error(data._response)
        }
        setBloquearLeadModal(false)
        return message.success("Lead Bloqueado Correctamente!")


    };


    const ModalBloquearLead = () => {

        const [motivoBloqueo, setMotivoBloqueo] = useState(null);

        return <>

            <Modal
                title={`Bloquear lead ${leadEncontrado._id}`}
                visible={bloquearLeadModal}
                onCancel={()=>setBloquearLeadModal(true)}
                closable={false}
                footer={[
                    <Button onClick={()=> setBloquearLeadModal(false) } >Cancelar</Button>,
                    <Button 
                        type="primary" 
                        danger 
                        disabled={!motivoBloqueo}
                        onClick={()=>{
                            bloquearLead(motivoBloqueo)
                        }}
                    >
                        Bloquear
                    </Button>

                ]}
            >

                <p className="bold mb1">Motivo <span className="colRoj mr1">*</span> </p>
                    <TextArea
                        autoSize={{
                            minRows: 2,
                        }}
                        placeholder="Escribe aquí el comentario..."
                        value={motivoBloqueo}
                        onChange={ev => {
                            setMotivoBloqueo(ev.target.value);
                        }}
                    />
            </Modal>
        </>
    };

    const columns = [
        {
            title:"Id Lead",
            dataIndex:"oidLead",
        },
        {
            title:"Campaña",
            render:(text,record,indx)=> record.campana?.nombre
        },
        {
            title:"Teléfono",
            dataIndex:"telefono",
            
        },
        {
            title:"Bloqueado El",
            sorter: (a, b) => ( a.ts > b.ts ? 1 : -1 ),
            render:(text,record,index)=> record.cuando?.str
            
        },
        {
            title:"Bloqueado Por",
            render:(text,record,index)=> record.quien?.username
        },
        {
            title:"Motivo",
            dataIndex:"motivo",
        },

    ]



    return (
        
        <div className="fdc">

            <div className="fdr">

                <div className="mr3">

                        <FlatCard
                            title="Lead a bloquear"
                        >
                            <div className="fdc">

                                <Entrada 
                                    
                                    title={"Id Lead"} 
                                    type={"text"}
                                    setValue={(ev)=>{
                                        
                                        setLeadBuscar(ev);
                                    }}
                                />  

                            </div>
                            <Button
                                type="primary"
                                onClick={buscarLead}
                                disabled={!leadBuscar}
                                icon={<SearchOutlined/>}
                            >
                                Buscar
                            </Button>
                            
                        </FlatCard>
                    
                </div>

                <div className="mr3">
                        <FlatCard
                            title="Leads bloqueados"
                        >
                            <div className="fdr">
                                
                                <Entrada 
                                    className="pr5" 
                                    title={"Teléfono"} 
                                    type={"text"}
                                    setValue={(ev)=>{
                                        if(ev) setLeadBuscarBloqueado({ ...leadBuscarBloqueado,"telefono":ev })
                                        else delete leadBuscarBloqueado.telefono;
                                        
                                    }}
                                />
                                <Entrada 
                                    className="pr5" 
                                    title={"Id Lead"} 
                                    type={"text"}
                                    setValue={(ev)=>{
                                        if(ev) setLeadBuscarBloqueado({ ...leadBuscarBloqueado,"oidLead":ev })
                                        else delete leadBuscarBloqueado.oidLead;
                                    }}
                                />

                            </div>

                            <div className="fdr">
                                
                                <Entrada 
                                    className="pr5"
                                    title={"Campanas"}
                                >   
                                <BuscadorGenerico
                                    placeholder = "Filtro de Campanas"
                                    listado={campanas}
                                    onChange={ val => {

                                        const cambios = {...leadBuscarBloqueado};

                                        if ( val.length ) cambios.campana = val;
                                        else delete cambios.campana;
                                        setLeadBuscarBloqueado(cambios)

                                    } }
                                    value={leadBuscarBloqueado?.campana}
                                    mode={"multiple"}
                                />
                                </Entrada>


                                <Entrada className="pr5" title={"Fecha Bloqueado"}>
                                    <RangePicker
                                        disabledDate={(current)=>{ return current && current > moment().endOf('day');}}
                                        ranges={getDateRanges()}
                                        format={"DD/MM/YYYY"}
                                        onChange={(fechasMoment,fechasStr) => {
                                            
                                            let cambios = { ...leadBuscarBloqueado };

                                            if( fechasStr[0] && fechasStr[1] ){
                                                cambios={ ...cambios, fechaFiltro : {
                                                    inicio: dayjs(fechasStr[0],"DD/MM/YYYY").unix(),
                                                    // Sumo 86400 que es un dia en segundos para incluir el ultimo dia.
                                                    fin: dayjs(fechasStr[1],"DD/MM/YYYY").unix()+86400
                                                }}
                                            }else{
                                                delete cambios.fechaFiltro;
                                            }
                                            
                                            setLeadBuscarBloqueado(cambios)
                                        }}
                                    />
                                </Entrada>


                            </div>

                            <Button
                                type="primary"
                                onClick={buscarLeadBloqueado}
                                icon={<SearchOutlined/>}
                            >
                                Buscar
                            </Button>
                            {/* <div className="fdc pr5">
                                Telefóno
                                <Input 
                                    type={"text"}
                                    size={"small"}
                                    onChange={(ev)=>{
                                        setLeadBuscarBloqueado(ev.target.value);
                                    }}
                                />
                            </div>   */}

                        </FlatCard>
                </div>

            </div>
            
           { ( leadEncontrado && !leadBloqueado) && <div>
                <FlatCard 
                    title="Lead"
                >
                
                    <div className="fdr aic mr5 m2">
                        <IdcardOutlined className="colNeg fs4" />
                        <span className="ml2 bold">Id Lead:&nbsp;</span>
                        <span >{leadEncontrado._id}</span>
                    </div>
                
                    <div className="fdr aic mr5 m2">
                        <TagOutlined classID="colNeg fs4" />
                        <span className="ml2 bold">Nombre:&nbsp;</span>
                        <span >{leadEncontrado.campos?.nombreorazonsocial?.valor}
                        </span>
                    </div>

                    <div className="fdr aic mr5 m2">
                        <PhoneOutlined classID="colNeg fs4" />
                        <span className="ml2 bold">Nº Teléfono:&nbsp;</span>
                        <span >{leadEncontrado.campos.telefono1?.valor}</span>
                    </div>

                    <div className="fdr aic mr5 m2">
                        <BellOutlined classID="colNeg fs4" />
                        <span className="ml2 bold">Campaña:&nbsp;</span>
                        <span >{leadEncontrado.campana?.nombre}</span>
                    </div>


                    <div className="jcc">
                        <Button 
                            type="primary" 
                            danger
                            onClick={()=> setBloquearLeadModal(true)} 
                        >
                            <StopOutlined /> Bloquear Lead    
                        </Button>
                    </div>

                </FlatCard>
                <ModalBloquearLead/>
            </div>
            }
            { ( leadBloqueado && !leadEncontrado ) && 
                <div>
                    
                    <Table
                        className="mt5" 
                        columns={columns} 
                        dataSource={leadBloqueado}
                        pagination={{"hideOnSinglePage":true} }
                    />


                </div>
            }
            

        
            
        </div>
        
    );
    
};


