
import React, { useRef } from "react";



// import "./Image.scss";


/**
 * @typedef {Object} props
 * @property {string} src Source de la imagen.
 * @property {string} className Class de la imagen.
 * @property {string} style Estilo de la imagen. Los props w y h sobreescribirán heigth y width del estilo.
 * @property {string} [w] Ancho.
 * @property {string} [h] Alto.
 * @property {function} [onClick] Función que se lanzará al hacer click.
*/

/**
 * @param {props} props
*/

export default function Image({
	src,
	className,
	style,
	w,
	h,
	alt = "",
	onClick,
	hueRotate,
}) {

	let imgRef = useRef(null);


	const check = () => {

		let img = imgRef.current;

		if ( /*!img.complete ||*/ img.naturalWidth === undefined || img.naturalWidth === 0) {
			//img.src = "img/imageFallback.svg"
			img.style.width = "32px";
			img.style.heigth = "32px";
		};

	};


	if (hueRotate) className += " hueRotate";



	// ############################################################
	// Render
	// ############################################################

	return (

		<img
			onClick={onClick}
			ref={imgRef}
			src={src}
			alt={alt || src}
			className={className}
			style={{
				...style,
				width: w ?? "100%",
				height: h ?? null,
			}}
			onError={check}
		>
		</img>

	);

};
