
import { CloseOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React, { useRef, useState } from "react";
import Cargando from "../Cargando/Cargando";
import "./flatCard.scss";



/**
 * @typedef {Object} Props
 * @property {string} [width="100%"] Ancho. Default `100%`.
 * @property {string} [mainClassName="main-card p3"] Clase que tendrá el contenedor main.
 * @property {string} [collapsable=false] Determina si se puede contraer. Default `false`.
 * @property {boolean} [initialCollapsed=false] Si está collapsed inicialmente o no. Default `false`.
 * @property {boolean} [closable=false] Determina si se puede cerrar o no. **Un collapsable no puede ser closable**. Default `false`.
 * @property {function} [onClick] onClick
 * @property {boolean} [loading=false] Determina si está cargando o no.
*/

/**
 * Crea una tarjeta.
 * @param {Props} props
*/

export default function FlatCard({
	children,
	width = "100%",
	mainClassName="",
	collapsable,
	initialCollapsed = false,
	closable,
	danger = false,
	title = "",
	titleClassName = "fs3 bold mb1",
	onClick,
	loading = false,
}) {
	
	const ref = useRef(null);
	
	
	mainClassName += " main-flatCard p3 fdc";
	
	let style = {};
	if (danger) style = {
		border: "2px solid rgba(255,0,0,0.5)",
	};
	
	
	
	const [collapsed, setCollapsed] = useState( () => {
		if (initialCollapsed) return ["0"];
		else return [];
	});
	
	
	
	const nodoTitulo = <span className={titleClassName}>{title}</span>;
	
	let nodo = (
		<Cargando
			cargando={loading}
		>
			
			{ !collapsable && nodoTitulo}
			
			<div
				className={mainClassName}
				ref={ref}
				style={{
					...style,
					// background: "white",
					width: width,
					position: "relative",
				}}
				onClick={ onClick }
			>
				{ closable &&
					<CloseOutlined	
						className="p2 fs4 cp"
						style={{
							position: "absolute",
							right: 0,
							top: 0,
						}}
						onClick={ ev => {
							(ref.current).parentElement.removeChild(ref.current);
						}}
					/>
				}
				
				{children}
			</div>
		</Cargando>
	);	
	
	
	
	if (!collapsable) {
		
		return (
			<>{nodo}</>
		);
		
	} else {
		
		return (
			<Collapse
				defaultActiveKey={collapsed}
				onChange={ key => setCollapsed(key) }
				ghost
			>
				<Collapse.Panel
					style={{padding: "0 !important"}}
					header={nodoTitulo}
					showArrow={true}
				>
					{nodo}
				</Collapse.Panel>
			</Collapse>
		)
	};
	
	

	
};


