import {
  BlockOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  CalculatorOutlined,
  CheckCircleOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  DownOutlined,
  FallOutlined,
  FileSearchOutlined,
  FundViewOutlined,
  GoldOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  MailOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  PauseOutlined,
  PhoneOutlined,
  PlusSquareOutlined,
  PoweroffOutlined,
  ProfileOutlined,
  RightOutlined,
  RiseOutlined,
  RotateRightOutlined,
  SolutionOutlined,
  StepForwardOutlined,
  StopOutlined,
  TableOutlined,
  TagOutlined,
  TagsOutlined,
  TeamOutlined,
  TranslationOutlined,
  UserAddOutlined,
  WalletOutlined,
  CloseOutlined,
  EyeOutlined,
  FundProjectionScreenOutlined,
  FieldTimeOutlined,
  SyncOutlined
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Dropdown,
  Layout,
  Menu,
  message,
  Select,
  Tooltip,
} from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import tiposPausa from "utils/tiposPausa";
import { version } from "../../../package.json";
import { logout } from "../../actions/logout";
import Image from "../../atomicComponents/Image";
import Void from "../../atomicComponents/Void";
import { mbxGlobal, mbxSocket } from "../../MobX/root";
import buildClassName from "../../utils/buildClassName";
import "./globalLayout.scss";

const { Option } = Select;
const { Header, Sider } = Layout;

const { Panel } = Collapse;

function NavElement({
  nombre,
  icon,
  url = "",
  isColumn = true,
  collapsed = false,
}) {
  const { pathname } = useLocation();
  const history = useHistory();

  const isActive = pathname === url;

  let className = buildClassName([
    "navElementContainer w100 cp ta03 pl3 pr3 aic fs3",
    {
      navElementContainerActive: isActive,
      "pt1 pb1": isColumn,
      jcc: collapsed,
    },
  ]);

  let nodoIcon = icon;

  if (collapsed)
    nodoIcon = (
      <Tooltip title={nombre} placement="right">
        {icon}
      </Tooltip>
    );

  return (
    <Button
      type="link"
      name={url}
      className={className}
      style={
        {
          // height: "2em",
        }
      }
      icon={nodoIcon}
      onMouseDown={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        if (ev.button === 1) {
          // 0 izq, 1 central, 2 der
          window.open(url, "_blank", "noopener");
          return;
        } else if (ev.button === 0) {
          history.push(url);
        }
      }}>
      {!collapsed && <span className="ml2">{nombre}</span>}
    </Button>
  );

  // return (
  // 	<a
  // 		name={url}
  // 		className={className}
  // 		href={null}
  // 		style={{
  // 			height: "2em",
  // 		}}
  // 		onMouseDown={ev => {

  // 			ev.stopPropagation();
  // 			ev.preventDefault();

  // 			if (ev.button === 1) { // 0 izq, 1 central, 2 der
  // 				window.open(url, "_blank", "noopener");
  // 				return
  // 			} else if (ev.button === 0) {
  // 				history.push(url);
  // 			};

  // 		}}

  // 	>
  // 		{nodoIcon} {!collapsed && <span className="ml2">{nombre}</span>}
  // 	</a>
  // );
}

const BotonPausa = observer(() => {
  let dropdownOverlay = (
    <Menu
      onClick={({ key }) => {
        if (
          key !== "baño" &&
          (mbxGlobal.hayLeadNormal === true ||
            mbxGlobal.hayLeadRecuperable === true)
        ) {
          return message.error(
            "Mientras tienes un lead abierto sólo puedes hacer una pausa de baño."
          );
        }

        mbxSocket.socket.emit("set:usuario_pausa", {
          nombre: "pausado",
          tipo: key,
        });

        // mbxUi.modalPausa = {
        // 	content: <h1>hola</h1>,
        // }
      }}>
      {Object.keys(tiposPausa).map((_id) => {
        const { nombre, emoji } = tiposPausa[_id];

        return (
          <Menu.Item key={_id}>
            {emoji} {nombre}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown trigger="click" overlay={dropdownOverlay}>
      <Button>
        <PauseOutlined />
        Pausa
        <DownOutlined />
      </Button>
    </Dropdown>
  );
});

export default observer(function GlobalLayout({ children }) {
  const history = useHistory();

  const [collapsed, setCollapsed] = useState(
    mbxGlobal.globalLayout?.sideCollapsed
  );
  const [rol] = useState(mbxGlobal.user?.role);

  const tienePermisos = rol === 3 || rol === 6;

  // const estado = mbxGlobal?.socket?.estado;

  const getMarginSider = () => {
    return tienePermisos ? `${collapsed ? 80 : 200}px` : "0px";
  };

  return (
    <Layout className="main-globalLayout hfull">
      <Header
        className="fdr jcsb"
        style={{ position: "fixed", zIndex: 1, width: "100%", height: 40 }}>
        <div className="fdc jcc aic mr5">
          <Image
            src="img/logoGanaEnergiaFull.webp"
            h="1.3em"
            w="7em"
            className="mr2"
          />
          <span
            className="colBla fs1"
            style={{ lineHeight: 1 }}
            onClick={(ev) => {
              if (ev.ctrlKey) history.push("/sala");
            }}>
            {version}
          </span>
        </div>

        {process.env.NODE_ENV !== "production" && (
          <p
            className="colGri bolder"
            style={{
              position: "fixed",
              top: "64px",
              left: "33%",
              fontSize: "10em",
              opacity: 0.15,
              pointerEvents: "none",
            }}>
            T E S T
          </p>
        )}

        <div className="aic pr5 mr5">
          <Button
            type="default"
            className="mr3"
            onClick={() =>
              window.open("/listaRetenciones", "_blank", "noopener")
            }
            icon={<OrderedListOutlined />}>
            Lista de cálculos
          </Button>

          {mbxGlobal.hayLeadNormal === false &&
            mbxGlobal.hayLeadRecuperable === false && (
              <NavElement
                nombre="Home"
                url="/"
                icon={<HomeOutlined className="fs4" />}
                isColumn={false}
              />
            )}

          {/* <NavElement
						size='small'
						url='/llamada'
						nombre='Llamada'
						icon={<PhoneOutlined className="fs4" />}
						isColumn={false}
					/> */}

          <div className="aic ml3">
            <BotonPausa />
          </div>
        </div>

        <div className="aic">
          <PoweroffOutlined
            className="colRojCla fs4"
            onClick={() => logout()}
          />
        </div>
      </Header>

      <Layout
        style={{
          background: "transparent !important",
          marginTop: 35,
        }}>
        {tienePermisos && (
          <Sider
            collapsible
            width={200}
            className="site-layout-background"
            style={{
              height: "100vh",
              position: "fixed",
              left: 0,
              marginTop: 5,
              zIndex: 9,
            }}
            collapsed={collapsed}
            onCollapse={(collapsed) => {
              setCollapsed(collapsed);
              mbxGlobal.globalLayout.sideCollapsed = collapsed;
            }}>
            <div
              style={{
                overflowY: "auto",
                height: "100%",
              }}>
              <NavElement
                nombre="Dashboard"
                url="/dashboard"
                icon={<TableOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <span>
                <NavElement
                  nombre="Campañas"
                  url="/campanas"
                  icon={<NotificationOutlined className="fs4" />}
                  collapsed={collapsed}
                />
              </span>
              <span>
                <NavElement
                  nombre="Bloques Prioridad"
                  url="/bloquesPrioridad"
                  icon={<BlockOutlined className="fs4" />}
                  collapsed={collapsed}
                />
              </span>
              <span>
                <NavElement
                  nombre="Bloques"
                  url="/bloques"
                  icon={<BlockOutlined className="fs4" />}
                  collapsed={collapsed}
                />
              </span>
              <span>
                <NavElement
                  nombre="Tickets"
                  url="/tickets"
                  icon={<MailOutlined className="fs4" />}
                  collapsed={collapsed}
                />
              </span>

              <Void space={1} />

              <NavElement
                nombre="Tops"
                url="/tops"
                icon={<TeamOutlined className="fs4" />}
                collapsed={collapsed}
              />
              <NavElement
                nombre="Traducciones"
                url="/traducciones"
                icon={<TranslationOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <Void space={1} />

              <NavElement
                nombre="Buscador Telefonos"
                url="/buscadorTelefonos"
                icon={<PhoneOutlined className="fs4" />}
                collapsed={collapsed}
              />
              <NavElement
                nombre="Leads"
                url="/leads"
                icon={<UserAddOutlined className="fs4" />}
                collapsed={collapsed}
              />
              <NavElement
                nombre="Próximos leads"
                url="/lineaTemporalLeads"
                icon={<StepForwardOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <Void space={1} />

              <NavElement
                nombre="Estados"
                url="/estados"
                icon={<TagOutlined className="fs4" />}
                collapsed={collapsed}
              />
              <NavElement
                nombre="Subestados"
                url="/subestados"
                icon={<TagsOutlined className="fs4" />}
                collapsed={collapsed}
              />
              <NavElement
                nombre="Reglas"
                url="/reglas"
                icon={<RotateRightOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <Void space={1} />

              <NavElement
                nombre="Caract. campaña"
                url="/caracteristicasCampana"
                icon={<ProfileOutlined className="fs4" />}
                collapsed={collapsed}
              />
              <NavElement
                nombre="Caract. tops"
                url="/caracteristicasTops"
                icon={<SolutionOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <Void space={1} />

              <Collapse
                expandIcon={({ isActive }) => (
                  <RightOutlined
                    className="colBla"
                    rotate={isActive ? 90 : 0}
                  />
                )}
                ghost>
                <Panel
                  header={<span className="colBla">Calculadora</span>}
                  key="1">
                  <NavElement
                    nombre="Calculadora de clientes"
                    url="/retencion"
                    icon={<CalculatorOutlined className="fs4" />}
                    collapsed={collapsed}
                  />

                  <NavElement
                    nombre="Comercializadoras"
                    url="/comercializadoras"
                    icon={<ProfileOutlined className="fs4" />}
                    collapsed={collapsed}
                  />

                  <NavElement
                    nombre="Tarifas Comercializadoras"
                    url="/tarifasComercializadoras"
                    icon={<SolutionOutlined className="fs4" />}
                    collapsed={collapsed}
                  />

                  <NavElement
                    nombre="Ventajas Ahorradas"
                    url="/ventajasAhorradas"
                    icon={<SolutionOutlined className="fs4" />}
                    collapsed={collapsed}
                  />
                </Panel>
              </Collapse>

              <Void space={1} />

              <NavElement
                nombre="Retrasos"
                url="/retrasos"
                icon={<DashboardOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <Void space={1} />

              <NavElement
                nombre="Leads"
                url="/totales"
                icon={<WalletOutlined className="fs4" />}
                collapsed={collapsed}
              />
              <NavElement
                nombre="Agentes"
                url="/agentescampanas"
                icon={<GoldOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <Void space={1} />

              <NavElement
                nombre="KPI's"
                url="/kpis"
                icon={<CalculatorOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <NavElement
                nombre="KPI's Ventas"
                url="/kpisRepsolventas"
                icon={<RiseOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <NavElement
                nombre="KPI's Bajas"
                url="/kpisRepsolBajas"
                icon={<FallOutlined className="fs4" />}
                collapsed={collapsed}
              />
              {rol === 3 && (
                <NavElement
                  nombre="Tiempos"
                  url="/tiempos"
                  icon={<FieldTimeOutlined className="fs4" />}
                  collapsed={collapsed}
                />
              )}
              <Void space={1} />
              <NavElement
                nombre="CUPS Colaborador"
                url="/cupscolaborador"
                icon={<CalculatorOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <Void space={1} />

              <NavElement
                nombre="Incentivos"
                url="/incentivos"
                icon={<DollarOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <Void space={1} />

              <NavElement
                nombre="view MailChimp"
                url="/mailchimpView"
                icon={<InfoCircleOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <NavElement
                nombre="Crear MailChimp"
                url="/mailchimp"
                icon={<PlusSquareOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <Void space={1} />

              <NavElement
                nombre="Leads en Uso"
                url="/leadsenuso"
                icon={<FundViewOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <NavElement
                nombre="Busqueda Leads"
                url="/condicionestraspaso"
                icon={<FileSearchOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <NavElement
                nombre="Bloquear Leads"
                url="/bloquearLeads"
                icon={<StopOutlined className="fs4" />}
                collapsed={collapsed}
              />

              <NavElement
                nombre="TOPS en línea"
                url="/topsenlinea"
                icon={<CheckCircleOutlined className="fs4" />}
                collapsed={collapsed}
              />
              <NavElement
                nombre="CATAS en línea"
                url="/catasenlinea"
                icon={<EyeOutlined className="fs4" />}
                collapsed={collapsed}
              />
              <NavElement
                nombre="Habilitar/deshabilitar TOPS"
                url="/topshabilitar"
                icon={< SyncOutlined className="fs4" />}
                collapsed={collapsed}
              />
              {rol === 3 && (
                <>
                  <Void space={1} />
                  <NavElement
                    nombre="Caidas colaboradores"
                    url="/informe_colaboradores"
                    icon={<FundProjectionScreenOutlined className="fs4" />}
                    collapsed={collapsed}
                  />
                </>
              )}
              <Void space={1} />

              <Collapse
                expandIcon={({ isActive }) => (
                  <RightOutlined
                    className="colBla"
                    rotate={isActive ? 90 : 0}
                  />
                )}
                ghost>
                <Panel
                  header={<span className="colBla">Reclamaciones</span>}
                  key="2">
                  <NavElement
                    nombre="Pendientes"
                    url="/reclamaciones_pendientes"
                    icon={<ExclamationCircleOutlined className="fs4" />}
                    collapsed={collapsed}
                  />
                  <NavElement
                    nombre="Finalizadas"
                    url="/reclamaciones_cerradas"
                    icon={<CloseOutlined className="fs4" />}
                    collapsed={collapsed}
                  />
                </Panel>
              </Collapse>
              <Void space={1} />
              {rol === 3 && (
                <>
                  <NavElement
                    nombre="Gestión de encuestas"
                    url="/encuestas"
                    icon={<QuestionCircleOutlined className="fs4" />}
                    collapsed={collapsed}
                  />
                </>
              )}

              <Void space={5} />
            </div>
          </Sider>
        )}

        {
          <div
            className="transition3 w100"
            style={{
              marginLeft: getMarginSider(),
              padding: "0.75em",
            }}>
            {children}
          </div>
        }
      </Layout>
    </Layout>
  );
});
