import dayjs from "dayjs";
import { validameConfig } from "validame";

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/customParseFormat"));
dayjs.extend(require('dayjs/plugin/duration'));
dayjs.extend(require('dayjs/plugin/relativeTime'));
dayjs.extend(require("dayjs/plugin/isBetween"));
dayjs.extend(require("dayjs/plugin/isToday"));

dayjs.tz.setDefault("Europe/Madrid");
dayjs.locale(require("dayjs/locale/es"));



const validacionCups = (textoParaValidar) => {
	
	let codigoPais = textoParaValidar.slice(0, 2); 			// ES
	// let idDistribuidora = textoParaValidar.slice(2, 6); 	// 0022
	let id = textoParaValidar.slice(6, 18); 				// 123456789012
	let control = textoParaValidar.slice(18, 20);			// AB
	// OF (opcional en luz, no se usa en gas)
	
	// let distribuidoras = ["0120","0030", "0021", "0022", "0026", "0027", "0033", "0034", "0168", "0304", "0031", "0032", "0230", "0229", "0238", "0234", "0218", "0221", "0220", "0239", "0230", "0237", "0226", "0225", "0216", "0227"];
	
	if (textoParaValidar.length === 0) return "CUPS inválido";
	
	if (codigoPais !== "ES") {
		return "Código país inválido";
	};
	
	// if( !distribuidoras.includes(idDistribuidora)){ 
	// 	return "No tenemos convenio con esa distribuidora"
	// }
	
	// if (! [].includes(idDistribuidora)) {
	// 	return "distribuidora inválida.";
	// };
	
	if (! /[0-9]{12}/.test(id)) {
		return "Id inválida.";
	};
	
	if (! /[A-Z]{2}/.test(control)) {
		return "Código de control inválido.";
	};
	
	if(textoParaValidar.length === 21) {
		return "El CUPS tiene que tener 20 o 22 caracteres."
	};
	
	if(textoParaValidar.length === 22) {
		let puntosFrontera = textoParaValidar.slice(20, 22);
		if ( puntosFrontera.toString() !== "1P" && puntosFrontera.toString() !== "0F") {
			// return "Puntos de frontera inválidos.";
			return "CUPS no válido.";
		};
	};
	
	
	return "";
};



const validacionCupsGas = (textoParaValidar) => {
	
	let codigoPais = textoParaValidar.slice(0, 2); 			// ES
	// let idDistribuidora = textoParaValidar.slice(2, 6); 	// 0022
	let id = textoParaValidar.slice(6, 18); 				// 123456789012
	let control = textoParaValidar.slice(18, 20);			// AB
	// OF (opcional en luz, no se usa en gas)
	
	// let distribuidoras = ["0120","0030", "0021", "0022", "0026", "0027", "0033", "0034", "0168", "0304", "0031", "0032", "0230", "0229", "0238", "0234", "0218", "0221", "0220", "0239", "0230", "0237", "0226", "0225", "0216", "0227"];
	
	if (textoParaValidar.length === 0) return "CUPS inválido";
	
	
	if (codigoPais !== "ES") {
		return "Código país inválido.";
	};
	
	// if( !distribuidoras.includes(idDistribuidora)){ 
	// 	return "No tenemos convenio con esa distribuidora"
	// }
	
	
	// if (! [].includes(idDistribuidora)) {
	// 	return "distribuidora inválida.";
	// };
	
	if (! /[0-9]{12}/.test(id)) {
		return "Id inválida.";
	};
	
	if (! /[A-Z]{2}/.test(control)) {
		return "Código de control inválido.";
	};
	
	if(textoParaValidar.length === 21) {
		return "El CUPS tiene que tener 20 o 22 caracteres."
	};
	
	if(textoParaValidar.length === 22) {
		let puntosFrontera = textoParaValidar.slice(20, 22);
		if ( puntosFrontera.toString() !== "1P" && puntosFrontera.toString() !== "0F") {
			// return "Puntos de frontera inválidos.";
			return "CUPS no válido.";
		};
	};
	
	
	return "";
};


validameConfig.symbols.cups = {
	regex: validacionCups,
};
validameConfig.symbols.cupsGas = {
	regex: validacionCupsGas,
};


