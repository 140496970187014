import React, { useMemo, useRef, useState } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";


/**
 * 
 * @param {*} param0 
 * @returns Select con las opciones que se han buscado en la llamada de fetch
 */
export default function DebounceSelect ({
    fetchOptions, 
    tipo,
    debounceTimeout = 800, 
    start = false,
    ...props
}) {
    
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    
    
    const debounceFetcher = useMemo(() => {
        
        const loadOptions = (value, tipo) => {
            if(start) value = "";
            // fetchRef.current += 1;
            // const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value, tipo).then((newOptions) => {
                // console.log('fetchId :>> ', fetchId, fetchRef.current);
                // if (fetchId !== fetchRef.current) {
                    
                //     // Por si hay callback
                //     return;
                // }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
    <Select
        labelInValue
        filterOption={true}
        onSearch={debounceFetcher}
        onFocus={(start)?debounceFetcher:""}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
        allowClear={true}
    />
    );
};