import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
export default function BoxStatistic({
  loading = true,
  icon = <LoadingOutlined spin className="box-icon" />,
  title = "",
  subtitle = "",
  onClick = null,
}) {
  const extraClasses = onClick !== null ? "info-box" : "";
  return (
    <div className={"box " + extraClasses} onClick={onClick}>
      {loading ? <LoadingOutlined spin className="box-icon" /> : icon}
      <b className="box-title">
        {loading ? <Skeleton.Input active size="small" /> : title}
      </b>
      <p className="box-title">
        {loading ? <Skeleton.Input active size="small" /> : subtitle}
      </p>
    </div>
  );
}
