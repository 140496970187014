
import React from "react";


/**
 * @typedef {Object} Props
 * @property {string} [space=1] Espacio en em que tendrá este vacío
 * @property {boolean} [divider=false] Determina si aparece el divisor horizontal.
*/

/**
 * Crea un vacío.
 * @param {Props} props
*/

export default function Void({
	space = 1, // espacio en em
	divider = false,
}) {
	
	return (

		<div
			className="aic jcc"
			style={{
				width: "100%",
				height: `${space}em`,
				// backgroundColor: "rgba(255, 0, 0, 0.3)",
			}}
		>
			{divider &&
				<div
					style={{
						width: "100%",
						height: "1px",
						backgroundColor: "rgba(0,0,0,0.1)",
					}}
				>
				</div>
			}
		</div>

	);

};
