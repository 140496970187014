import Axios from "axios";
// import { logout } from "../actions/logout";
import { mbxGlobal } from "../MobX/root";


const baseUrl = (process.env.REACT_APP_API_URL ?? "noPillaVariableDeEntorno").replace(/\/$/, ""); // compruebo si termina con "/" para eliminarla


/*
	(Ejemplos return) https://gyazo.com/4e5320d73e6b59b1b5b4720272664225
	
	Return:
		Con returnPromise en TRUE:
			PROMISE
		Con returnFullResponse en TRUE:
			En caso de status 200:
			OBJ = {
				data: {}, 			// respuesta de la api
				status: 200, 		// código HTTP devuelto por la api
				statusText: "OK", 	// mensaje del código HTTP
				headers: {}, 		// cabeceras del servidor
				config: {}, 		// opciones de axios que se usó al mandar la petición
				request: {} 		// request que generó esta respuesta
			}
			En caso de error:
			OBJ = {
				_error: true,		// esto sólo aparecerá si la respuesta no viene con status 200
				status: 200, 		// código HTTP devuelto por la api
				statusText: "OK", 	// mensaje del código HTTP
				data: {}, 			// respuesta de la api
				headers: {}, 		// cabeceras del servidor
				config: {}, 		// opciones de axios que se usó al mandar la petición
				request: {} 		// request que generó esta respuesta
			}
		Con returnFullResponse en FALSE:
			En caso de status 200:
			ANY: Respuesta de la API
			En caso de error:
			OBJ = {
				_error: true,							// esto sólo aparecerá si la respuesta no viene con status 200
				_status: errorResponse.status,			// código HTTP devuelto por la api
				_statusText: errorResponse.statusText,	// mensaje del código HTTP
				_response: (...resto de propiedades que devuelve la api)
			}
		.
	.
*/



/**
 * @typedef Options
 * @property {boolean} [ignoreBaseUrl=false] Ignorará la apiBaseUrl, se tendrá que poner la dirección completa en lugar de /endpoint.
 * @property {boolean} [returnPromise=false] Devolverá una promesa en lugar de un objeto con la respuesta.
 * @property {boolean} [returnFullResponse=false] Devolverá toda la respuesta, no sólo la propiedad "data".
 * @property {boolean} [preventRedirectOn401=false] Impedirá que se redirija al login ante las respuestas con 401.
*/



/**
 * @typedef ErrorResponse
 * @property {boolean} _error Esto sólo aparecerá si la respuesta no viene con status 200.
 * @property {number} _status Código HTTP devuelto.
 * @property {string} _statusText Mensaje del código HTTP.
 * @property {*} _response Body de la respuesta.
*/



/**
 * Hace una petición con Axios con los parámetros dados y devuelve la respuesta limpia o una promesa.
 * 
 * @param {"get" | "post" | "put" | "patch" | "delete"} [verbo="get"] Método de petición http.
 * @param {string} url - URL de la api. Tiene que empezar con "/".
 * @param {object} [body={}] - Objeto que será el body.
 * @param {boolean} [useToken=false] - Incluirá Bearer token en las cabeceras.
 * @param {Options} [options={}] Opciones de apiRequest.
 * @param {object} [axiosOptions={}] - Opciones de axios.
 * 
 * ---
 * 
 * @returns {Promise<ErrorResponse>} Object, a no ser que returnPromise = true que devolverá Promise.
 * 
 * ---
 * 
 * @example
 * // Get simple
 * res = await apiRequest("get", "/contratos");
 * 
 * @example
 * // Get ignorando baseUrl
 *	res = await apiRequest(
 *		"get",
 *		"https://rickandmortyapi.com/api/character/38",
 *		null,
 *		false,
 *		{ignoreBaseUrl: true}
 *	);
 *
 * @example
 * // Post simple
 *	res = await apiRequest("post", "/signup", {
 *		email: values.email,
 *		password: values.password
 *	});
 * 
*/

export default async function apiRequest (verbo = "get", url = "", body, useToken = false, options = {}, axiosOptions = {}) {
	
	if (!url) return console.error("url vacía");
	if (! ["get", "post", "put", "patch", "delete"].includes(verbo)) return console.error( "Verbo no válido." );
	
	
	// Tengo body, pero no estoy usando un verbo que lo admita
	if (body && !["post", "put", "patch", "delete"].includes(verbo)) {
		body = undefined;
	};
	
	
	// ¿Uso token?
	let token;
	
	
	if (useToken) {
		
		const type = typeof useToken;
		
		if (type === "boolean") {
			token = mbxGlobal.user?.token;
		} else if (type === "string") {
			token = useToken;
		};
		
		
		//! TEMPORAL
		// token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjM3LCJ1c2VybmFtZSI6ImF0b3J0YWphZGEiLCJuYW1lIjoiQWRyaWFuIFRvcnRhamFkYSIsInJvbGUiOjMsImlhdCI6MTYxOTE3NTUyMX0.ibdDUvD-obVLjb7asS43fMQt0p68ruqEnSKgr-9TkQ0";
		
	};
	
	
	
	// Creo cabeceras
	let headers = axiosOptions.headers ?? {}; // si tengo cabeceras en las opciones las pongo
	
	if (token) {
		headers.Authorization = `Bearer ${token}`;
	};
	
	
	// Establezco opciones de axios
	let axiosRequestOptions = {
		...axiosOptions,
		headers: headers
	};
	
	
	// Compruebo opciones
	let fullUrl;
	
	if (options.ignoreBaseUrl) {
		fullUrl = url;
	} else {
		// fullUrl = baseUrl + "/api" + url;
		fullUrl = baseUrl + url;
	};
	
	
	// Hago llamada
	// const fullUrl =  "https://api-dot-front-llamadas-270212.appspot.com" + url;
	
	const axios = Axios[verbo];
	let promise = body ? axios(fullUrl, body, axiosRequestOptions) : axios(fullUrl, axiosRequestOptions);
	
	if (options.returnPromise) { // sólo quiero devolver promesa
		return promise;
	};
	
	// Espero la llamada
	try {
		
		let res = await promise;
		
		
		if (options.returnFullResponse) { // devuelvo todo
			return res;
		} else { // sólo devuelvo data
			return res.data;
		};
		
	} catch (err) {
		
		let errorResponse = err.response;
		
		
		// Sin conexión
		if (! errorResponse) {
			return {
				_error: true,
				_status: 0,
				_statusText: "Sin conexión",
			};
		};
		
		
		if (!options.preventRedirectOn401 && errorResponse.status === 401) {
			// logout();
		};
		
		
		
		// Devuelvo todo como axios
		if (options.returnFullResponse) { 
			
			errorResponse._error = true;
			return errorResponse;
		
		// Devuelvo lo necesario
		} else {

			return {
				_error: true,
				_status: errorResponse.status,
				_statusText: errorResponse?.statusText ?? "Network error",
				_response: errorResponse?.data ?? null,
			};

		};

	};

};
