import { useEffect, useState, useMemo } from "react";
import apiRequest from "utils/apiRequest";

const useGroupedData = (id_encuesta, filters, groupby, global = false) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const filtersToEndpoint = global ? {} : filters;
  useMemo(() => {
    setLoading(true);
    if (id_encuesta !== null) {
      apiRequest(
        "get",
        `/encuestas/grouped_results/${id_encuesta}?filters=${JSON.stringify(
          filtersToEndpoint
        )}&groupby=${groupby}`,
        null,
        true
      )
        .then((response) => {
          if (response.error === undefined) {
            if (groupby === "valoracion") {
              setData(response.sort((a, b) => b.count - a.count));
            } else {
              setData(response);
              setLoading(false);
            }
          } else {
            setError(true);
          }
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    } else {
      setError(true);
    }
  }, [id_encuesta, filters]);
  return {
    grouped_data: data,
    loading,
    error,
  };
};
export default useGroupedData;
