import React, { useEffect, useState } from "react";
import useEncuesta from "./hooks/useEncuesta";
import useEncuestaAnalytics from "./hooks/useEncuestaAnalytics";
import { Pie } from "@ant-design/plots";
import { Empty } from "antd";
import "./styles.scss";
import { DatePicker } from "antd";
import TablaResultados from "./TablaResultados";
import useTopData from "./hooks/useTopData";
import { mbxGlobal } from "MobX/root";
import useEncuestaData from "./hooks/useEncuestaData";

export default function VistaTopEncuesta() {
  const filters = { tops: mbxGlobal.user?.id };
  const { encuestaLoaded, loading, error } = useEncuesta("any");
  //Obtenemos los resultados del top filtrado
  const {
    results,
    loading: resultsLoading,
    error: resultsError,
  } = useEncuestaAnalytics("any", filters);

  //Resultados globales de gana
  const { results: resultsGlobal } = useEncuestaAnalytics("any", {
    ...filters,
    tops: undefined,
  });

  const { top } = useTopData(mbxGlobal.user?.id);
  //Resultados por turno
  const { results: resultsTurno } = useEncuestaAnalytics("any", {
    ...filters,
    tops: undefined,
    turno: top?.turno === "AM" ? 1 : 2,
  });
  const preguntas =
    !loading &&
    !error &&
    encuestaLoaded
      ?.map((encuesta) => encuesta.preguntas)
      .flat(Infinity)
      .map((pagina) => pagina.preguntas)
      .flat(Infinity);

  const { loadingView, resultsEncuesta } = useEncuestaData(
    results,
    preguntas,
    filters
  );

  const configInitial = {
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent, value }) =>
        `${(percent * 100).toFixed(0)}% (${value})`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  return (
    <>
      <h1>
        Resultados de {top?.nombre ?? ""} {top?.apellidos ?? ""}
      </h1>
      <div className="flex-list">
        <table className="table-analytics">
          <thead>
            <tr>
              <th>Estadisticas generales</th>
            </tr>
          </thead>
          <tbody>
            <tr className="row-analytics">
              <td className="row-content-analytics row-caracteristica">
                Encuestas generadas/creadas
              </td>
              <td className="row-content-analytics">
                {results.countLanzadas ?? 0}
              </td>
            </tr>
            <tr className="row-analytics">
              <td className="row-content-analytics row-caracteristica">
                Encuestas enviadas por correo
              </td>
              <td className="row-content-analytics">
                {results.countEnviadas ?? 0}
              </td>
            </tr>
            <tr className="row-analytics">
              <td className="row-content-analytics row-caracteristica">
                Encuestas respondidas
              </td>
              <td className="row-content-analytics">
                {results.countRespuestas ?? 0}
              </td>
            </tr>

            {results?.media?.map((media, index) => {
              return (
                <tr className="row-analytics" key={index}>
                  <td className="row-content-analytics row-caracteristica">
                    Tu Media{" "}
                    {
                      preguntas.find(
                        (pregunta) => pregunta.id_pregunta === media._id
                      ).heading
                    }
                  </td>
                  <td className="row-content-analytics">
                    {media.avg.toFixed(2)}
                  </td>
                </tr>
              );
            })}
            {resultsGlobal?.media?.map((media, index) => {
              return (
                <tr className="row-analytics" key={index}>
                  <td className="row-content-analytics row-caracteristica">
                    Media Gana{" "}
                    {
                      preguntas.find(
                        (pregunta) => pregunta.id_pregunta === media._id
                      ).heading
                    }
                  </td>
                  <td className="row-content-analytics">
                    {media.avg.toFixed(2)}
                  </td>
                </tr>
              );
            })}
            {resultsTurno?.media?.map((media, index) => {
              return (
                <tr className="row-analytics" key={index}>
                  <td className="row-content-analytics row-caracteristica">
                    Media de tu turno {top?.turno ?? ""}{" "}
                    {
                      preguntas.find(
                        (pregunta) => pregunta.id_pregunta === media._id
                      ).heading
                    }
                  </td>
                  <td className="row-content-analytics">
                    {media.avg.toFixed(2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="grid-view">
        {preguntas &&
          preguntas?.map((pregunta, index) => {
            return (
              <div key={index}>
                <h1 className="question-heading">{pregunta.heading}</h1>
                <h1 className="question-subheading">
                  {pregunta.parent
                    ? "Pregunta padre:" +
                      preguntas?.find(
                        (element) => element.id_pregunta === pregunta.parent
                      ).heading
                    : ""}
                </h1>
                {resultsEncuesta[index] &&
                resultsEncuesta[index].every(
                  (element) => element.type !== "texto"
                ) &&
                Object.keys(resultsEncuesta[index]).length > 0 ? (
                  <Pie {...configInitial} data={resultsEncuesta[index]} />
                ) : resultsEncuesta[index] &&
                  Object.keys(resultsEncuesta[index]).length > 0 &&
                  resultsEncuesta[index].every(
                    (element) => element.type === "texto"
                  ) ? (
                  <TablaResultados data={resultsEncuesta[index]} />
                ) : (
                  <Empty />
                )}
              </div>
            );
          })}
      </div>
    </>
  );
}
