
import dayjs from "dayjs";



/**
 * Devuelve un objeto con las fechas predefinidas para el RangePicker de Ant
*/
export default function getDateRanges() {
	
	return {
		"Hoy": [dayjs(), dayjs()],
		"Ayer": [
			dayjs().subtract(1, "day").startOf("day"),
			dayjs().subtract(1, "day").endOf("day"),
		],
		"Esta semana": [
			dayjs().startOf("week"),
			dayjs(),
		],
		"Mes en curso": [
			dayjs().startOf("month"),
			dayjs().subtract(1, "day").endOf("day"),
		],
		"Mes anterior": [
			dayjs().subtract(1, "month").startOf("month"),
			dayjs().subtract(1, "month").endOf("month"),
		],
		"Año en curso": [
			dayjs().startOf("year"),
			dayjs().subtract(1, "day").endOf("day"),
		],
		"Últimos 7 días": [
			dayjs().subtract(7, "day"),
			dayjs().subtract(1, "day").endOf("day"),
		],
		"Últimos 30 días": [
			dayjs().subtract(30, "day"),
			dayjs().subtract(1, "day").endOf("day"),
		],
		"Últimos 365 días": [
			dayjs().subtract(365, "day"),
			dayjs().subtract(1, "day").endOf("day"),
		],
		"Desde siempre": [
			dayjs("2015-01-01").startOf("day"),
			dayjs().subtract(1, "day").endOf("day"),
		],
	};
	
};