import React, { useReducer } from "react";
import margenesReducer, { initialState } from "./margenes_reducer";
import "./Margenes.css";
import FiltrosMargenes from "./FiltrosMargenes";
import { Col, Row, Spin } from "antd";

export default function MargenesNew({}) {
  const [state, dispatch] = useReducer(margenesReducer, initialState);
  const { resultado_general } = state;
  return (
    <>
      {state.cargando && (
        <div className="fullWidth tac">
          {" "}
          <Spin size="large" className="tac" />
          <br />
          <br />
          Calculando
        </div>
      )}
      <h3>Márgenes</h3>
      <FiltrosMargenes state={state} dispatch={dispatch} />
      <Row flex={1} className="mt2">
        <Col flex={1} className="bloque">
          <b>Total Facturas:</b> {resultado_general?.total_facturas ?? 0} &nbsp;{" "}
          <b>Total Consumo:</b> {resultado_general?.consumo_total ?? 0} kWh
          &nbsp;
          <b>Consumo Promedio:</b>{" "}
          {resultado_general?.consumo_promedio?.toFixed(2) ?? 0} kWh &nbsp;
        </Col>
      </Row>
      <Row flex={1} className="bloque mt2">
        <Col xs={24} sm={12} md={6} lg={4}>
          <div>
            <b>Margen Energía:</b>{" "}
            {resultado_general?.margen_energia?.toFixed(2) ?? 0} €
          </div>
          <div>
            <b>Total Energía:</b>{" "}
            {resultado_general?.total_energia?.toFixed(2) ?? 0} €
          </div>
          <div>
            <b>% Energía:</b>{" "}
            {(
              (resultado_general?.porcentaje_margen_energia ?? 0) * 100
            ).toFixed(2) ?? 0}{" "}
            %
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <div>
            <b>Margen Potencia:</b>{" "}
            {resultado_general?.margen_potencia?.toFixed(2) ?? 0} €
          </div>
          <div>
            <b>Precio Medio Ponderado:</b>{" "}
            {resultado_general?.precio_margen?.toFixed(6) ?? 0} €/kWh{" "}
          </div>
          <div>
            <b>Total Potencia:</b>{" "}
            {(
              (resultado_general?.total_potencia ?? 0)
            ).toFixed(2) ?? 0}{" "}
             €
          </div>
          <div>
            <b>% Potencia:</b>{" "}
            {(
              ((resultado_general?.total_potencia ?? 0 )/0 ?? 0) * 100
            ).toFixed(2) ?? 0}{" "}
             €
          </div>
        </Col>
      </Row>
    </>
  );
}
