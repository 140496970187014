import React, { useEffect, useState } from "react";

export default function Report() {

    const [altura, setAltura] = useState("600px");
    const [anchura, setAnchura] = useState("100%");
    
    useEffect(() => {
        // Obtener la altura de la pantalla
        const height = window.innerHeight;
        // Setear la altura del iframe
        setAltura(`${height}px`);

        // Obtener la anchura de la pantalla
        const width = window.innerWidth;
        // Setear la anchura del iframe
        // setAnchura(`${width}px`);

        console.log('inicio :>> ', altura, height);
    }, []);

    return (
      <div style={{textAlign:"center"}}>
        <iframe
          src="https://lookerstudio.google.com/embed/reporting/feb74f59-1f53-47c5-aa26-12b6c2f5e468/page/vuBND"
          style={{ border: "none", width: anchura, height: altura, maxWidth:"1400px"}}
        //   width="100%"
        //   height={altura}
        ></iframe>
      </div>
    );
}