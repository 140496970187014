
const tiposPausa = {
	"baño": {
		nombre: "Baño",
		emoji: "🚽",
	},
	"formacion": {
		nombre: "Formación",
		emoji: "📘",
	},
	// "gestiones": {
	// 	nombre: "Gestiones",
	// 	emoji: "📝",
	// },
	"descanso": {
		nombre: "Descanso",
		emoji: "🛏️",
	},
	"incidenciaTecnica": {
		nombre: "Incidencia técnica",
		emoji: "🔧",
	},
	"tareas": {
		nombre: "Tareas",
		emoji: "🪛",
	},
};


export default tiposPausa;