import { useMemo, useState } from "react";
import apiRequest from "../../../utils/apiRequest";

/*
 * Custom Hook utilizado para obtener datos de tops
 */
const useTopsData = (filters, id_encuesta) => {
  const [tops, setTops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [prevFilter, setPrevFilter] = useState([]);

  useMemo(() => {
    if (JSON.stringify(filters) !== JSON.stringify(prevFilter)) {
      setLoading(true);
      setPrevFilter(filters);
      if (filters !== null) {
        apiRequest(
          "get",
          `/encuestas/media_tops?filters=${JSON.stringify(
            filters
          )}&id_encuesta=${id_encuesta}`,
          null,
          true
        )
          .then((response) => {
            if (response.error === undefined) {
              setTops(response);
            } else {
              setError(true);
            }
          })
          .catch(() => setError(true))
          .finally(() => setLoading(false));
      } else {
        setError(true);
      }
    }
  }, [filters, id_encuesta]);
  return { tops, loading, error };
};
export default useTopsData;
