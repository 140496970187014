import { useEffect, useState, useMemo } from "react";
import apiRequest from "../../../utils/apiRequest";

/*
 * Custom Hook utilizado para obtener datos de una encuesta para las análiticas
 */
const useEncuestaAnalytics = (id, filters) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [prevFilter, setPrevFilter] = useState([]);
  useMemo(() => {
    // Para evitar que hagamos más peticiones de las necesarias
    if (JSON.stringify(filters) !== JSON.stringify(prevFilter)) {
      setLoading(true);
      setPrevFilter(filters);
      if (id !== null) {
        apiRequest(
          "get",
          `/encuestas/analytics/${id}?filters=${JSON.stringify(filters)}`,
          null,
          true
        )
          .then((response) => {
            if (response.error === undefined) {
              setResults(response);
            } else {
              setError(true);
            }
          })
          .catch(() => setError(true))
          .finally(() => setLoading(false));
      } else {
        setError(true);
      }
    }
  }, [id, filters]);
  return { results, loading, error };
};
export default useEncuestaAnalytics;
