import { useEffect, useState } from "react";
import apiRequest from "../../../utils/apiRequest";

/*
 * Custom Hook utilizado para obtener datos de una encuesta
 */
const useEncuesta = (id) => {
  const [encuesta, setEncuesta] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (id !== null) {
      apiRequest("get", "/encuesta?id=" + id, null, true)
        .then((response) => {
          if (response.error === undefined) {
            setEncuesta(response.encuesta);
          } else {
            setError(true);
          }
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    } else {
      setError(true);
    }
  }, [id]);
  return { encuestaLoaded: encuesta, loading, error };
};
export default useEncuesta;
