import React, { useState, useEffect, useMemo } from "react";
import apiRequest from "utils/apiRequest";
import useQuery from "./useQuery";

export default function useEncuestaData(results, preguntas, filters) {
  const [loadingView, setLoadingView] = useState(false);
  const [resultsEncuesta, setResultsEncuesta] = useState([]);
  const [resultsTextEncuesta, setResultsTextEncuesta] = useState([]);

  const [prevFilter, setPrevFilter] = useState(null);
  const query = useQuery();
  const controller = new AbortController();
  useMemo(() => {
    if (
      preguntas &&
      (prevFilter === null ||
        JSON.stringify(filters) !== JSON.stringify(prevFilter))
    ) {
      setPrevFilter(filters);
      setLoadingView(true);
      const preguntasIds =
        preguntas && preguntas?.map((pregunta) => pregunta.id_pregunta);
      if (preguntasIds && preguntasIds.length > 0) {
        const params = new URLSearchParams();
        const id_encuesta =
          query.get("id").length >= 24
            ? query.get("id")
            : query.get("id_encuesta");
        params.append("id_encuesta", id_encuesta ?? "any");
        preguntasIds.forEach((id) => params.append("id_pregunta", id));
        apiRequest(
          "get",
          "/encuestas/answers?" +
            params.toString() +
            "&filters=" +
            JSON.stringify(filters),
          null,
          true,
          undefined,
          { signal: controller.signal }
        )
          .then((response) => {
            if (response.error === undefined && response.valor) {
              let chartData = [];
              let textResultsData = [];
              response.valor?.forEach((element) => {
                let mapData = [];
                //Para cada una de las que sea necesario generar un gráfico
                const id_pregunta = element._id;
                const preguntaFind = preguntas.find(
                  (pregunta) => pregunta.id_pregunta === id_pregunta
                )?.content;
                const respuestas = element.respuestas;
                if (preguntaFind) {
                  respuestas.forEach((respuesta) => {
                    const valor = preguntaFind.find(
                      (resp) => resp.value === respuesta.value
                    );
                    if (valor) {
                      const tags = {
                        normal: "Normal",
                        good: "Bien",
                        bad: "Mal",
                      };
                      if (mapData.some((val) => val.type === valor.text)) {
                        const index = mapData.findIndex(
                          (val) => val.type === valor.text
                        );
                        mapData[index] = {
                          ...mapData[index],
                          value: mapData[index].value + respuesta.count,
                        };
                      } else {
                        mapData.push({
                          value: respuesta.count,
                          type: tags[valor.tipo] ?? valor.text,
                        });
                      }
                    }
                  });

                  chartData[id_pregunta] = mapData;
                }
              });
              response.text?.forEach((element) => {
                const textData = [];
                //Para cada una de las que sea necesario generar un gráfico
                const id_pregunta = element._id;
                const preguntaFind = preguntas.find(
                  (pregunta) => pregunta.id_pregunta === id_pregunta
                );
                const respuestas = element.respuestas;
                if (preguntaFind) {
                  respuestas.forEach((respuesta) => {
                    textData.push({
                      value: respuesta.value,
                      email_envio: respuesta.email_envio.toString(),
                      value_pregunta: respuesta.value_pregunta,
                    });
                  });
                }
                textResultsData[id_pregunta] = textData;
              });
              setResultsTextEncuesta(textResultsData);
              setResultsEncuesta(chartData);
              setLoadingView(false);
            } else {
              console.error("error");
            }
          })
          .catch((error) => {
            setLoadingView(false);
            console.error("error", error);
          });
      }

      return () => {
        controller.abort();
      };
    }
  }, [preguntas, results, filters]);
  return { loadingView, resultsEncuesta, resultsTextEncuesta };
}
