import { EditOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { Button, List, message, Select, Tabs } from 'antd';
import 'rc-color-picker/assets/index.css';
import React, { useEffect, useState } from 'react';
import apiRequest from '../../utils/apiRequest';
import Tarjeta from "components/Card/Tarjeta";
import FlatCard from "components/FlatCard/FlatCard";
import Transfer from "components/Transfer";
import Entrada from "components/Entrada";
import Void from "atomicComponents/Void";
import SimpleModal from "atomicComponents/SimpleModal";


const { TabPane } = Tabs;

// TODO : FALTA DESHABILITAR EL BOTON DE GUARDAR
// TODO : FALTA ACTUALIZAR A LOS TOPS

const Bloques = () => {

    const [tops, setTops] = useState([]);
    const [campanas, setCampanas] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenMail, setIsModalOpenMail] = useState(false);
    const [bloques, setBloques] = useState(null);
    const [bloqueSeleccionado, setBloqueSeleccionado] = useState(null);
    const [filtros, setFiltros] = useState({});

    const [crearVisible, setCrearVisible] = useState(false);
    const [crearBloque, setCrearBloque] = useState({ nombre: "", campanas: [] });

    const [selectedKeys, setSelectedKeys] = useState({});
    const [guardar, setGuardar] = useState(false);

    const obj_bloqueSeleccionado = bloques ? bloques.find( _bloq => _bloq._id === bloqueSeleccionado ) : null;

    //Seleccionamos el grupo de usuarios del localStorage
    useEffect(() => {
        
        const getUser = JSON.parse(localStorage.getItem("global"))
        const userGroups = getUser?.user?.user_groups || []; //esto nos devuelve un array de numeros ej:[4,7,8]

        //recorremos el array para ver si hay alguna coincidencia
        const isValueInUserGroups = [4, 6, 7, 8].some(value => userGroups.includes(value));

        //si la hay ponemos el Boton de crear a true
        if (isValueInUserGroups) {
            setCrearVisible(true)
        }

    }, [])
    
    useEffect(() => {
        getTops();
        getCampanas();
        getBloques();
    }, []);
    
    const getTops = async () => {
        try {
                
            const getTops = await apiRequest("get", "/top/all", null, true);
            setTops(getTops);

        } catch (err) {
            return message.error("Error al obtener los datos");
        }
    }
    const getCampanas = async () => {
        try {
                
            const getCampanas = await apiRequest("get", "/campana", null, true);
            setCampanas(getCampanas);

        } catch (err) {
            return message.error("Error al obtener los datos");
        }
    }
    const getBloques = async () => {
        try {

            const getBloques = await apiRequest("get", `/bloques`, null, true);
            setBloques(getBloques);

            const obj_final = {};
            getBloques.forEach(_bloque => {
                obj_final[_bloque.nombre] = { _id : _bloque._id, campanas : _bloque.campanas.map( _cam => _cam._id ), caracteristicas : _bloque.caracteristicas };
            });
            setSelectedKeys( obj_final );

        } catch (err) {
            return message.error("Error al obtener los datos");
        }
    }

    const loading = !bloques || !tops || !campanas;
    if ( loading ) return  <div className="w100 jcc fs5 pt5"> <LoadingOutlined /> </div>

    return <div className="flex jcc">

        <div className="w80 minw70em">

            <span className="fdc m3">
                <p className="fs6 bold tac colVerCor"> Editor bloques </p>
            </span>          

            <Void space={.5} />
            <Void space={1} divider={true} />

            <div
                className="fww"
            >
                {bloques.map((_bloque, indx) => {

                    const listadoTops = tops.filter(_top => _top.bloques && _top.bloques.includes(_bloque._id ) ).slice().sort((a, b) => a.nombre.localeCompare(b.nombre));

                    return <Tarjeta
                        key={indx}
                        className="w46 m2"
                        titulo={<div className="flex">
                            <EditOutlined
                                className="fs3 mr3 aic mb1"
                                onClick={(event) => {
                                    setBloqueSeleccionado(_bloque._id)
                                    event.stopPropagation();
                                }}
                            />
                            <p className="fs2"> {_bloque.nombre} </p>
                        </div>}
                        children={
                            <div className="pl2 pr2">
                                <Tabs defaultActiveKey="1" centered>
                                    <TabPane tab={`TOPS (${ listadoTops.length })`} key={`TOP_${indx}`}>
                                        <Entrada
                                            type="text"
                                            value={filtros[`${_bloque.nombre}`]?.searchTop}
                                            setValue={(search) => setFiltros({ ...filtros, [`${_bloque.nombre}`]: { ...filtros[`${_bloque.nombre}`], searchTop: search } })}
                                        />
                                        <List
                                            className="demo-loadmore-list h10em ofys"
                                            itemLayout="horizontal"
                                            dataSource={listadoTops}
                                            renderItem={(item) => <p className="m2"> {item.nombre} </p>}
                                        /> 
                                    </TabPane>
                                    <TabPane tab={`Campañas (${_bloque.campanas.filter(_cam => {
                                        const busqueda = filtros[`${_bloque.nombre}`]?.searchCam ? filtros[`${_bloque.nombre}`]?.searchCam.toLowerCase() : "";
                                        return _cam.nombre.toLowerCase().includes(busqueda);
                                    }).slice().length})`} key={`CAM_${indx}`}>
                                       
                                        <Entrada
                                            type="text"
                                            value={filtros[`${_bloque.nombre}`]?.searchCam}
                                            setValue={(search) => setFiltros({ ...filtros, [`${_bloque.nombre}`]: { ...filtros[`${_bloque.nombre}`], searchCam: search } })}
                                        />
                                        
                                      
                                        <List
                                            className="demo-loadmore-list h10em ofys"
                                            itemLayout="horizontal"
                                            dataSource={
                                                _bloque.campanas.filter(_cam => {
                                                    const busqueda = filtros[`${_bloque.nombre}`]?.searchCam ? filtros[`${_bloque.nombre}`]?.searchCam.toLowerCase() : "";
                                                    return _cam.nombre.toLowerCase().includes(busqueda);
                                                }).slice().sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente por 'nombre'
                                            }
                                            renderItem={(item) => <p className="m2"> {item.nombre} </p>}
                                        />
                                    </TabPane>
                                </Tabs>
                            </div>
                        }
                        collapse
                        collapseDefaultOpen
                    />
                })
                }
            </div>
            {
                crearVisible && 
                <div className="mt4 jcsb">
                    <Button
                        type="primary"
                        onClick={() => setIsModalOpen(true)}
                    >CREAR NUEVO BLOQUE</Button>
                    <SimpleModal
                        showModal={isModalOpen}
                        setShowModal={setIsModalOpen}
                        title="Crear bloque"
                        body={
                            <div className="flex fdc">
                                <p>Introduzca el nombre del Bloque</p>
                                <Entrada
                                    type="text"
                                    label="Nombre"
                                    value={crearBloque.nombre}
                                    setValue={(e) =>
                                        setCrearBloque({
                                            ...crearBloque,
                                            nombre: e
                                        })
                                    }
                                />
                            </div>
                        }
                        onOk={async () => {

                            const valido = (() => {
                                if ( !crearBloque.nombre ) return false;
                                if ( bloques.find( _x => _x.nombre === crearBloque.nombre ) ) return false;
                                return true;
                            })();

                            if ( !valido ) return message.error( "El bloque debe tener nombre y que sea unico" );
                            message.success("Bloque creado correctamente!");

                            const nuevoBloque = await apiRequest("post", "/bloques", { bloque: crearBloque }, true);
                            setBloques([...bloques, nuevoBloque.ops[0]]);
                            setIsModalOpen(false);
                        }}
                    />  
                        <Button
                            type="primary"
                            onClick={() => setIsModalOpenMail(true)}
                        ><SendOutlined /> EMAIL CAMPAÑAS SIN BLOQUE</Button>
                        <SimpleModal
                            showModal={isModalOpenMail}
                            setShowModal={setIsModalOpenMail}
                            
                            body={
                                <div className="flex fdc">
                                    <h2>Se va a enviar un email con las campañas sin asignar a ningún bloque</h2>
                                    
                                    <p className="mt3">Recuerda este email, solo se puede enviar 1 vez por hora como máximo</p>
                                </div>
                            }
                            onOk={async () => {

                                const { ok, message: msj } = await apiRequest("post", "/bloquesRestantesMail", {} , true);

                                if(!ok) {
                                    message.error(msj);
                                    return setIsModalOpenMail(false);
                                }
                                
                                message.success("Mail enviado correctamente! revisa tu bandeja de entrada");
                                return setIsModalOpenMail(false);

                            }}
                        />
                </div>
            }
           
            {
                bloqueSeleccionado && <div>
                    <Void space={3} divider/>

                    <div className="flex jcc fs6">
                        BLOQUE ACTUAL: <span className="ml3 bold"> { obj_bloqueSeleccionado.nombre } </span>
                    </div>

                    <Void space={3} divider/>
                </div>
            }

            {bloqueSeleccionado && <FlatCard
                initialCollapsed={ false }
                collapsable
                title={<p className="fs5 bold colVerCor"> CAMPAÑAS</p>}
            >

                <Transfer
                    dataSource={campanas}
                    oneWay={false}
                    valueKey="_id"
                    searchFnc= {(inputValue, option) => {
                            const regex = new RegExp(`.*${inputValue}.*`, "i");
                                if (regex.test(option.nombre)) return true;
	                    }}
                    render={item => item.nombre}
                    rowKey={item => item._id}
                    value={selectedKeys[obj_bloqueSeleccionado.nombre]?.campanas}
                    setValue={(transfered, direction, moveKeys) => {

                        let finalArray = [ ...selectedKeys[obj_bloqueSeleccionado.nombre]?.campanas ];

                        if ( direction === "right" ) finalArray = [...finalArray, ...moveKeys];
                        else if ( direction === "left" ) finalArray = finalArray.filter( _x => !moveKeys.includes( _x ) );

                        setSelectedKeys({
                            ...selectedKeys,
                            [obj_bloqueSeleccionado.nombre]: {
                                _id: obj_bloqueSeleccionado._id,
                                campanas: finalArray,
                                caracteristicas: obj_bloqueSeleccionado.caracteristicas
                            }
                        })
                    }}
                />

            </FlatCard>
            }

            <Button
                size="large"
                type="primary"
                className="w100 mt5 mb2"
                disabled={!obj_bloqueSeleccionado || guardar}
                onClick={async () => {

                    message.info("Guardando los datos...");
                    setGuardar(true);

                    setBloqueSeleccionado(null);

                    // "nuevosBloques" ES PARA EDITAR LOS BLOQUES EN LOCAL
                    const nuevosBloques = [...bloques];
                    const bulkBloques = Object.keys(selectedKeys).map(_key => {

                        const obj = selectedKeys[_key]
                        const nuevas_campanas = campanas
                            .filter(_cam => obj.campanas.includes(_cam._id))
                            .map(_cam => { return { _id: _cam._id, nombre: _cam.nombre, descripcion: _cam.descripcion } })

                        nuevosBloques.find(_bloque => _bloque._id === obj._id).campanas = nuevas_campanas;

                        return {
                            updateOne: {
                                filter: { _id: obj._id },
                                update: {
                                    $set: {
                                        campanas: nuevas_campanas
                                    }
                                }
                            }
                        }

                    })
                    setBloques(nuevosBloques);

                    // HACEMOS SUBGRUPOS CON LOS TELEOPERADORES PARA NO HACER 200 CONSULTAS
                    //
                    // CASO: 
                    //      20 TOPS SON: 
                    //          - 20 COMBINACIONES DE CAMPAÑAS DE LOS BLOQUES 
                    //          - 20 ELIMINACIONES DE CAMPAÑAS DUPLICADAS
                    //          - 20 UPDATE ONE EN MONGO
                    //
                    // SOLUCION (EJEMPLO):
                    //      20 TOPS SON: 
                    //          - 7 GRUPOS DE [ BLOQUE_01, BLOQUE_02, BLOQUE_03 ], [ BLOQUE_01, BLOQUE_02 ], [ BLOQUE_02 ], ...
                    //          - 7 ELIMINACIONES DE CAMPAÑAS DUPLICADAS
                    //          - 7 UPDATE MANY EN MONGO
                    const teleOpsByBloques = (() => {
                        const subgrupos = {};
                        tops.forEach( _top => {
                            if ( !_top.bloques ) return;
                            const nombreBloque = (_top.bloques.sort().join(","));
                            if ( subgrupos[nombreBloque] ) subgrupos[nombreBloque].push(_top._id);
                            else subgrupos[nombreBloque] = [ _top._id ];
                        });
                        return subgrupos;
                    })();

                    const bulkTops = Object.keys(teleOpsByBloques).map(_key => {

                        const subgrupoTops = teleOpsByBloques[_key];

                        const bloquesId = _key.split(",");
                        const bloquesOriginales = bloques.filter( _bloque => bloquesId.includes(_bloque._id) );

                        const campanas = (() => {
                            let _campanas = [];
                            bloquesOriginales.forEach( _bloque => _campanas.push(..._bloque.campanas) );
                            _campanas.filter( (_campana, _indx) => _campanas.findIndex((_x) => _x._id === _campana._id) === _indx );
                            return _campanas;
                        })();

                        return {
                            updateMany: {
                                filter: { 
                                    "$and": [
                                        { "$or": subgrupoTops.map(_x => ({ _id: _x })) }, 
                                        { "deshabilitado" : { $ne : true } }
                                    ]
                                }, 
                                update: { $set: { campanas: campanas, bloques: bloquesId } }
                            }
                        }
                    })

                    await apiRequest("put", "/bloques", { bulk: bulkBloques }, true);
                    await apiRequest("put", "/campanasTops", { bulk: bulkTops }, true);

                    setGuardar(false);

                    message.success("Cambios guardados!");

                }}
            >
                Guardar
            </Button>
        </div>

    </div>

};

export default Bloques;