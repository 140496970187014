import { Select } from 'antd';
import React from 'react';

const { Option } = Select;



/**
 * @typedef Props
 * @property {string} propiedad1
 */

/**
 * 
 * @param {Props} Props.
*/
const SelectMultiFiltro = ({
	placeHolder,
	data,
	// multiFiltro,
	style,
	onChange = () => {},
	value,
    mode="multiple",
	is50 = false,
    disabled = false
}) => {
    
    if(!style) style = { width: 250, marginTop: 15 }; 

    let contieneTexto = false;
    if(data.length > 0) {
        if(data[0].text) {
            contieneTexto = true
        }
    }


    return (
        <div>
            {
                ( is50 === true ) 
            ?
                <Select 
                // showSearch 
                mode={mode} 
                allowClear
                // mode="tags" 
                style={style} 
                placeholder={placeHolder} 
                onChange={onChange} 
                tokenSeparators={[',']}
                optionFilterProp="children"
                filterOption={(input, option) =>{        
                    return Object.values( option ).some( _x  => ( "" + _x ).toLowerCase().includes( input.toLowerCase() ) );
                }}
                value={value}
                disabled={disabled}
                >
                    {
                        data.map(_x => {    
                            return (
                                <Option value={_x.value} key={_x.value}>{_x.text}</Option>
                            )
                        })
                    }
                </Select>
            :
                (contieneTexto === false)
            ?
                <>
                <Select 
                    mode={mode} 
                    allowClear
                    style={style} 
                    placeholder={placeHolder} 
                    onChange={onChange} 
                    tokenSeparators={[',']}
                    optionFilterProp="children"
                    filterOption={(input, option) =>{
                        return Object.values( option ).some( _x  => ( "" + _x ).toLowerCase().includes( input.toLowerCase() ) );
                    }}
                    options={data}
                    value={value}
                    disabled={disabled}
                />
                </>
            : 
                <>
                <Select 
                // showSearch 
                mode={mode} 
                labelInValue
                allowClear
                // mode="tags" 
                style={style} 
                placeholder={placeHolder} 
                onChange={onChange} 
                tokenSeparators={[',']}
                optionFilterProp="children"
                filterOption={(input, option) =>{
                    return Object.values( option ).some( _x  => ( "" + _x ).toLowerCase().includes( input.toLowerCase() ) );        
                }}
                value={value}
                disabled={disabled}
                >
                    {
                        data.map(_x => {    
                            return (
                                <Option value={_x.key} key={_x.value}>{_x.text}</Option>
                            )
                        })
                    }
                </Select>
                </>
            }
        </div>
    )
}

export default SelectMultiFiltro;
