

import 'rc-color-picker/assets/index.css';
import React, { useEffect, useState } from 'react';
import apiRequest from '../../utils/apiRequest';
import FlatCard from "components/FlatCard/FlatCard";
import Transfer from "components/Transfer";
import { Button, Checkbox, message } from 'antd';
import SelectCaracteristicas from 'components/selectCaracteristicas';

const EditarTops = () => {

    const [topsList, setTopsList] = useState([]);
    const [selectedTops, setSelectedTops] = useState([]);
    const [caracteristicas, setCaracteristicas] = useState(null);

    const [bloques, setBloques] = useState(null);
    const [selectedBloque, setSelectedBloque] = useState(null);

    const bloqueSeleccionado = selectedBloque ? bloques.find( _bloque => _bloque._id.toString() === selectedBloque.toString() ) : undefined;

    // PILLO TOPS, CAMPAÑAS Y CARACTERISTICAS
    useEffect(() => {
        (async () => {

            try {

                const getTops = await apiRequest("get", "/top/all", null, true);
                const getBloques = await apiRequest("get", `/bloques`, null, true);
                const getCaracteristicas = await apiRequest("get", "/caracteristicaTop", null, true);

                setTopsList(getTops);
                setBloques(getBloques);
                setCaracteristicas(getCaracteristicas);

            } catch (err) {
                return message.error("Error al obtener los datos");
            }

        })();
    }, []);

    const cambiarCaracteristicas = () => {

        if ( !bloqueSeleccionado ) return message.error( "BLOQUE NECESARIO!" );

        const match = {
            _id : {
                $in : selectedTops
            }
        }
        const set = {
            campanas: bloqueSeleccionado.campanas,
			campanas_tmp : [],
			caracteristicas: caracteristicas
                .filter( _car => bloqueSeleccionado.caracteristicas
                .includes( _car._id.toString() ) )
                .map( ( _car ) => { return { _id: _car._id, nombre: _car.nombre } } ),
			caracteristicas_tmp: [],
        }

		apiRequest("post",'/actualizarTops', { match, set } ,true).then( res => {
			if(res._error) return message.error(res._response.message );
			message.success( "TODO BIEN!" );
		});

    }

    const vaciarCaracteristicas = () => {

        const match = {
            _id : {
                $in : selectedTops
            }
        }
        const set = {
            campanas: [],
			campanas_tmp : [],
			caracteristicas: [],
			caracteristicas_tmp: [],
        }

		apiRequest("post",'/actualizarTops', { match, set } ,true).then( res => {
			if(res._error) return message.error(res._response.message );
			message.success( "TODO BIEN!" );
		});

    }

    return <div className='fdc'>

        { bloqueSeleccionado && <span> BLOQUE: { bloqueSeleccionado.nombre } </span> }

        <div>
            {
                bloques && bloques.map(( _bloque, indx ) => {
                    return <button 
                        key={indx}
                        className="m2 p2"
                        onClick={ () => setSelectedBloque( _bloque._id ) }
                    >
                        { _bloque.nombre }    
                    </button>
                })
            }
        </div>

        <FlatCard
            initialCollapsed
            collapsable
            title={<p className="fs5 bold colVerCor"> TOPS </p>}
        >
            <Transfer
                dataSource={topsList}
                valueKey="_id"
                render={item => item.nombre}
                rowKey={item => item._id}
                value={selectedTops}
                setValue={(transfered) => { setSelectedTops(transfered); }}
            />

            <button     
                className="m2 p2"
                onClick={ () => cambiarCaracteristicas() }
            >
                GUARDAR
            </button>

            <button     
                className="m2 p2"
                onClick={ () => vaciarCaracteristicas() }
            >
                VACIAR CARACTERISTICAS
            </button>

        </FlatCard>

    </div>

}
export default EditarTops;