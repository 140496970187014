import { mbxGlobal } from "MobX/root";
import apiRequest from "utils/apiRequest";

export default async function initTengoLeads(){
    const tengoLeads = await apiRequest('get','/tengoLeads',null ,true);

    mbxGlobal.hayLeadNormal = !!tengoLeads.idLeadNormal;
    mbxGlobal.hayLeadRecuperable = !!tengoLeads.idLeadRecuperable;

    return tengoLeads;
}