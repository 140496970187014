import { ConfigProvider, message, notification } from "antd";
import esEs from "antd/es/locale/es_ES";
import { configure } from "mobx";
import moment from "moment";
import "moment/locale/es";
import "rc-color-picker/assets/index.css";
import "react-json-inspector/json-inspector.css";
import React from "react";
import ReactDOM from "react-dom";
import "react-quill/dist/quill.snow.css";
import App from "./App";
import "./config";
import reportWebVitals from "./reportWebVitals";
import LogRocket from "logrocket";

moment.locale("es");

message.config({
  duration: 4,
  top: 40,
});

notification.config({
  placement: "bottomRight",
  duration: 4,
});

configure({
  enforceActions: "never",
});

if (process.env.NODE_ENV === "production") {
  LogRocket.init("nxc69d/gestor", {
    network: {
      requestSanitizer: (request) => {
        if (request.method === "POST") {
          try {
            const url = new URL(request.url).pathname;

            if (url === "/login") {
              request.body = request.body.replace(
                /("password":)(".*")/,
                '$1"__HIDDEN"'
              );
            }
          } catch (err) {
            console.log(err);
            return request;
          }
        }

        return request;
      },
    },
  });
}

ReactDOM.render(
  // <React.StrictMode>
  <ConfigProvider locale={esEs}>
    <App />
  </ConfigProvider>,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
