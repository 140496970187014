
import { makeAutoObservable } from "mobx";
import persist from "./persist";



export default class MbxGlobal {
	
	user = null; // {username, name, id...}
	socket = null;
	traducciones = [];
	globalLayout = {};
	hayLeadNormal = false;
	hayLeadRecuperable = false;
	
	
	constructor() {
		makeAutoObservable(this);
		persist(this, "global");
	};
	
};
