import React from "react";
import { Table } from "antd";
import { useState } from "react";
export default function TablaResultados({ data, preguntas = [] }) {
  const opciones = Array.isArray(preguntas.content) ? preguntas.content : [];
  const columns = [
    {
      title: "Opción seleccionada",
      dataIndex: "value_pregunta",
      key: "value_pregunta",
      render: (option) => {
        if (option && Number.isInteger(option) && opciones) {
          const optionText = opciones?.find(
            (pregunta) => pregunta.value === option
          );
          return <>{optionText?.text ?? "Otros"}</>;
        } else {
          return <>Mensaje</>;
        }
      },
    },
    {
      title: "Respuesta",
      dataIndex: "value",
      key: "value",
      render: (text) => {
        if (text && text.length > 0) {
          return <ExpandableText text={text} />;
        } else {
          return <></>;
        }
      },
      sorter: (a, b) => a.value - b.value,
    },
    {
      title: "Email Encuesta",
      dataIndex: "email_envio",
      key: "email_envio",
    },
  ];
  return (
    <>
      <h4>Esto es lo que dicen los clientes:</h4>
      <Table
        dataSource={data}
        columns={columns}
        pagination={{
          pageSizeOptions: ["5"],
          pageSize: 5,
          hideOnSinglePage: true,
        }}
      />
    </>
  );
}

const ExpandableText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      {expanded ? (
        <span>{text + " "}</span>
      ) : (
        <span>{text.length > 70 ? `${text.slice(0, 70)}... ` : text}</span>
      )}
      {text.length > 70 ? (
        <a onClick={() => setExpanded((expanded) => !expanded)}>
          Leer {expanded === true ? "Menos" : "Más"}
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};
