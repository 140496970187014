import React from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import getDateRanges from "utils/getDateRanges";
import { DatePicker, Button, Select, Tag } from "antd";
import moment from "moment";
import { mbxUi } from "MobX/root";
const { RangePicker } = DatePicker;

export default function FilterCreator({ filters, setFilters }) {
  const FilterList = () => {
    return (
      <>
        <b>Filtra las encuestas</b>
        <div className="flex-spaced">
          Fecha de respuesta:{" "}
          <RangePicker
            autoComplete="off"
            value={
              filters.fecha_inicio_respuesta && filters.fecha_fin_respuesta
                ? [
                    moment.unix(filters.fecha_inicio_respuesta),
                    moment.unix(filters.fecha_fin_respuesta),
                  ]
                : undefined
            }
            style={{ width: "60%" }}
            ranges={getDateRanges()}
            disabled={filters.fecha_inicio || filters.fecha_fin}
            onChange={(val) => {
              setFilters({
                ...filters,
                fecha_inicio_respuesta: val[0].startOf("day").unix(),
                fecha_fin_respuesta: val[1].endOf("day").unix(),
              });
              mbxUi.modal = null;
            }}
          />
        </div>
        <div className="flex-spaced">
          Fecha de creación:{" "}
          <RangePicker
            autoComplete="off"
            value={
              filters.fecha_inicio && filters.fecha_fin
                ? [
                    moment.unix(filters.fecha_inicio),
                    moment.unix(filters.fecha_fin),
                  ]
                : undefined
            }
            style={{ width: "60%" }}
            ranges={getDateRanges()}
            disabled={
              filters.fecha_inicio_respuesta || filters.fecha_fin_respuesta
            }
            onChange={(val) => {
              setFilters({
                ...filters,
                fecha_inicio: val[0].startOf("day").unix(),
                fecha_fin: val[1].endOf("day").unix(),
              });
              mbxUi.modal = null;
            }}
          />
        </div>
        <div className="flex-spaced">
          Filtra por tipo de suministro:
          <Select
            placeholder="Seleccione una opción"
            style={{ width: "50%" }}
            value={filters?.suministro ?? undefined}
            onChange={(value) => {
              setFilters({ ...filters, suministro: value });
              mbxUi.modal = null;
            }}
          >
            <Select.Option value="2">Ambos</Select.Option>
            <Select.Option value="1">Gas</Select.Option>
            <Select.Option value="0">Luz</Select.Option>
          </Select>
        </div>{" "}
        <div className="flex-spaced">
          Filtra por caducadas:
          <Select
            placeholder="Seleccione una opción"
            style={{ width: "50%" }}
            value={filters.caducadas}
            onChange={(value) => {
              setFilters({ ...filters, caducadas: value });
              mbxUi.modal = null;
            }}
          >
            <Select.Option value="1">Visibles</Select.Option>
            <Select.Option value="2">Ocultas</Select.Option>
          </Select>
        </div>{" "}
        <div className="flex-spaced">
          Filtra por turno:
          <Select
            placeholder="Seleccione una opción"
            style={{ width: "50%" }}
            value={filters.turno}
            onChange={(value) => {
              setFilters({ ...filters, turno: value });
              mbxUi.modal = null;
            }}
          >
            <Select.Option value="-1" selected>
              Todos
            </Select.Option>
            <Select.Option value="1">AM</Select.Option>
            <Select.Option value="2">PM</Select.Option>
          </Select>
        </div>{" "}
        <div className="flex-spaced">
          Filtra por media:
          <Select
            placeholder="Seleccione una opción"
            style={{ width: "50%" }}
            value={filters.media}
            onChange={(value) => {
              setFilters({ ...filters, media: value });
              mbxUi.modal = null;
            }}
          >
            <Select.Option value="-1">Todas</Select.Option>
            <Select.Option value="5">Bien</Select.Option>
            <Select.Option value="3">Normal</Select.Option>
            <Select.Option value="1">Mal</Select.Option>
          </Select>
        </div>{" "}
        <div className="flex-spaced">
          Filtra por característica:
          <Select
            placeholder="Seleccione una opción"
            style={{ width: "50%" }}
            value={filters.specialFilter}
            onChange={(value) => {
              setFilters({ ...filters, specialFilter: value });
              mbxUi.modal = null;
            }}
          >
            <Select.Option value="-1" selected>
              Todos
            </Select.Option>
            <Select.Option value="3">Ventas</Select.Option>
            <Select.Option value="4">Bajas</Select.Option>
            <Select.Option value="5">Atención al cliente</Select.Option>
            <Select.Option value="6">Mixto</Select.Option>
            <Select.Option value="7">Nuevos</Select.Option>
            <Select.Option value="8">Tickets</Select.Option>
            <Select.Option value="9">Catas</Select.Option>
          </Select>
        </div>{" "}
      </>
    );
  };
  const removeKey = (k = "", { [k]: _, ...o } = {}) => o;
  const removeKeys = (keys = [], o = {}) =>
    keys.reduce((r, k) => removeKey(k, r), o);

  const translations = {
    fecha_inicio_respuesta: "Fecha de respuesta",
    fecha_inicio: "Fecha de creación",
    suministro: "Tipo de suministro",
    caducadas: "Visibilidad caducadas",
    turno: "Turno",
    media: "Media",
    specialFilter: "Caracteristica",
    tops: "Top",
  };

  const translationOptions = {
    suministro: {
      0: "Luz",
      1: "Gas",
      2: "Ambos",
    },
    caducadas: {
      1: "Visibles",
      2: "Ocultas",
    },
    turno: {
      "-1": "Todos",
      1: "AM",
      2: "PM",
    },
    media: {
      "-1": "Todas",
      1: "Mal",
      3: "Normal",
      5: "Bien",
    },

    specialFilter: {
      "-1": "Todos",
      3: "Ventas",
      4: "Bajas",
      5: "Atención al cliente",
      6: "Mixto",
      7: "Nuevos",
      8: "Tickets",
      9: "Catas",
    },
    tops: "Operador",
  };

  return (
    <div className="flex-list">
      <Button
        type="primary"
        className="ant-dropdown-link "
        onClick={() =>
          (mbxUi.modal = {
            content: <FilterList filters={filters} setFilters={setFilters} />,
          })
        }
      >
        <PlusOutlined />
        Añadir filtro
      </Button>
      {Object.keys(filters).length > 0 && (
        <Button
          className="ant-dropdown-link"
          onClick={() => setFilters({ tops: filters?.tops ?? undefined })}
        >
          <CloseOutlined />
          Eliminar todo
        </Button>
      )}

      {Object.keys(filters)?.map((filter, index) => {
        switch (filter) {
          case "fecha_inicio_respuesta":
            return (
              <Tag
                key={index}
                closable
                color="blue"
                className="box-title "
                onClose={(e) => {
                  e.preventDefault();
                  setFilters(
                    removeKeys(
                      ["fecha_inicio_respuesta", "fecha_fin_respuesta"],
                      filters
                    )
                  );
                }}
              >
                <b>{translations[filter]}:</b>{" "}
                {new moment.unix(filters[filter]).toDate().toLocaleDateString()}{" "}
                -{" "}
                {new moment.unix(filters["fecha_fin_respuesta"])
                  .toDate()
                  .toLocaleDateString()}
              </Tag>
            );
          case "fecha_inicio":
            return (
              <Tag
                key={index}
                closable
                color="blue"
                className="box-title "
                onClose={(e) => {
                  e.preventDefault();
                  setFilters(
                    removeKeys(["fecha_inicio", "fecha_fin"], filters)
                  );
                }}
              >
                <b>{translations[filter]}:</b>{" "}
                {new moment.unix(filters[filter]).toDate().toLocaleDateString()}{" "}
                -{" "}
                {new moment.unix(filters["fecha_fin"])
                  .toDate()
                  .toLocaleDateString()}
              </Tag>
            );
          case "suministro":
            return (
              <Tag
                key={index}
                closable
                color="blue"
                className="box-title "
                onClose={(e) => {
                  e.preventDefault();
                  setFilters(removeKey("suministro", filters));
                }}
              >
                <b>{translations[filter]}:</b>{" "}
                {translationOptions[filter][filters[filter]]}
              </Tag>
            );
          case "caducadas":
            return (
              <Tag
                key={index}
                closable
                color="blue"
                className="box-title "
                onClose={(e) => {
                  e.preventDefault();
                  setFilters(removeKey("caducadas", filters));
                }}
              >
                <b>{translations[filter]}:</b>{" "}
                {translationOptions[filter][filters[filter]]}
              </Tag>
            );
          case "turno":
            return (
              <Tag
                key={index}
                closable
                color="blue"
                className="box-title "
                onClose={(e) => {
                  e.preventDefault();
                  setFilters(removeKey("turno", filters));
                }}
              >
                <b>{translations[filter]}:</b>{" "}
                {translationOptions[filter][filters[filter]]}
              </Tag>
            );
          case "media":
            return (
              <Tag
                key={index}
                closable
                color="blue"
                className="box-title "
                onClose={(e) => {
                  e.preventDefault();
                  setFilters(removeKey("media", filters));
                }}
              >
                <b>{translations[filter]}:</b>{" "}
                {translationOptions[filter][filters[filter]]}
              </Tag>
            );
          case "specialFilter":
            return (
              <Tag
                key={index}
                closable
                color="blue"
                className="box-title "
                onClose={(e) => {
                  e.preventDefault();
                  setFilters(removeKey("specialFilter", filters));
                }}
              >
                <b>{translations[filter]}:</b>{" "}
                {translationOptions[filter][filters[filter]]}
              </Tag>
            );
        }
      })}
    </div>
  );
}
