// initialState
export const initialState = {
    facturas: [],
    dnis: [],
    cups: [],
    tarifas: [],
    atrs: [],
    origenes: "Todos",
    fijo: "Ambos",
    luz: "Ambos",
    periodoFacturacion: '',
    periodoEmision: '',
    descuentos: "-1",
    excluyente: "0",
    cargando: false,
    resultado_general: {}
};

const SET_FACTURAS = "SET_FACTURAS";
const SET_DNIS = "SET_DNIS";
const SET_CUPS = "SET_CUPS";
const SET_TARIFAS = "SET_TARIFAS";
const SET_ATRS = "SET_ATRS";
const SET_ORIGENES = "SET_ORIGENES";
const SET_FIJO = "SET_FIJO";
const SET_LUZ = "SET_LUZ";
const SET_PERIODO_FACTURACION = "SET_PERIODO_FACTURACION";
const SET_PERIODO_EMISION = "SET_PERIODO_EMISION";
const SET_DESCUENTOS = "SET_DESCUENTOS";
const SET_EXCLUYENTE = "SET_EXCLUYENTE";
const SET_CARGANDO = "SET_CARGANDO";
const SET_RESULTADO_GENERAL = "SET_RESULTADO_GENERAL";
const CLEAN_STATE = "CLEAN_STATE";

function margenesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FACTURAS:
            return { ...state, facturas: action.payload };
        case SET_DNIS:
            return { ...state, dnis: action.payload };
        case SET_CUPS:
            return { ...state, cups: action.payload };
        case SET_TARIFAS:
            return { ...state, tarifas: action.payload };
        case SET_ATRS:
            return { ...state, atrs: action.payload };
        case SET_ORIGENES:
            return { ...state, origenes: action.payload };
        case SET_FIJO:
            return { ...state, fijo: action.payload };
        case SET_LUZ:
            return { ...state, luz: action.payload };
        case SET_PERIODO_FACTURACION:
            return { ...state, periodoFacturacion: action.payload };
        case SET_PERIODO_EMISION:
            return { ...state, periodoEmision: action.payload };
        case SET_DESCUENTOS:
            return { ...state, descuentos: action.payload };
        case SET_EXCLUYENTE:
            return { ...state, excluyente: action.payload };
        case SET_CARGANDO:
            return { ...state, cargando: action.payload };
        case SET_RESULTADO_GENERAL:
            const { total_facturas, total_facturas_luz, consumo_total, consumo_promedio, total_energia, margen_energia, porcentaje_margen_energia, precio_margen, margen_potencia, total_potencia } = action.payload
            return { ...state, resultado_general: { total_facturas, total_facturas_luz, consumo_total, consumo_promedio, total_energia, margen_energia, porcentaje_margen_energia, precio_margen, margen_potencia, total_potencia } }
        case CLEAN_STATE:
            return { ...initialState, resultado_general: state.resultado_general };
        default:
            return state;
    }
}

export default margenesReducer;
