import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import apiRequest from "utils/apiRequest";
import { Button, Col, DatePicker, Divider, Row, Select, Statistic } from "antd";
import ButtonGroup from "atomicComponents/ButtonGroup";
// import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import Card from "components/Card/Card";

import dayjs from "dayjs";

import getDateRanges from "utils/getDateRanges";

import { SearchOutlined } from "@ant-design/icons";
import BuscadorGenerico from "components/BuscadorGenerico";
import { Line } from "@ant-design/charts";

const { RangePicker } = DatePicker;

const KpiRepsolBajasAg = () => {

    const gridRefRegistros = useRef();
    const gridRefSumatorios = useRef()

    const [rangoFecha, setRangoFecha] = useState([
        dayjs().subtract(7, "day").startOf("day"),
        dayjs().endOf("day")
    ]);

    const [rangoFechaPerformance, setRangoFechaPerformance] = useState([
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").startOf("day")
    ]);

    const [kpis, setKpis] = useState("estadobbdd");
    const [rowData, setRowData] = useState();
    const [rowDataTotal, setRowDataTotal] = useState();

    // El periodo para las kpis de ventas es desde el dia anterior
    const [rangoFechaVentas, setRangoFechaVentas] = useState([
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").startOf("day")
    ]);

    const [cerrados, setCerrados] = useState({
        total: 0,
        burn_rate: 0,
        caducados: 0,
        burn_rate_caducados: 0,
        burn_rate_caducados_agentes:0,
        no_caducados: 0,
        burn_rate_no_caducados: 0,
        burn_rate_no_caducados_agentes : 0,
    });

    // Establecemos las horas trabajadas por defecto a 12
    // 2 turnos de 6 horas
    const [horasTrabajadas, setHorasTrabajadas] = useState(12);

    const [ventasKpi, setVentasKpi] = useState({
        total: 0,
        ventas_por_hora: 0,
        hit_rate_cerrados: 0,
        hit_rate_caducados: 0,
        hit_rate_no_caducados: 0,
    });
    const [mostrarPeriodo, setMostrarPeriodo] = useState(false);

    const [performance, setPerformance] = useState({
        ventas: 0,
        argumentados: 0,
        cerrados: 0,
        hit_rate_cerrados: 0,
        hit_rate_argumentados: 0,
    });

    const [ordenacion, setOrdenacion] = useState("registros")
    const [agrupacion, setAgrupacion] = useState("singrupo")

    const [agentesAgrupacion, setAgentesAgrupacion] = useState("ambos");

    const [acumuladoOption, setAcumuladoOption] = useState("mensual");
    const [acumuladoVistaOption, setacumuladoVistaOption] = useState("agente");

    const [valorFiltro, setValorFiltro] = useState([]);
    const [triggerFiltro, setTriggerFiltro] = useState(false);

    // const [totales, setTotales] = useState([]);
    
    // const [accionAgente, setAccionAgente] = useState("total");

    const [columnDefs, setColumnDefs] = useState([
        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true,hide: true },
        { field: "descripcion",headerName: "Estado", filter: true },
        { field: "HOY", headerName: "Hoy", filter: false, aggFunc: "sum" },
        { field: "WTD", headerName: "WTD", filter: false, aggFunc: "sum" },
        { field: "MTD", headerName: "MTD", filter: false, aggFunc: "sum" },
        { field: "YTD", headerName: "YTD", filter: false, aggFunc: "sum" },
    ]);

    const [columnDefsTotal, setColumnDefsTotal] = useState([
        {field: '_id.dia_calculo_bi', headerName:"dia", filter: true, rowGroup: true, enableRowGroup: true, enablePivot: true, hide: true},
        {field: '_id.tipo', headerName:"tipo", filter: true,rowGroup: true,enableRowGroup: true, hide: true},
        {field: '_id.descripcion', headerName:"estado", filter: true, rowGroup: true,enableRowGroup: true, hide: true},
        {field: '_id.tipo_campana', headerName:"tipo campaña", filter: true,rowGroup: true,enableRowGroup: true, hide: true},
        {field: '_id.campana',headerName:"campaña", filter: true, rowGroup: true, enableRowGroup: true, hide: true},
        {field: 'total', headerName: "Total", aggFunc: 'sum'}
    ]);

    const [datosGrafico, setDatosGrafico] = useState([]);

    const [mostrarEvolucion, setMostrarEvolucion] = useState(false);


    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }));

    // // Example of consuming Grid Event
    // const cellClickedListener = useCallback((event) => {
    //     let descripcion = (event.data) ? event.data.descripcion.toLowerCase() : null;
        
    //     // console.log("cellClicked",event, event.node.key, event.column.colId, descripcion);
    //     if(descripcion) {
    //         console.log('descripcion :>> ', descripcion);
    //     } else {
    //         console.log('no hay dato :>> ');
    //     }
        
    // }, []);

    const commonOptions = {
        // adds subtotals
        groupIncludeFooter: true,
        // includes grand total
        // groupIncludeTotalFooter: true,
        // overlayLoadingTemplate:
        //     '<span class="ag-overlay-loading-center">Cargando datos...</span>',
        // overlayNoRowsTemplate:
        //     '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No hay datos disponibles</span>',
        autoGroupColumnDef : {
            // headerName : "Grupo",
            cellRendererParams : {
                suppressCount : true
            }
        },
        resizable : true
    }        

    const gridOptions = {
        ...commonOptions
        // // adds subtotals
        // groupIncludeFooter: true,
        // // includes grand total
        // // groupIncludeTotalFooter: true,
        // overlayLoadingTemplate:
        //     '<span class="ag-overlay-loading-center">Cargando datos...</span>',
        // overlayNoRowsTemplate:
        //     '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No hay datos disponibles</span>',

        // // other grid options ...
    };

    const gridOptionsTotal = {
        ...commonOptions,
        // // adds subtotals
        // groupIncludeFooter: true,
        // // includes grand total
        // // groupIncludeTotalFooter: true,

        // // other grid options ...

        // groupMultiAutoColumn : true,
        

    };

    // Configuracion de la gráfica
    const config = {
        data: datosGrafico,
        padding: 'auto',
        xField: 'fecha',
        yField: 'total',
        seriesField: 'tipo',
        xAxis: {
          // type: 'timeCat',
          tickCount: 5,
        },
        label: {},
        point: {
            size: 5,
            shape: 'diamond',
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 2,
            },
        },
        tooltip: {
            showMarkers: false,
        },
        // state: {
        //     active: {
        //         style: {
        //         shadowBlur: 4,
        //         stroke: '#000',
        //         fill: 'red',
        //         },
        //     },
        // },
        interactions: [
            {
                type: 'marker-active',
            },
        ],
        legend: {
            position: 'top',
        },
    };


    const agrupacionVentasHandler = () => {
        if(agrupacion === "porcampanas" ){
            switch ( agentesAgrupacion ){
                case "numeros":
                    setColumnDefs([
                        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide:true },
                        { field: "descripcion",headerName: "Estado", filter: true,rowGroup: true, hide:true },
                        { field: "campana",headerName: "Campaña", filter: true, rowGroup: true, hide:true },
                        // { field: "tipo_campana",headerName: "Tipo Campaña", filter: true, rowGroup: true, hide:true },
                        { field: "HOY", headerName: "Ayer", filter: false, aggFunc: "sum"  },
                        
                        { field: "WTD", headerName: "WTD", filter: false, aggFunc: "sum" },
                        
                        { field: "MTD", headerName: "MTD", filter: false, aggFunc: "sum" },
                        
                        { field: "YTD", headerName: "YTD", filter: false, aggFunc: "sum" },
                        
                    ]);
                    break;
                case "porcentaje":
                    setColumnDefs([
                        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide:true },
                        { field: "descripcion",headerName: "Estado", filter: true,rowGroup: true, hide:true },
                        { field: "campana",headerName: "Campaña", filter: true, rowGroup: true, hide:true },
                        // { field: "tipo_campana",headerName: "Tipo Campaña", filter: true, rowGroup: true, hide:true },
                        
                        { field: "HOY_RATE", headerName: "% Ayer", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        
                        { field: "WTD_RATE", headerName: "% WTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        
                        { field: "MTD_RATE", headerName: "% MTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                        
                        { field: "YTD_RATE", headerName: "% YTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                    ]);

                    break;
                default:

                    setColumnDefs([
                        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide:true },
                        { field: "descripcion",headerName: "Estado", filter: true,rowGroup: true, hide:true },
                        { field: "campana",headerName: "Campaña", filter: true, rowGroup: true, hide:true },
                        // { field: "tipo_campana",headerName: "Tipo Campaña", filter: true, rowGroup: true, hide:true },
                        { field: "HOY", headerName: "Ayer", filter: false, aggFunc: "sum"  },
                        { field: "HOY_RATE", headerName: "% Ayer", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        { field: "WTD", headerName: "WTD", filter: false, aggFunc: "sum" },
                        { field: "WTD_RATE", headerName: "% WTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        { field: "MTD", headerName: "MTD", filter: false, aggFunc: "sum" },
                        { field: "MTD_RATE", headerName: "% MTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                        { field: "YTD", headerName: "YTD", filter: false, aggFunc: "sum" },
                        { field: "YTD_RATE", headerName: "% YTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                    ]);

                    break;
            }
           
        } else if (agrupacion === "portipocampana") {
            
            switch( agentesAgrupacion ){
                case "numeros":
                    setColumnDefs([
                        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide:true },
                        { field: "descripcion",headerName: "Estado", filter: true,rowGroup: true, hide:true },
                        { field: "tipo_campana",headerName: "Tipo Campaña", filter: true, rowGroup: true, hide:true },
                        { field: "campana",headerName: "Campaña", filter: true, rowGroup: true, hide:true },
                        { field: "HOY", headerName: "Ayer", filter: false, aggFunc: "sum"  },
                        
                        { field: "WTD", headerName: "WTD", filter: false, aggFunc: "sum" },
                        
                        { field: "MTD", headerName: "MTD", filter: false, aggFunc: "sum" },
                        
                        { field: "YTD", headerName: "YTD", filter: false, aggFunc: "sum" },
                        
                    ]);

                    break;

                case "porcentaje":
                    setColumnDefs([
                        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide:true },
                        { field: "descripcion",headerName: "Estado", filter: true,rowGroup: true, hide:true },
                        { field: "tipo_campana",headerName: "Tipo Campaña", filter: true, rowGroup: true, hide:true },
                        { field: "campana",headerName: "Campaña", filter: true, rowGroup: true, hide:true },
                        
                        { field: "HOY_RATE", headerName: "% Ayer", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        
                        { field: "WTD_RATE", headerName: "% WTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        
                        { field: "MTD_RATE", headerName: "% MTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                        
                        { field: "YTD_RATE", headerName: "% YTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                    ]);
                    break;
                    
                default:
                    setColumnDefs([
                        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide:true },
                        { field: "descripcion",headerName: "Estado", filter: true,rowGroup: true, hide:true },
                        { field: "tipo_campana",headerName: "Tipo Campaña", filter: true, rowGroup: true, hide:true },
                        { field: "campana",headerName: "Campaña", filter: true, rowGroup: true, hide:true },
                        { field: "HOY", headerName: "Ayer", filter: false, aggFunc: "sum"  },
                        { field: "HOY_RATE", headerName: "% Ayer", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        { field: "WTD", headerName: "WTD", filter: false, aggFunc: "sum" },
                        { field: "WTD_RATE", headerName: "% WTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        { field: "MTD", headerName: "MTD", filter: false, aggFunc: "sum" },
                        { field: "MTD_RATE", headerName: "% MTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                        { field: "YTD", headerName: "YTD", filter: false, aggFunc: "sum" },
                        { field: "YTD_RATE", headerName: "% YTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                    ]);
                    break;
            }



        } else {

            switch (agentesAgrupacion) {
                case "porcentaje":
                    
                    setColumnDefs([
                        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide:true },
                        { field: "descripcion",headerName: "Estado", filter: true,rowGroup: true, hide:true },
                        
                        { field: "HOY_RATE", headerName: "% Ayer", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        
                        { field: "WTD_RATE", headerName: "% WTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        
                        { field: "MTD_RATE", headerName: "% MTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                        
                        { field: "YTD_RATE", headerName: "% YTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                    ]);
                    break;
            
                case "numeros":
                    setColumnDefs([
                        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide:true },
                        { field: "descripcion",headerName: "Estado", filter: true,rowGroup: true, hide:true },
                        { field: "HOY", headerName: "Ayer", filter: false, aggFunc: "sum"  },
                        
                        { field: "WTD", headerName: "WTD", filter: false, aggFunc: "sum" },
                       
                        { field: "MTD", headerName: "MTD", filter: false, aggFunc: "sum" },
                       
                        { field: "YTD", headerName: "YTD", filter: false, aggFunc: "sum" },
                        
                    ]);
                    break;

                default:
                    setColumnDefs([
                        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide:true },
                        { field: "descripcion",headerName: "Estado", filter: true,rowGroup: true, hide:true },
                        { field: "HOY", headerName: "Ayer", filter: false, aggFunc: "sum"  },
                        { field: "HOY_RATE", headerName: "% Ayer", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        { field: "WTD", headerName: "WTD", filter: false, aggFunc: "sum" },
                        { field: "WTD_RATE", headerName: "% WTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }  
                        },
                        { field: "MTD", headerName: "MTD", filter: false, aggFunc: "sum" },
                        { field: "MTD_RATE", headerName: "% MTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                        { field: "YTD", headerName: "YTD", filter: false, aggFunc: "sum" },
                        { field: "YTD_RATE", headerName: "% YTD", filter: false, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            } 
                        },
                    ]);
                    break;
            }

            
        }
    };
    

    const cambioKpi = async (kpi, orden = null) => {
        
        if(gridRefSumatorios.current.api) {
            gridRefSumatorios.current.api.showLoadingOverlay();
        }
        
        if(gridRefRegistros.current && gridRefRegistros.current.api){
            gridRefRegistros.current.api.showLoadingOverlay();
        }
        
        ////////////////////////////////////////////////////////////////
        // Limpiamos los datos y las columnas de los grids
        setRowData([]);
        setRowDataTotal([]);
        setColumnDefsTotal([]);
        setColumnDefs([]);
        ////////////////////////////////////////////////////////////////
        let periodo = null;
        let periodoVentas = null;

        if(rangoFecha) {
            periodo =
                rangoFecha[0].format("YYYY-MM-DD") +
                "," +
                rangoFecha[1].format("YYYY-MM-DD");
        }

        if(rangoFechaVentas) {
            periodoVentas =
                rangoFechaVentas[0].format("YYYY-MM-DD") +
                "," +
                rangoFechaVentas[1].format("YYYY-MM-DD");
        }

        let periodoPerformance = null;
        if( rangoFechaPerformance ){
            periodoPerformance = 
                rangoFechaPerformance[0].format("YYYY-MM-DD") +
                ","+
                rangoFechaPerformance[1].format("YYYY-MM-DD");
        }

        if( kpi != "acumulado" && gridRefSumatorios.current.columnApi){
            gridRefSumatorios.current.columnApi.setPivotMode(false);
            gridRefSumatorios.current.api.setSideBar(null);
            gridRefSumatorios.current.api.setSideBarVisible(false);
        }

        let urlToSend = ""

        ////////////////////////////////////////////////////////////////
        // En funcion del kpi solicitado se cargan los datos
        // y las columnas correspondientes
        switch (kpi) {

            case "estadobbdd":

                urlToSend = "/getestadobajasBBDD";

                if( valorFiltro && valorFiltro.length > 0) urlToSend+=`?filtro=${valorFiltro}`

                let estadoBBDD = await fetchData(urlToSend);
                
                if(estadoBBDD) {
                    setColumnDefs([
                        { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide: true },
                        { field: "descripcion",headerName: "Estado", filter: true },
                        { field: "AYER", headerName: "Ayer", filter: false, aggFunc: "sum" },
                        { field: "HOY", headerName: "Hoy", filter: false, aggFunc: "sum" },
                        { field: "mediaWTD", headerName: "Media WTD", filter: false, aggFunc: "sum",
                            valueFormatter: params => {
                                return params.value ? params.value.toFixed(2) : '';
                            },
                        },
                        { field: "WTD", headerName: "WTD", filter: false, aggFunc: "sum" },
                        { field: "mediaMTD", headerName: "Media MTD", filter: false, aggFunc: "sum",
                            valueFormatter: params => {
                                return params.value ? params.value.toFixed(2) : '';
                            },
                        },
                        { field: "MTD", headerName: "MTD", filter: false, aggFunc: "sum" },
                        { field: "mediaYTD", headerName: "Media YTD", filter: false, aggFunc: "sum",
                            valueFormatter: params => {
                                return params.value ? params.value.toFixed(2) : '';
                            },
                        },
                        { field: "YTD", headerName: "YTD", filter: false, aggFunc: "sum" },
                    ]);
                }
                
                if (periodo) urlToSend = `/getestadoparamsbajasBBDD?periodo=${periodo}`
                else urlToSend = `/getestadoparamsbajasBBDD`
                
                let estadoParams = await fetchData(urlToSend);
                if(estadoParams) {
                    setColumnDefsTotal([
                        {field: '_id.dia_calculo_bi', headerName:"dia", filter: true, rowGroup: true, enableRowGroup: true, enablePivot: true, hide: true, resizable:true},
                        {field: '_id.tipo', headerName:"tipo", filter: true,rowGroup: true,enableRowGroup: true, hide: true},
                        {field: '_id.descripcion', headerName:"estado", filter: true, rowGroup: true,enableRowGroup: true, hide: true},
                        {field: '_id.tipo_campana', headerName:"tipo campaña", filter: true,rowGroup: true,enableRowGroup: true, hide: true},
                        {field: '_id.campana',headerName:"campaña", filter: true, rowGroup: true, enableRowGroup: true, hide: true},
                        {field: 'total', headerName: "Total", aggFunc: 'sum'}
                    ])
                }
                
                break;

            case "retencion":

                // VENTAS BBDD
                
                
                // if ( !orden ) {
                //     resultadoVentasRegistros = await fetchData(`/getventasparamsBBDD`);
                // } else {
                //     console.log('lanzamos :>> ');
                //     resultadoVentasRegistros = await fetchData(`/getventasparamsBBDD?tipo=${orden}`);
                //     console.log('terminamos :>> ');
                // }

                // Para ventas y en funcion de las agrupacion seleccionada
                // vamos a lanzar una consulta u otra
                let resultadoVentasBBDD = null;
                
                switch (agrupacion) {
                    case "porcampanas":
                    case "portipocampana":
                        resultadoVentasBBDD = await fetchData(`/getretencionBBDDCampanas`);
                        break;
                
                    default:
                        urlToSend = "/getretencionBBDD";
                        if ( valorFiltro && valorFiltro.length > 0 ) urlToSend+=`?filtro=${valorFiltro}`

                        resultadoVentasBBDD = await fetchData(urlToSend);
                        break;
                }

                if ( resultadoVentasBBDD ) {
                    agrupacionVentasHandler();
                }
                ////////////////////////////////////////////////////////////////////////////////////////////////
                // Creamos la estructura de columnas en funcion de la ordenacion
                if (periodo) urlToSend = `/getretencionparamsBBDD?tipo=dias&periodo=${periodo}`
                else urlToSend = `/getretencionparamsBBDD?tipo=dias`
                
                if(periodoVentas) await estadisticasBajas(periodoVentas)
                else await estadisticasBajas() 


                if (orden) urlToSend += `&orden=${orden}`
                

                console.log('urtToSend :>> ', urlToSend, orden, periodo);

                let resultadoVentasRegistros = await fetchData(urlToSend);
                if(resultadoVentasRegistros) {

                    let arrayColums = [
                        {field: '_id.dia_calculo_bi', headerName:"dia", filter: true, rowGroup: true, enableRowGroup: false, enablePivot: true, hide: true, resizable: true },
                        {field: '_id.tipo', headerName:"tipo", filter: true, rowGroup: true,enableRowGroup: false, hide: true},
                        {field: '_id.descripcion', headerName:"estado", filter: true, rowGroup: true,enableRowGroup: false, hide: true},
                        {field: '_id.tipo_campana', headerName:"tipo campaña", filter: true,rowGroup: true,enableRowGroup: false, hide: true},
                        {field: '_id.campana',headerName:"campaña", filter: true, rowGroup: true, enableRowGroup: false, hide: true},
                        {field: '_id.num_intentos', headerName:"intentos operador", filter: true, rowGroup: true, enableRowGroup: false, sortable: true, hide: false},
                        {field: '_id.num_dias', headerName:"dias hasta venta", filter: true, rowGroup: true, enableRowGroup: false, sortable: true, hide: false},
                        {field: 'total', headerName: "Total", aggFunc: 'sum'},
                        {field: 'hitRate', headerName: "% Hit Rate", 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }
                        }
                    ]

                    if ( orden && orden === "intentos") {
                        arrayColums = [
                            {field: '_id.num_intentos', headerName:"intentos operador", filter: true, rowGroup: true, enableRowGroup: true, sortable: true , resizable:true, hide:true,
                                comparator: function(valueA, valueB, nodeA, nodeB, isDescending) {
                                    // Hay que tener en cuenta los valores vacios
                                    if(isNaN(parseInt(valueA)) && isNaN(parseInt(valueB)) ) {
                                        return 0
                                    } else {
                                        if(isNaN(parseInt(valueA))) {
                                            return -1
                                        } else if(isNaN(parseInt(valueB))) {
                                            return 1
                                        } else {
                                            return (valueA == valueB) ? 0 : (parseInt(valueA) < parseInt(valueB)) ? 1 : -1;
                                        }
                                    }
                                }
                            },
                            // {field: '_id.dia_calculo_bi', headerName:"dia", filter: true, rowGroup: true, enableRowGroup: true, enablePivot: true, sortable: true, hide: true },
                            {field: '_id.tipo', headerName:"tipo", filter: true, rowGroup: true,enableRowGroup: true, hide: true},
                            {field: '_id.descripcion', headerName:"estado", filter: true, rowGroup: true,enableRowGroup: true, hide: true},
                            {field: '_id.tipo_campana', headerName:"tipo campaña", filter: true,rowGroup: true,enableRowGroup: true, hide: true,  enablePivot: true},
                            {field: '_id.campana',headerName:"campaña", filter: true, rowGroup: true, enableRowGroup: true, hide: true},
                            // {field: '_id.num_dias', headerName:"dias hasta venta", filter: true, rowGroup: true, enableRowGroup: true, sortable: true, hide: false},
                            // {field: '_id.num_intentos', headerName:"intentos operador", filter: true, rowGroup: true, enableRowGroup: true, sortable: true, hide: false,
                            //     // aggFunc: params => {
                            //     //     let total = 0
                            //     //     params.values.forEach(value => total += parseFloat(value));
                            //     //     return total.toFixed(2)
                            //     // }
                            // },
                            // {field: '_id.num_dias', headerName:"dias hasta venta", filter: true, rowGroup: true, enableRowGroup: true, sortable: true, hide: false,
                            //     // aggFunc: params => {
                            //     //     let total = 0
                            //     //     params.values.forEach(value => (value) ? total += parseFloat(value) : total +=0);
                            //     //     return total.toFixed(2)
                            //     // }
                            // },
                            {field: 'total', headerName: "Total", aggFunc: 'sum', sortable: true},
                            {field: '_id.hitRate', headerName: "% Hit Rate", sortable: true, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                // console.log('params.values :>> ', params.values);
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(2)
                            }},
                        ]
                        // Refrescamos las filas
                        
                    }

                    if ( orden && orden === "dias") {
                        arrayColums = [
                            {field: '_id.num_dias', headerName:"dias hasta venta", filter: true, rowGroup: true, enableRowGroup: true, sortable: true, hide:true, resizable:true,
                                comparator: function(valueA, valueB, nodeA, nodeB, isDescending) {
                                    // Hay que tener en cuenta los valores vacios
                                    if(isNaN(parseInt(valueA)) && isNaN(parseInt(valueB)) ) {
                                        return 0
                                    } else {
                                        if(isNaN(parseInt(valueA))) {
                                            return -1
                                        } else if(isNaN(parseInt(valueB))) {
                                            return 1
                                        } else {
                                            return (valueA == valueB) ? 0 : (parseInt(valueA) < parseInt(valueB)) ? 1 : -1;
                                        }
                                    }
                                }
                            },
                            // {field: '_id.dia_calculo_bi', headerName:"dia", filter: true, rowGroup: true, enableRowGroup: true, enablePivot: true, sortable: true, hide: true },
                            {field: '_id.tipo', headerName:"tipo", filter: true, rowGroup: true,enableRowGroup: true, hide: true},
                            {field: '_id.descripcion', headerName:"estado", filter: true, rowGroup: true,enableRowGroup: true, hide: true},
                            {field: '_id.tipo_campana', headerName:"tipo campaña", filter: true,rowGroup: true,enableRowGroup: true, hide: true},
                            {field: '_id.campana',headerName:"campaña", filter: true, rowGroup: true, enableRowGroup: true, hide: true},
                            // {field: '_id.num_intentos', headerName:"intentos operador", filter: true, rowGroup: true, enableRowGroup: true, sortable: true, hide: false},
                            {field: 'total', headerName: "Total", aggFunc: 'sum', sortable: true},
                            {field: '_id.hitRate', headerName: "% Hit Rate", sortable: true, 
                            valueFormatter: params => {
                                return params.value ? params.value  + ' %' : '0.00 %';
                            },
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += parseFloat(value));
                                return total.toFixed(3)
                            }},
                        ]
                    }
                                
                    setColumnDefsTotal(arrayColums)
                    console.log('refrescamos... :>> ');
                    gridRefRegistros.current && gridRefRegistros.current.api.redrawRows();
                    var params = {
                        force: true,
                        suppressFlash: true,
                    };
                    gridRefRegistros.current && gridRefRegistros.current.api.refreshCells(params);
                    gridRefRegistros.current && gridRefRegistros.current.api.sizeColumnsToFit();

                }
                
                break;
                
            case "performance":
                
                urlToSend = `/getPerformanceAgentes?periodo=${periodoPerformance}`

                if( valorFiltro && valorFiltro.length > 0 ) urlToSend += `&filtro=${valorFiltro}`

                const response = await fetchData(urlToSend);
                setColumnDefs([
                    {field:"nombre",headerName:"Nombre Agente",filter:"agTextColumnFilter"},
                    {field:"ventas",headerName:"Ventas Totales",filter:true},
                    {field:"total",headerName:"Cerrados por el Agente",filter:true},
                    // {field:"totalDia",headerName:"Total cerrados",filter:true},
                    {field:"argumentado",headerName:"Argumentado",filter:true},
                    {field:"argumentadoPorcentaje",headerName:"Porcentaje Argumentado",filter:true,
                        valueFormatter: params => {
                            return ( params.value  && !isNaN(params.value) ) ? params.value  + ' %' : '0.00 %';
                        },
                    },
                    {field:"HRCerrados",headerName:"HR Cerrados",filter:true,
                        valueFormatter: params => {
                            return (params.value && !isNaN(params.value) ) ? params.value  + ' %' : '0.00 %';
                        },
                    },
                    {field:"HRArgumentado",headerName:"HR Argumentado",filter:true,
                        valueFormatter: params => {
                            return (params.value && !isNaN(params.value) ) ? params.value  + ' %' : '0.00 %';
                        },
                    },
                ])

                break;
            
            case "contactabilidad":

                urlToSend = `/getcontactabilidadBajasBBDD`;
                if( valorFiltro && valorFiltro.length > 0 ) urlToSend += `?filtro=${valorFiltro}`;

                let contactabilidadBBDD = await fetchData(urlToSend);
                if(contactabilidadBBDD) {
                    setColumnDefs([
                        // { field: "tipo", headerName: "Tipo", filter: true, rowGroup: true, hide: true },
                        { field: "descripcion",headerName: "Contactabilidad", filter: true },
                        { field: "HOY", headerName: "Ayer", filter: false, aggFunc: "sum" },
                        { field: "WTD", headerName: "WTD", filter: false, aggFunc: "sum" },
                        { field: "MTD", headerName: "MTD", filter: false, aggFunc: "sum" },
                        { field: "YTD", headerName: "YTD", filter: false, aggFunc: "sum" },
                    ]);
                }

                if (periodo) urlToSend = `/getcontactabilidadparamsBajasBBDD?periodo=${periodo}`
                else urlToSend = `/getcontactabilidadparamsBajasBBDD`

                let contactabilidadParams = await fetchData(urlToSend);
                if(contactabilidadParams) {
                    setColumnDefsTotal([
                        {field: '_id.dia_calculo_bi', headerName:"dia", filter: true, rowGroup: true, enableRowGroup: true, enablePivot: true, hide: true, resizable:true},
                        {field: '_id.contactabilidad', headerName:"contactabilidad", filter: true,rowGroup: true, enableRowGroup: true, hide: true},
                        // {field: '_id.tipo', headerName:"tipo", filter: true,rowGroup: true,enableRowGroup: true, hide: true},
                        // {field: '_id.descripcion', headerName:"estado", filter: true, rowGroup: true,enableRowGroup: true, hide: true},
                        {field: '_id.tipo_campana', headerName:"tipo campaña", filter: true,rowGroup: true,enableRowGroup: true, hide: true},
                        {field: '_id.campana',headerName:"campaña", filter: true, rowGroup: true, enableRowGroup: true, hide: true},
                        {field: 'total', headerName: "Total", aggFunc: 'sum'},
                        {field: 'porcentaje', headerName: "Porcentaje", 
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            } ,
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += value );
                                return total
                            },
                        }
                    ])
                }
                
                break;
            
            case "agentes":
                
                urlToSend = "/getagentesBajasBBDD";

                if( valorFiltro && valorFiltro.length > 0 ) urlToSend+=`?filtro=${valorFiltro}`;

                let agentesBBDD = await fetchData(urlToSend);
                if(agentesBBDD) {  
                    setColumnDefs([
                        { field: "descripcion",headerName: "Agente", filter: true, resizable:true },
                        { field: "HOY", headerName: "Cerrados Ayer", filter: false, resizable:true },
                        { field: "HOY_VENTAS", headerName: "Ventas ayer", filter: false, resizable:true },
                        { field: "HOY_HR", headerName: "Hit Rate Ayer", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            } 
                        },
                        { field: "HOY_ARGUMENTADO", headerName: "Argumentado ayer", filter: false, resizable:true },
                        { field: "WTD", headerName: "WTD", filter: false, resizable:true },
                        { field: "WTD_VENTAS", headerName: "Ventas WTD", filter: false, resizable:true },
                        { field: "WTD_HR", headerName: "Hit Rate WTD", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            }
                        },
                        { field: "WTD_ARGUMENTADO", headerName: "Argumentado WTD", filter: false,resizable:true },
                        { field: "MTD", headerName: "MTD", filter: false, resizable:true },
                        { field: "MTD_VENTAS", headerName: "Ventas MTD", filter: false, resizable:true },
                        { field: "MTD_HR", headerName: "Hit Rate MTD", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            }
                        },
                        { field: "MTD_ARGUMENTADO", headerName: "Argumentado MTD", filter: false, resizable:true },
                        { field: "YTD", headerName: "YTD", filter: false, resizable:true },
                        { field: "YTD_VENTAS", headerName: "Ventas YTD", filter: false, resizable:true },
                        { field: "YTD_HR", headerName: "Hit Rate YTD", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            }     
                        },
                        { field: "YTD_ARGUMENTADO", headerName: "Argumentado YTD", filter: false, resizable:true },
                    ]);
                }

                if (periodo) urlToSend = `/getagentesparamsbajasBBDD?periodo=${periodo}`
                else urlToSend = `/getagentesparamsbajasBBDD`

                let agentesParams = await fetchData(urlToSend);
                if(agentesParams) {
                    setColumnDefsTotal([
                        {field: '_id.dia_calculo_bi', headerName:"dia", filter: true, rowGroup: true, enableRowGroup: true, enablePivot: true, hide: true, resizable:true },
                        {field: '_id.descripcion', headerName:"agente", filter: true,rowGroup: true, enableRowGroup: true, hide: true, resizable:true },
                        {field: '_id.tipo_campana', headerName:"tipo campaña", filter: true,rowGroup: true,enableRowGroup: true, hide: true, resizable:true },
                        {field: '_id.campana',headerName:"campaña", filter: true, rowGroup: true, enableRowGroup: true, hide: true, resizable:true },
                        {field: '_id.venta', headerName:"venta", filter: true,rowGroup: false, enableRowGroup: true, hide: false},
                        {field: '_id.contactabilidad', headerName:"contactabilidad", filter: true, rowGroup: false, enableRowGroup: true, hide: false},
                        {field: 'hit_rate', headerName: "Hit Rate Cerrados",  aggFunc: 'sum',
                            valueFormatter: params => {
                                if(params.value)

                                    return params.value.toFixed(2) + ' %'
                            }
                        },
                        {field: 'total', headerName: "Cerrados", aggFunc: 'sum'},
                        {field: 'porcentaje', headerName: "Porcentaje Cerrados",
                            valueFormatter: params => {
                                if(params.value)

                                    return params.value.toFixed(2) + ' %'
                            } ,
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += value );
                                return total
                            },
                        },
                        {field: 'ventas', headerName: "Ventas", aggFunc: 'sum'},
                        {field: 'porcentajeventas', headerName: "Porcentaje Ventas",
                            valueFormatter: params => {
                                if(params.value)

                                    return params.value.toFixed(2) + ' %'
                            } ,
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += value );
                                return total
                            },
                        },
                        {field: 'argumentado', headerName: "Argumentado", aggFunc: 'sum'},
                        {field: 'porcentajeargumentados', headerName: "Porcentaje Argumentado",
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2)  + ' %'
                            } ,
                            aggFunc: params => {
                                let total = 0
                                params.values.forEach(value => total += value );
                                return total
                            },
                        },
                    ])
                }

                break;
            
            case "acumulado":

                urlToSend = `/getAgentesAcumulado?tipo=${acumuladoOption}&tipoKpi=bajas`;

                if( valorFiltro && valorFiltro.length > 0 ) urlToSend+=`&filtro=${valorFiltro}`;

                let agentesAcumulado= await fetchData(urlToSend);

                if( agentesAcumulado && acumuladoVistaOption === "agente"  ){

                    gridRefSumatorios.current.columnApi.setPivotMode(false);
                    gridRefSumatorios.current.api.setSideBar(null);
                    gridRefSumatorios.current.api.setSideBarVisible(false);

                    setColumnDefs([
                        { field:"nombre",headerName:"TOP",rowGroup:true,hide:true,filter:true},
                        { field:"periodoNombre",headerName:"Periodo"},
                        { field:"totalVentas",headerName:"Total Ventas"},
                        { field:"argumentado",headerName:"Argumentado"},
                        { field:"porcentajeArgumentado",headerName:"% Argumentado",
                           valueFormatter: params => {
                               return params.value ? params.value  + ' %' : '';
                           },
                       },
                       { field:"cerrados",headerName:"Cerrados"},
                       { field:"cta",headerName:"Cta"}
                   ]) 
                }
                if( agentesAcumulado && acumuladoVistaOption === "periodo" ){
                    gridRefSumatorios.current.columnApi.setPivotMode(true);
                    gridRefSumatorios.current.api.setSideBar(['columns','filters']);
                    gridRefSumatorios.current.api.setSideBarVisible(true);

                    const ComparadorMeses = (a,b) => {
                        const meses = [  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ];
                        return meses.indexOf(a) - meses.indexOf(b);
                    };

                    
                    setColumnDefs([
                        { field:"nombre",headerName:"TOP",rowGroup:true,hide:true,filter:true},
                        { field:"periodoNombre",headerName:"Periodo" ,pivot:true,filter:true,enableValue:true,pivotComparator:ComparadorMeses},
                        { field:"totalVentas",headerName:"Total Ventas",aggFunc:"sum",enableValue:true},
                        { field:"argumentado",headerName:"Argumentado",aggFunc:"sum",enableValue:true,hide:true},
                        { field:"porcentajeArgumentado",headerName:"% Argumentado",aggFunc:"sum",enableValue: true,hide:true,
                           valueFormatter: params => {
                               return params.value ? params.value  + ' %' : '';
                           },
                       },
                       { field:"cerrados",headerName:"Cerrados",aggFunc:"sum",enableValue:true},
                       { field:"cta",headerName:"Cta",aggFunc:"sum",enableValue:true}
                   ])
                }

                break;
            default:
                break;
        }
        if ( kpis !== 'portipologia' && kpis !== 'porcampanas' && kpis !== 'performance' && kpis !== "acumulado" ) {
            gridRefRegistros.current.api.sizeColumnsToFit();
            gridRefRegistros.current.api.hideOverlay();
        }

        gridRefSumatorios.current.api.sizeColumnsToFit();
        gridRefSumatorios.current.api.hideOverlay();
    }

    const estadisticasBajas = async (periodo) => {
        
        let urlToSend = ''
        if (periodo) urlToSend = `/getestadisticasretenciones?periodo=${periodo}`
        else urlToSend = `/getestadisticasretenciones`

        let estadisticas = await apiRequest("get", urlToSend, null, true);

        if(estadisticas.data) {
            
            let datos = estadisticas.data;
            let agentes = (estadisticas.agentes) ? estadisticas.agentes : 0;
            let datosParaGrafico = estadisticas.grafico;
            
            let ventas = datos.find(dato => {
                return dato.descripcion.toLowerCase() === "ventas"
            })
            let caducados = datos.find(dato => {
                return dato.descripcion.toLowerCase() === "caducados"
            })
            let noCaducados = datos.find(dato => {
                return dato.descripcion.toLowerCase() === "no caducados"
            })
            
            if ( kpis === "retencion"  ) {
                
                let tmp = {...ventasKpi}
                tmp.total = ventas.HOY
                tmp.ventas_por_hora = (ventas.HOY / horasTrabajadas).toFixed(2);
                tmp.hit_rate_cerrados = ventas.HOY_RATE;
                tmp.ventas_por_hora_agente = (agentes > 0) ? (tmp.ventas_por_hora / agentes).toFixed(2) : 0;
                tmp.ventas_por_agente = (agentes > 0) ? (ventas.HOY / agentes).toFixed(2) : 0;

                if(tmp.total !== 0) {
                    tmp.hit_rate_caducados =  ( caducados.HOY / tmp.total ) 
                    tmp.hit_rate_no_caducados =  (tmp.total / noCaducados.HOY )  * 100
                } else {
                    tmp.hit_rate_caducados =  0
                    tmp.hit_rate_no_caducados =  0
                }
                
                setVentasKpi({...tmp})

                tmp = {...cerrados}
                tmp.total = caducados.HOY + noCaducados.HOY
                tmp.burn_rate = (tmp.total / horasTrabajadas).toFixed(2)
                tmp.burn_rate_agentes = (agentes > 0) ? (tmp.burn_rate / agentes).toFixed(2) : 0

                tmp.caducados = caducados.HOY;
                tmp.burn_rate_caducados = (tmp.caducados / horasTrabajadas).toFixed(2)
                tmp.burn_rate_caducados_agentes = ( agentes > 0 ) ? (tmp.caducados / agentes).toFixed(2) : 0;
                
                tmp.no_caducados = noCaducados.HOY
                tmp.burn_rate_no_caducados = (tmp.no_caducados / horasTrabajadas).toFixed(2);
                tmp.burn_rate_no_caducados_agentes = ( agentes > 0 ) ? (tmp.no_caducados / agentes).toFixed(2) : 0;
                
                setCerrados({...tmp})

                // Realizamos la agrupacion por fechas para el grafico
                let datosGrafico = []
                // Grafico Ventas
                let datosGraficoVentas = datosParaGrafico.map(dato => {
                    return {
                        "fecha" : dato.diaCalculo,
                        "total" : dato.ventas.total,
                        "tipo" : "Ventas"
                    }
                })
                datosGrafico = datosGrafico.concat(datosGraficoVentas)
                
                // Grafico Cerrados
                let datosGraficoCerrados = datosParaGrafico.map(dato => {
                    return {
                        "fecha" : dato.diaCalculo,
                        "total" : dato.cerrados.total,
                        "tipo" : "Cerrados"
                    }
                })
                datosGrafico = datosGrafico.concat(datosGraficoCerrados)

                // Grafico Caducados
                let datosGraficoCaducados = datosParaGrafico.map(dato => {
                    return {
                        "fecha" : dato.diaCalculo,
                        "total" : dato.cerradoscaducados.total,
                        "tipo" : "Caducados"
                    }
                })
                datosGrafico = datosGrafico.concat(datosGraficoCaducados)

                // Grafico No Caducados
                let datosGraficoNoCaducados = datosParaGrafico.map(dato => {
                    return {
                        "fecha" : dato.diaCalculo,
                        "total" : dato.cerradosnocaducados.total,
                        "tipo" : "No Caducados"
                    }
                })
                datosGrafico = datosGrafico.concat(datosGraficoNoCaducados)

                // console.log('datosGrafico :>> ', datosGrafico);

                setDatosGrafico(datosGrafico)
            }
        }
    }

    const fetchData = async (url) => {
        
        let response = [];

        let urlToTest = url.split('?')[0];

        // console.log('urlToTest :>> ', urlToTest);

        switch (urlToTest) {
            
            case "/getestadobajasBBDD":
            case "/getretencionBBDD":
            case "/getcontactabilidadBajasBBDD":
            case "/getagentesBajasBBDD":
                
                response = await apiRequest("get", url, null, true);
                if (response) {
                    let datos = response.data

                    setRowData(datos);
                    
                    // En funcion de la agrupacion realizamos los calculos
                    if(agrupacion === "singrupo") {
                        let ventas = datos.find(dato => {
                            return dato.descripcion.toLowerCase() === "retención"
                        })
                        let caducados = datos.find(dato => {
                            return dato.descripcion.toLowerCase() === "caducados"
                        })
                        let noCaducados = datos.find(dato => {
                            return dato.descripcion.toLowerCase() === "no caducados"
                        })
                        console.log('ventas :>> ', ventas);
                        
                        if ( kpis === "retencion"){
                            
                            let tmp = {...ventasKpi}
                            tmp.total = ventas.HOY
                            tmp.ventas_por_hora = (ventas.HOY / horasTrabajadas).toFixed(2);
                            tmp.hit_rate_cerrados = ventas.HOY_RATE;
                            if(tmp.total !== 0) {
                                tmp.hit_rate_caducados =  (caducados.HOY / tmp.total) 
                                tmp.hit_rate_no_caducados =  (noCaducados.HOY / tmp.total) 
                            } else {
                                tmp.hit_rate_caducados =  0
                                tmp.hit_rate_no_caducados =  0
                            }

                            setVentasKpi({...tmp})

                            tmp = {...cerrados}
                            tmp.total = caducados.HOY + noCaducados.HOY
                            tmp.burn_rate = (tmp.total / horasTrabajadas).toFixed(2)
                            tmp.caducados = caducados.HOY;
                            tmp.burn_rate_caducados = (tmp.caducados / horasTrabajadas).toFixed(2)
                            tmp.no_caducados = noCaducados.HOY
                            tmp.burn_rate_no_caducados = (tmp.no_caducados / horasTrabajadas).toFixed(2);
                            setCerrados({...tmp})
                            
                        }
                    }
                }
                break;
            case "/getPerformanceAgentes":   

                response = await apiRequest("get", url, null, true);
                if (response) {
                    setRowData(response.data);                    
                }
                // Seteamos los datos de performance
                console.log('response :>> ', response);
                let performance = {
                    ventas: response.totalVentas,
                    cerrados: response.totalCerrados,
                    argumentados: response.totalArgumentados,
                    hit_rate_cerrados: ((response.totalVentas / response.totalCerrados) *100).toFixed(2),
                    hit_rate_argumentados: ((response.totalVentas / response.totalArgumentados) *100).toFixed(2),
                }
                setPerformance(performance)
                break; 
            case "/getAgentesAcumulado":
                
                    response = await apiRequest("get", url, null, true);
                    if (response) {
                        setRowData(response.data);                    
                    }
                    break;
        
            case "/getestadoparamsbajasBBDD":
            case "/getretencionparamsBBDD":
            case "/getcontactabilidadparamsBajasBBDD":
            case "/getagentesparamsbajasBBDD":
                response = await apiRequest("get", url, null, true);
                if (response) {
                    setRowDataTotal(response.data);                    
                }
                break;
        
            default:
                break;
        }
        
        return true;
    };

    useEffect(() => {
        // Aqui empieza todo
        if( kpis === "performance"  || kpis === "acumulado") setMostrarPeriodo(false);
        else setMostrarPeriodo(true);

        cambioKpi(kpis, ordenacion);
    },[kpis, ordenacion, rangoFecha, agrupacion,rangoFechaVentas,rangoFechaPerformance,acumuladoOption,acumuladoVistaOption,triggerFiltro])

    useEffect(() => {

        if( kpis ==="retencion"){

            agrupacionVentasHandler();

        }else{
            switch (agentesAgrupacion) {
                case "numeros":
                    setColumnDefs([
                        { field: "descripcion",headerName: "Agente", filter: true, resizable:true },
                        { field: "HOY", headerName: "Cerrados Ayer", filter: false, resizable:true },
                        { field: "HOY_VENTAS", headerName: "Ventas ayer", filter: false, resizable:true },
                       
                        { field: "HOY_ARGUMENTADO", headerName: "Argumentado ayer", filter: false, resizable:true },
                        { field: "WTD", headerName: "WTD", filter: false, resizable:true },
                        { field: "WTD_VENTAS", headerName: "Ventas WTD", filter: false, resizable:true },
                        
                        { field: "WTD_ARGUMENTADO", headerName: "Argumentado WTD", filter: false,resizable:true },
                        { field: "MTD", headerName: "MTD", filter: false, resizable:true },
                        { field: "MTD_VENTAS", headerName: "Ventas MTD", filter: false, resizable:true },
                       
                        { field: "MTD_ARGUMENTADO", headerName: "Argumentado MTD", filter: false, resizable:true },
                        { field: "YTD", headerName: "YTD", filter: false, resizable:true },
                        { field: "YTD_VENTAS", headerName: "Ventas YTD", filter: false, resizable:true },
                        
                        { field: "YTD_ARGUMENTADO", headerName: "Argumentado YTD", filter: false, resizable:true },
                    ]);
                    break;
                
                case "porcentaje":
                    setColumnDefs([
                        { field: "descripcion",headerName: "Agente", filter: true, resizable:true },
                        { field: "HOY_HR", headerName: "Hit Rate Ayer", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            } 
                        },
                     
                        { field: "WTD_HR", headerName: "Hit Rate WTD", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            }
                        },
                       
                        { field: "MTD_HR", headerName: "Hit Rate MTD", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            }
                        },
                      
                        { field: "YTD_HR", headerName: "Hit Rate YTD", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            }     
                        },
                       
                    ]);
                break;
    
                default:
    
                    setColumnDefs([
                        { field: "descripcion",headerName: "Agente", filter: true, resizable:true },
                        { field: "HOY", headerName: "Cerrados Ayer", filter: false, resizable:true },
                        { field: "HOY_VENTAS", headerName: "Ventas ayer", filter: false, resizable:true },
                        { field: "HOY_HR", headerName: "Hit Rate Ayer", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            } 
                        },
                        { field: "HOY_ARGUMENTADO", headerName: "Argumentado ayer", filter: false, resizable:true },
                        { field: "WTD", headerName: "WTD", filter: false, resizable:true },
                        { field: "WTD_VENTAS", headerName: "Ventas WTD", filter: false, resizable:true },
                        { field: "WTD_HR", headerName: "Hit Rate WTD", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            }
                        },
                        { field: "WTD_ARGUMENTADO", headerName: "Argumentado WTD", filter: false,resizable:true },
                        { field: "MTD", headerName: "MTD", filter: false, resizable:true },
                        { field: "MTD_VENTAS", headerName: "Ventas MTD", filter: false, resizable:true },
                        { field: "MTD_HR", headerName: "Hit Rate MTD", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            }
                        },
                        { field: "MTD_ARGUMENTADO", headerName: "Argumentado MTD", filter: false, resizable:true },
                        { field: "YTD", headerName: "YTD", filter: false, resizable:true },
                        { field: "YTD_VENTAS", headerName: "Ventas YTD", filter: false, resizable:true },
                        { field: "YTD_HR", headerName: "Hit Rate YTD", filter: false, resizable:true,
                            valueFormatter: params => {
                                if(params.value)
                                    return params.value.toFixed(2) + ' %'
                            }     
                        },
                        { field: "YTD_ARGUMENTADO", headerName: "Argumentado YTD", filter: false, resizable:true },
                    ]);
    
                    break;
            }
        }
        
        



    },[agentesAgrupacion])

    
    // Listado de botones
    const menuOpciones = [
        {
            label: "Estado BBDD",
            value: "estadobbdd",
        },
        {
            label: "Retención",
            value: "retencion",
        },
        {
            label:"Performance",
            value:"performance"
        },
        {
            label: "Contactabilidad",
            value: "contactabilidad",
        },
        {
            label: "Agentes",
            value: "agentes",
        },
        {
            label:"Acumulado Agentes",
            value:"acumulado"
        }
    ];
    
    // Listado de botones
    const listaOrdenacion = [
        {
            label: "Dias",
            value: "registros",
        },
        {
            label: "Por intentos",
            value: "intentos",
        },
        {
            label: "Por dias hasta contratacion",
            value: "dias",
        },
    ];
   
    // Listado de botones
    const listaAgrupacion = [
        {
            label: "Sin grupo",
            value: "singrupo",
        },
        {
            label: "Por campañas",
            value: "porcampanas",
        },
        {
            label: "Por tipo campaña",
            value: "portipocampana",
        },
    ];

    const listaAccionesAgentes = [
        {
            label: "Total",
            value: "total",
        },
        {
            label: "Ventas",
            value: "ventas",
        },
        {
            label: "Contactabilidad",
            value: "contactabilidad",
        },
    ]

    const listaBotontesAgentes = [
        {
            label:"Porcentaje",
            value:"porcentaje"
        },
        {
            label:"Numeros",
            value:"numeros"
        },
        {
            label:"Ambos",
            value:"ambos"
        }
    ]
    const botonesAcumulado = [
        {
            label:"Semanal",
            value:"semanal"
        },
        {
            label:"Mensual",
            value:"mensual"
        },
        {
            label:"Trimestral",
            value:"trimestral"
        },
        {
            label:"Semestral",
            value:"semestral"
        },
        {
            label:"Anual",
            value:"anual"
        }
    ]
    const acumuladoOpcionesVista = [
        {
            label:"Periodo",
            value:"periodo"
        },
        {
            label:"Agente",
            value:"agente"
        }
    ]
    const listaTipoRegistro = [
        {
            _id:"call blending",
            nombre:"Call Blending",
        },
        {
            _id:"primario",
            nombre:"Primario",
        },
        {
            _id:"secundario activo",
            nombre:"Secundario Activo",
        },
        {
            _id:"secundario baja",
            nombre:"Secundario Baja",
        }

    ]


    return (
        <div>
            <h1>Bajas KpiRepsolAG</h1>
            <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: 500 }}
            >
                <Row>
                    <Col span={12}>
                        KPI's
                        <ButtonGroup
                            mode="single"
                            value={kpis}
                            onChange={(val) => {
                                setKpis(val);
                            }}
                            className="bloqueBotones"
                            options={menuOpciones}
                        />
                    </Col>
                    { (kpis === "retencion" || kpis === "agentes" ) &&  <Col offset={8} span={4} >
                            Agrupación
                            <ButtonGroup
                                mode="single"
                                value={agentesAgrupacion}
                                onChange={(val) => {
                                    setAgentesAgrupacion(val);
                                }}
                                className="bloqueBotones"
                                options={listaBotontesAgentes}
                            />
                    </Col>}
                    
                </Row>
                {( kpis === "retencion" ) && (
                    <>
                    <Divider />
                    <Row>
                        <Col span={12}>
                            <div style={{ width: "100%", marginBottom: "10px" }}>
                                Periodo
                                <RangePicker
                                    style={{ width: "100%" }}
                                    value={rangoFechaVentas}
                                    format="DD-MM-YYYY"
                                    onChange={(arrDesdeHasta) => {
                                        setRangoFechaVentas(arrDesdeHasta);

                                    }}
                                    ranges={getDateRanges()}
                                    disabledDate={(current) => {
                                        let customDate =
                                            dayjs("2022-06-13").format(
                                                "YYYY-MM-DD"
                                            );
                                        let todayDate = dayjs()
                                            .format("YYYY-MM-DD");

                                        return (
                                            current &&
                                            (current < dayjs(customDate) ||
                                                current > dayjs(todayDate))
                                        );
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="site-statistic-demo-card">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card>
                                <Statistic
                                    title="Ventas Totales"
                                    value={ventasKpi.total}
                                    />
                               
                                <Statistic
                                    title="Ventas por hora"
                                    value={ventasKpi.ventas_por_hora}
                                    />
                               
                                <Row>
                                    <Col span={8}>
                                        <Statistic
                                        title="Burn Rate"
                                        value={cerrados.burn_rate}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic
                                        title="Burn Rate Caducados"
                                        value={cerrados.burn_rate_caducados}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic
                                        title="Burn Rate No Caducados"
                                        value={cerrados.burn_rate_no_caducados}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <Statistic
                                            title="Burn Rate Agente"
                                            value={cerrados.burn_rate_agentes}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic
                                            title="Burn Rate Caducados por agente"
                                            value={cerrados.burn_rate_caducados_agentes}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic
                                            title="Burn Rate No Caducados por agente"
                                            value={cerrados.burn_rate_no_caducados_agentes}
                                        />
                                    </Col>
                                </Row>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card>
                                
                                <Statistic
                                    title="Hit rate sobre cerrados"
                                    value={ventasKpi.hit_rate_cerrados}
                                    precision={2}
                                    suffix="%"
                                />
                                <Statistic
                                    title="Hit rate sobre cerrados no caducados"
                                    value={ventasKpi.hit_rate_no_caducados}
                                    precision={2}
                                    suffix="%"
                                />
                                {/* <Statistic
                                    title="Hit rate sobre caducados"
                                    value={ventasKpi.hit_rate_caducados}
                                    precision={2}
                                    // valueStyle={{
                                    //     color: '#3f8600',
                                    // }}
                                    // prefix={<ArrowUpOutlined />}
                                    suffix="%"
                                /> */}
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card>
                                <Statistic
                                    title="Leads Cerrados Totales"
                                    value={cerrados.total}
                                />
                                <Statistic
                                    title="Leads Cerrados No caducados"
                                    value={cerrados.no_caducados}
                                />
                                <Statistic
                                    title="Leads Cerrados Caducados"
                                    value={cerrados.caducados}
                                />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Divider />
                    <Button 
                        style={{marginBottom: "10px"}}
                        onClick={() => {
                            setMostrarEvolucion(!mostrarEvolucion);
                        }}
                    >Ver Evolución</Button>

                    {
                        mostrarEvolucion && (<>
                            {/* <h1><b>Evolución periodo</b></h1> */}
                            <Line {...config} />
                        </>)
                    }
                    </>
                )}
                {( kpis === "performance") && (
                     <>
                     <Divider />
                     <Row>
                         <Col span={12}>
                             <div style={{ width: "100%", marginBottom: "10px" }}>
                                 Periodo
                                 <RangePicker
                                     style={{ width: "100%" }}
                                     value={rangoFechaPerformance}
                                     format="DD-MM-YYYY"
                                     onChange={(arrDesdeHasta) => {
                                         setRangoFechaPerformance(arrDesdeHasta);
 
                                     }}
                                     ranges={getDateRanges()}
                                     disabledDate={(current) => {
                                         let customDate =
                                             dayjs("2022-06-13").format(
                                                 "YYYY-MM-DD"
                                             );
                                         let todayDate = dayjs()
                                             .format("YYYY-MM-DD");
 
                                         return (
                                             current &&
                                             (current < dayjs(customDate) ||
                                                 current > dayjs(todayDate))
                                         );
                                     }}
                                 />
                             </div>
                         </Col>
                     </Row>
                     <Divider />
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Card>
                                <Statistic
                                    title="Total Ventas"
                                    value={performance.ventas}
                                />
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Card>
                                <Statistic

                                    title="Total Cerrados"
                                    value={performance.cerrados}
                                />
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Card>
                                <Statistic
                                    title="Total Argumentados"
                                    value={performance.argumentados}
                                />
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Card>
                                <Statistic
                                    title="HR sobre cerrados"
                                    value={performance.hit_rate_cerrados}
                                />
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                                <Card>
                                <Statistic
                                    title="HR sobre argumentados"
                                    value={performance.hit_rate_argumentados}
                                />
                                </Card>
                            </Col>
                        </Row>
                     </>              
                )}
                {(kpis === "acumulado") && 
                    <>
                    <Divider />
                    <Row>
                        <Col span={12} >
                            Opciones
                            <ButtonGroup
                                mode="single"
                                value={acumuladoOption}
                                onChange={(val) => {
                                    setAcumuladoOption(val);
                                }}
                                className="bloqueBotones"
                                options={botonesAcumulado}
                            />
                        </Col>
                        <Col offset={8} span={4} >
                            Opciones
                            <ButtonGroup
                                mode="single"
                                value={acumuladoVistaOption}
                                onChange={(val) => {
                                    setacumuladoVistaOption(val);
                                }}
                                className="bloqueBotones"
                                options={acumuladoOpcionesVista}
                            />
                        </Col>
                    </Row>
                    
                    </>
                }
                {/* NO ES NECESARIO PARA BAJAS
                {kpis === "ventas" && (
                    <>
                    <Divider />
                    <Row>
                    <Col span={12}>
                        Agrupación
                        <ButtonGroup
                            mode="single"
                            value={agrupacion}
                            onChange={(val) => {
                                setAgrupacion(val);
                            }}
                            className="bloqueBotones"
                            options={listaAgrupacion}
                        />
                    </Col>
                    <Col span={12} style={{paddingLeft:"25px"}}>
                        
                    </Col>
                </Row>
                    </>
                )} */}
                <Row>
                    <Col span={12}>
                            <div style={{ width: "50%", marginBottom: "10px", marginTop:"10px" }}>
                            Filtro
                            <BuscadorGenerico
                                className={"w100 mb1"}
                                listado={listaTipoRegistro}
                                value={valorFiltro}
                                mode={"multiple"}
                                placeholder={"Tipo Registro"}
                                onChange={(ev)=>{
                                    setValorFiltro(ev)
                                }}
                            />
                            <Button type="primary" icon={<SearchOutlined/>} onClick={(ev)=> setTriggerFiltro(!triggerFiltro) }>
                                Buscar
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Divider />
                <AgGridReact
                    style={{ width: "100%"}}
                    ref={gridRefSumatorios} // Ref for accessing Grid's API
                    // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                    rowData={rowData} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="multiple" // Options - allows click selection of rows
                    gridOptions={gridOptions} // Optional - pass in gridOptions
                    overlayLoadingTemplate={
                        '<span class="ag-overlay-loading-center">Cargando datos...</span>'
                    }
                    overlayNoRowsTemplate={
                        '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Cargando datos...</span>'
                    }
                    // groupDisplayType={'singleColumn'}
                />
                <Divider />
                {mostrarPeriodo && (

                <>
                <Row>
                    <Col span={12}>
                        <div style={{ width: "100%", marginBottom: "10px" }}>
                            Periodo
                            <RangePicker
                                style={{ width: "100%" }}
                                value={rangoFecha}
                                format="DD-MM-YYYY"
                                onChange={(arrDesdeHasta) => {
                                    setRangoFecha(arrDesdeHasta);

                                }}
                                ranges={getDateRanges()}
                                disabledDate={(current) => {
                                    let customDate =
                                        dayjs("2022-06-13").format(
                                            "YYYY-MM-DD"
                                        );
                                    let tomorrowDate = dayjs()
                                        .add(1, "day")
                                        .format("YYYY-MM-DD");
                                    return (
                                        current &&
                                        (current < dayjs(customDate) ||
                                            current > dayjs(tomorrowDate))
                                    );
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={12} style={{paddingLeft:"25px"}}>
                        {kpis === "retencion" && (
                            <>
                            Agrupación
                            <ButtonGroup
                                mode="single"
                                value={ordenacion}
                                onChange={(val) => {
                                    setOrdenacion(val);
                                }}
                                className="bloqueBotones"
                                options={listaOrdenacion}
                            />
                            </>
                        )}
                    </Col>
                </Row>
                
                
                <AgGridReact
                    style={{ width: "100%", height: "100%" }}
                    ref={gridRefRegistros} // Ref for accessing Grid's API
                    rowData={rowDataTotal} // Row Data for Rows
                    columnDefs={columnDefsTotal} // Column Defs for Columns
                    defaultColDef={{sortable:true}} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="custom" // Options - allows click selection of rows
                    gridOptions={gridOptionsTotal} // Optional - pass in gridOptions
                    enableCharts={true} 
                    enableRangeSelection={true}
                    sideBar={['columns','filters']}
                    overlayLoadingTemplate={
                        '<span class="ag-overlay-loading-center">Cargando datos...</span>'
                    }
                    overlayNoRowsTemplate={
                        '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Cargando datos...</span>'
                    }
                    // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                    // groupDisplayType={'multipleColumns'}
                    // pivotMode={true}
                />
                <Divider />
                </>
                )}
            </div>
        </div>
    );
};
export default KpiRepsolBajasAg;
