
import { Button } from "antd";
import { mbxSocket } from "MobX/root";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import initSocket from "../socket/initSocket";
import apiRequest from "../utils/apiRequest";
import LogRocket from "logrocket";



export default function Guard ({
	component,
	allowedUsers = []
}) {
	
	const history = useHistory();
	const [estado, setEstado] = useState("cargando"); // "cargando", "bloqueado", "permitido"
	const [socketReady, setSocketReady] = useState(mbxSocket.ready ? "cargado" : "cargando"); // "cargando", "cargado", "error"
	
	
	useEffect( () => {
		(async() => {
			
			const intervalMs = 100;
			let intentos = 20;
			
			const intervalId = setInterval( () => {
				
				if (mbxSocket.ready) {
					clearInterval(intervalId);
					setSocketReady("cargado");
				};
				
				if (intentos-- < 0) {
					clearInterval(intervalId);
					setSocketReady("error");
				};
				
			}, intervalMs);
			setSocketReady("cargando");
			
		})();
	}, []);
	
	
	useEffect( () => {
		(async() => {
			
			const user = JSON.parse(localStorage.getItem("global")).user;
			if (!user || !user.token) {
				history.push("/login");
				return null;
			}
			
			
			const res = await apiRequest("get", "/login", null, true);
			if (res._error && [401, 0].includes(res._status)) return setEstado("bloqueado");
			
			
			
			LogRocket.identify(res.username, {
				id: res.id,
				name: res.name,
				username: res.username,
				role: res.role,
				extension: res.extension
			});
			
			
			initSocket({
				id: res.id,
				name: res.name,
				username: res.username,
				role: res.role,
				extension: res.extension
			});
			return setEstado("permitido");
			
		})();
	}, [history]);
	
	
	
	if (estado === "cargando" || estado === "dashboard") return <p>Comprobando credenciales...</p>
	if (estado === "bloqueado") {
		history.push("/login");
		return null;
	}
	if (socketReady === "cargando") return <p>Cargando socket...</p>;
	if (socketReady === "error") return (
		<p>
			Tiempo de espera agotado, refresca la página y vuelve a intentarlo.<br/>
			<Button
				onClick={ () => window.location.reload() }
				type="primary"
			>Refrescar</Button>
		</p>
	);
	
	const user = JSON.parse(localStorage.getItem("global")).user;
	if ( allowedUsers.length > 0 && !allowedUsers.includes( user.id ) ) {

		return <h1 className="w100 fs6 bold tac mt5 colVerCor"> NO TIENES PERMISOS SUFICIENTES </h1>;

	}
	
	return component;
	
};