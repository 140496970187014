import socketIOClient from "socket.io-client";
import { mbxGlobal, mbxSocket } from "../MobX/root";
import compruebaPausa from "./functions/compruebaPausa";
import initTengoLeads from "./functions/initTengoLead";
import { setUsuarioEstado } from "./functions/setUsuarioEstado";



export default function initSocket(user) {
	
	if (mbxSocket.socket) return; // si ya tengo el socket, salgo
	
	
	
	// Conecto al socket
	const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
		query: user,
	});
	mbxSocket.socket = socket;
	
	
	
	socket.on("ready", () => {
		
		setUsuarioEstado({
			nombre: "navegando",
			url: window.location.pathname,
			query: window.location.search,
		});
		
		mbxSocket.ready = true;
	});
	
	
	
	// Para DEBUG
	// socket.onAny( (a, b) => {
	// 	console.log( "onAny", a, b );
	// });
	
	
	socket.on("com:newTab", url => {
		window.open(url, "_blank", "noopener");
	});
	
	
	socket.on("get:usuario", usuario => {
		//compruebaPausa(usuario);
	});

	
	initTengoLeads();
	socket.on("hayLeadNormal", hay => {
		mbxGlobal.hayLeadNormal = hay;
	});
	

	socket.on("hayLeadRecuperable", hay => {
		mbxGlobal.hayLeadRecuperable = hay;
	});


	
};


