
let timeout = {};


/**
 * 
 * @param {number} wait Milisegundos que va a durar el freno.
 * @param {function} fnc Función a ejecutar.
 * @param {string} [id] Identificador único que distinguirá las acciones realizadas, para saber si son las mismas o diferentes. Si se omite se usará como `id` el parámetro `fnc` en string (menos óptimo).
 * 
*/

export default function freno(wait, fnc, id) {
	
	if (!fnc) return console.error( "Error: fnc null" );
	if (typeof fnc !== "function") return console.error("Error: fnc no es una función: ", fnc);
	
	
	if (!id) id = "" + fnc;
	if (timeout[id]) return; // no hago nada si ya está siendo frenado
	
	
	fnc(); // ejecuto la función
	clearTimeout(timeout[id]); // quito el timeout de la función anteriormente lanzada
	
	
	timeout[id] = setTimeout(() => {
		delete timeout[id]; // elimino del objeto
	}, wait); // creo uno nuevo
	
};
