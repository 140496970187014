import React, { useState } from "react";
import {
    Row,
    Col,
    Divider,
    Table,
    DatePicker,
    Button,
    Select,
    Spin,
    Radio,
    message,
} from "antd";
import apiRequest from "utils/apiRequest";
import dayjs from "dayjs";
import numToStr from "utils/numToStr";
import getDateRanges from "utils/getDateRanges";
import "./Margenes.css";
import { Option } from "antd/lib/mentions";
import DebounceSelect from "components/DebounceSelect";

const ranges = { ...getDateRanges() };
delete ranges["Hoy"];
delete ranges["Ayer"];

async function fetchList(valor, tipo) {
    let datosMargenes = await apiRequest(
        "get",
        "/datos?d=" + tipo + "&v=" + valor,
        null,
        true
    );

    let arrayData = [];
    if (datosMargenes) {
        datosMargenes.datos.forEach((dato) => {
            let tmp = {
                label: `${dato}`,
                value: `${dato}`,
            };
            arrayData.push(tmp);
        });
    }

    return arrayData;
}

const MargenesCambiados = () => {
    const { RangePicker } = DatePicker;

    const [margenes, setMargenes] = useState([]);
    const [totalMargen, setTotalMargen] = useState(0);
    const [totalEnergia, setTotalEnergia] = useState(0);
    const [totalPotencia, setTotalPotencia] = useState(0);
    const [totalServicio, setTotalServicio] = useState(0);
    const [totalDescuentos, setTotalDescuentos] = useState(0);
    const [totalFacturado, setTotalFacturado] = useState(0);
    const [totalPorcentajeMargen, setTotalPorcentajeMargen] = useState(0);
    const [totalPorcentajeCobertura, setTotalPorcentajeCobertura] = useState(0);
    const [porcentajeTotal, setPorcentajeTotal] = useState(0);
    const [totalCoberturaFijo, setTotalCoberturaFijo] = useState(0);
    const [totalCoberturaFijoOMIE, setTotalCoberturaFijoOMIE] = useState(0);
    const [totalCoberturaFijoTG, setTotalCoberturaFijoTG] = useState(0);

    const [totalCoberturaFiltro, setTotalCoberturaFiltro] = useState(0);
    const [totalCoberturaFiltroOMIE, setTotalCoberturaFiltroOMIE] = useState(0);
    const [totalCoberturaFiltroTG, setTotalCoberturaFiltroTG] = useState(0);

    const [totalCobertura, setTotalCobertura] = useState(0);

    const [totalCosteEnergia, setTotalCosteEnergia] = useState(0);
    const [totalCostePotencia, setTotalCostePotencia] = useState(0);

    const [consumoTotalFiltro, setConsumoTotalFiltro] = useState(0);

    // Porcentaje
    const [totalPorcentajeEnergia, setTotalPorcentajeEnergia] = useState(0);
    const [totalPorcentajePotencia, setTotalPorcentajePotencia] = useState(0);

    // Media de Potencias por periodos
    const [mediaPotencia, setMediaPotencia] = useState({
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        total: 0,
    });
    // Precio de Potencias por periodos TOTAL
    const [precioPotencia, setPrecioPotencia] = useState({
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        total: 0,
    });
    // Precio de Potencias por OMIE
    const [precioPotenciaOMIE, setPrecioPotenciaOMIE] = useState({
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        total: 0,
    });

    // Precio de Potencias por Tope de Gas
    const [precioPotenciaTG, setPrecioPotenciaTG] = useState({
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        total: 0,
    });

    // Datos globales
    const [datosGlobales, setDatosGlobales] = useState({
        consumoTotal: 0,
        porcentajeCubierto: 0,
        porcentajeCubiertoTopeGas: 0,
        periodoCubierto: 0,
        periodoCubiertoTopeGas: 0,
        diasPeriodo: 0,
    });

    //! Cambiar precio 
    /*
    Media ponderada de todas las coberturas que entren en el periodo, precio por kw ponderado (quien tenga mas cubierto mas pesa)
    */

    const [precioMargen, setPrecioMargen] = useState(0.06);
    /**
     * FILTROS
     */
    const [facturas, setFacturas] = useState([]);
    const [dnis, setDnis] = useState([]);
    const [cups, setCups] = useState([]);
    const [tarifas, setTarifas] = useState([]);
    const [atrs, setAtrs] = useState([]);
    const [origenes, setOrigenes] = useState("Todos");
    const [fijo, setFijo] = useState("Ambos");
    const [luz, setLuz] = useState("Ambos");

    const [periodoFacturacion, setPeriodoFacturacion] = useState("");
    const [periodoEmision, setPeriodoEmision] = useState("");
    const [descuentos, setDescuentos] = useState("-1");
    const [excluyente, setExcluyente] = useState("0");

    const [cargando, setCargando] = useState(false);

    const [pagina, setPaginas] = useState(1);
    const [cuantos, setCuantos] = useState(10);

    const [totalElementos, setTotalElementos] = useState(0);
    const [temporales, setTemporales] = useState(null);

    const [messageApi, contextHolder] = message.useMessage();

    const mensaje = (tipo, mensaje) => {
        messageApi.open({
            type: tipo,
            content: mensaje,
            style: {
                marginTop: '5vh',
                fontSize: '1rem',
            },
            duration: 5,
        });
    }

    const calcular = async () => {
        if(periodoEmision === "" && periodoFacturacion === ""){
            
            mensaje("error", "Debes seleccionar un periodo de emisión o de facturación");
            return;
            
        }

        setCargando(true);

        let body = {
            facturas: facturas,
            dnis: dnis,
            cups: cups,
            tarifas: tarifas,
            atrs: atrs,
            origenes: origenes,
            fijo: fijo,
            luz: luz,
            periodoFacturacion: periodoFacturacion,
            periodoEmision: periodoEmision,
            descuentos: descuentos,
            excluyente: excluyente,
            pagina: pagina,
            cuantos: cuantos,
        };

        let aplicaFiltro = true;

        // Comprobamos si se ha aplicado algun filtro
        if (
            body.facturas.length === 0 &&
            body.dnis.length === 0 &&
            body.cups.length === 0 &&
            body.tarifas.length === 0 &&
            body.atrs.length === 0 &&
            body.origenes === "Todos" &&
            body.fijo === "Ambos" &&
            body.luz === "Ambos"
        ) {
            aplicaFiltro = false;
        }
        // LISTADO DE MARGENES
        const datosMargenes = await apiRequest("post", "/margenes_copy", body, true);
        

        const precioMargen = (datosMargenes.precio_margen * 1.2) .toFixed(6);
        setPrecioMargen( (datosMargenes.precio_margen  * 1.2 ).toFixed(6));
        
        if (!datosMargenes._error && !datosMargenes.error ) {
            
        } else {
            setCargando(false);
            limpiar();
            mensaje("error", "Ha ocurrido un error al obtener los datos");
            return;
        }

        datosMargenes.tmp && setTemporales(datosMargenes.tmp);

        // Diferenciar si ha venido por periodo de facturacion o por periodo de emision
        let totalMargen = 0;
        let totalPotencia = 0;
        let totalEnergia = 0;
        let totalDescuentos = 0;
        let totalServicio = 0;
        let totalFacturado = 0;
        let totalCosteEnergia = 0;
        let totalCostePotencia = 0;
        let totalCobertura = 0;
        let totalCoberturaOMIE = 0;
        let totalCoberturaTG = 0;

        if (periodoFacturacion) {
            // PERIODO DE FACTURACION
            totalMargen = datosMargenes.total_margen
                ? datosMargenes.total_margen
                : 0;
            totalPotencia = datosMargenes.margen_potencia
                ? datosMargenes.margen_potencia
                : 0;
            totalEnergia = datosMargenes.margen_energia
                ? datosMargenes.margen_energia
                : 0;
            totalDescuentos = datosMargenes.descuentos_recargos
                ? datosMargenes.descuentos_recargos
                : 0;
            totalServicio = datosMargenes.servicios
                ? datosMargenes.servicios
                : 0;
            totalFacturado = datosMargenes.total_facturado
                ? datosMargenes.total_facturado
                : 0;
            totalCosteEnergia = datosMargenes.total_energia
                ? datosMargenes.total_energia
                : 0;
            totalCostePotencia = datosMargenes.total_potencia
                ? datosMargenes.total_potencia
                : 0;
            let total_documentos = datosMargenes.total_documentos
                ? datosMargenes.total_documentos
                : 0;
            let total_documentos_luz = datosMargenes.total_documentos_luz
                ? datosMargenes.total_documentos_luz
                : 0;
            setTotalElementos(total_documentos);

            // MEDIA POTENCIAS
            let elemMedios = 0;
            let mediaP1 =
                datosMargenes.total_P1[0].contador_potencia > 0
                    ? datosMargenes.total_P1[0].potencia /
                      datosMargenes.total_P1[0].contador_potencia
                    : 0;
            let mediaP2 =
                datosMargenes.total_P2[0].contador_potencia > 0
                    ? datosMargenes.total_P2[0].potencia /
                      datosMargenes.total_P2[0].contador_potencia
                    : 0;
            let mediaP3 =
                datosMargenes.total_P3[0].contador_potencia > 0
                    ? datosMargenes.total_P3[0].potencia /
                      datosMargenes.total_P3[0].contador_potencia
                    : 0;
            let mediaP4 =
                datosMargenes.total_P4[0].contador_potencia > 0
                    ? datosMargenes.total_P4[0].potencia /
                      datosMargenes.total_P4[0].contador_potencia
                    : 0;
            let mediaP5 =
                datosMargenes.total_P5[0].contador_potencia > 0
                    ? datosMargenes.total_P5[0].potencia /
                      datosMargenes.total_P5[0].contador_potencia
                    : 0;
            let mediaP6 =
                datosMargenes.total_P6[0].contador_potencia > 0
                    ? datosMargenes.total_P6[0].potencia /
                      datosMargenes.total_P6[0].contador_potencia
                    : 0;
            if (mediaP1 > 0) elemMedios++;
            if (mediaP2 > 0) elemMedios++;
            if (mediaP3 > 0) elemMedios++;
            if (mediaP4 > 0) elemMedios++;
            if (mediaP5 > 0) elemMedios++;
            if (mediaP6 > 0) elemMedios++;
            let mediaTotal =
                (mediaP1 + mediaP2 + mediaP3 + mediaP4 + mediaP5 + mediaP6) /
                elemMedios;
            let tmp = {
                p1: mediaP1,
                p2: mediaP2,
                p3: mediaP3,
                p4: mediaP4,
                p5: mediaP5,
                p6: mediaP6,
                total: mediaTotal ? mediaTotal : 0,
            };

            setMediaPotencia(tmp);

            // MEDIA PRECIOS ENERGIA
            elemMedios = 0;
            let mediaPrecioP1 =
                datosMargenes.total_P1[0].contador > 0
                    ? datosMargenes.total_P1[0].precio /
                      datosMargenes.total_P1[0].contador
                    : 0;
            let mediaPrecioP2 =
                datosMargenes.total_P2[0].contador > 0
                    ? datosMargenes.total_P2[0].precio /
                      datosMargenes.total_P2[0].contador
                    : 0;
            let mediaPrecioP3 =
                datosMargenes.total_P3[0].contador > 0
                    ? datosMargenes.total_P3[0].precio /
                      datosMargenes.total_P3[0].contador
                    : 0;
            let mediaPrecioP4 =
                datosMargenes.total_P4[0].contador > 0
                    ? datosMargenes.total_P4[0].precio /
                      datosMargenes.total_P4[0].contador
                    : 0;
            let mediaPrecioP5 =
                datosMargenes.total_P5[0].contador > 0
                    ? datosMargenes.total_P5[0].precio /
                      datosMargenes.total_P5[0].contador
                    : 0;
            let mediaPrecioP6 =
                datosMargenes.total_P6[0].contador > 0
                    ? datosMargenes.total_P6[0].precio /
                      datosMargenes.total_P6[0].contador
                    : 0;
            if (mediaPrecioP1 > 0) elemMedios++;
            if (mediaPrecioP2 > 0) elemMedios++;
            if (mediaPrecioP3 > 0) elemMedios++;
            if (mediaPrecioP4 > 0) elemMedios++;
            if (mediaPrecioP5 > 0) elemMedios++;
            if (mediaPrecioP6 > 0) elemMedios++;
            let mediaPrecioTotal =
                (mediaPrecioP1 +
                    mediaPrecioP2 +
                    mediaPrecioP3 +
                    mediaPrecioP4 +
                    mediaPrecioP5 +
                    mediaPrecioP6) /
                elemMedios;
            let tmp2 = {
                p1: mediaPrecioP1,
                p2: mediaPrecioP2,
                p3: mediaPrecioP3,
                p4: mediaPrecioP4,
                p5: mediaPrecioP5,
                p6: mediaPrecioP6,
                total: mediaPrecioTotal ? mediaPrecioTotal : 0,
            };
            setPrecioPotencia(tmp2);

            // MEDIA PRECIOS ENERGIA OMIE
            elemMedios = 0;
            let mediaPrecioP1OMIE =
                datosMargenes.total_P1[0].contador > 0
                    ? datosMargenes.total_P1[0].precio_OMIE /
                      datosMargenes.total_P1[0].contador
                    : 0;
            let mediaPrecioP2OMIE =
                datosMargenes.total_P2[0].contador > 0
                    ? datosMargenes.total_P2[0].precio_OMIE /
                      datosMargenes.total_P2[0].contador
                    : 0;
            let mediaPrecioP3OMIE =
                datosMargenes.total_P3[0].contador > 0
                    ? datosMargenes.total_P3[0].precio_OMIE /
                      datosMargenes.total_P3[0].contador
                    : 0;
            let mediaPrecioP4OMIE =
                datosMargenes.total_P4[0].contador > 0
                    ? datosMargenes.total_P4[0].precio_OMIE /
                      datosMargenes.total_P4[0].contador
                    : 0;
            let mediaPrecioP5OMIE =
                datosMargenes.total_P5[0].contador > 0
                    ? datosMargenes.total_P5[0].precio_OMIE /
                      datosMargenes.total_P5[0].contador
                    : 0;
            let mediaPrecioP6OMIE =
                datosMargenes.total_P6[0].contador > 0
                    ? datosMargenes.total_P6[0].precio_OMIE /
                      datosMargenes.total_P6[0].contador
                    : 0;
            if (mediaPrecioP1OMIE > 0) elemMedios++;
            if (mediaPrecioP2OMIE > 0) elemMedios++;
            if (mediaPrecioP3OMIE > 0) elemMedios++;
            if (mediaPrecioP4OMIE > 0) elemMedios++;
            if (mediaPrecioP5OMIE > 0) elemMedios++;
            if (mediaPrecioP6OMIE > 0) elemMedios++;
            let mediaPrecioTotalOMIE =
                (mediaPrecioP1OMIE +
                    mediaPrecioP2OMIE +
                    mediaPrecioP3OMIE +
                    mediaPrecioP4OMIE +
                    mediaPrecioP5OMIE +
                    mediaPrecioP6OMIE) /
                elemMedios;
            let tmp3 = {
                p1: mediaPrecioP1OMIE,
                p2: mediaPrecioP2OMIE,
                p3: mediaPrecioP3OMIE,
                p4: mediaPrecioP4OMIE,
                p5: mediaPrecioP5OMIE,
                p6: mediaPrecioP6OMIE,
                total: mediaPrecioTotalOMIE ? mediaPrecioTotalOMIE : 0,
            };
            setPrecioPotenciaOMIE(tmp3);

            // MEDIA PRECIOS ENERGIA TG
            elemMedios = 0;
            let mediaPrecioP1TG =
                datosMargenes.total_P1[0].contador > 0
                    ? datosMargenes.total_P1[0].precio_TG /
                      datosMargenes.total_P1[0].contador
                    : 0;
            let mediaPrecioP2TG =
                datosMargenes.total_P2[0].contador > 0
                    ? datosMargenes.total_P2[0].precio_TG /
                      datosMargenes.total_P2[0].contador
                    : 0;
            let mediaPrecioP3TG =
                datosMargenes.total_P3[0].contador > 0
                    ? datosMargenes.total_P3[0].precio_TG /
                      datosMargenes.total_P3[0].contador
                    : 0;
            let mediaPrecioP4TG =
                datosMargenes.total_P4[0].contador > 0
                    ? datosMargenes.total_P4[0].precio_TG /
                      datosMargenes.total_P4[0].contador
                    : 0;
            let mediaPrecioP5TG =
                datosMargenes.total_P5[0].contador > 0
                    ? datosMargenes.total_P5[0].precio_TG /
                      datosMargenes.total_P5[0].contador
                    : 0;
            let mediaPrecioP6TG =
                datosMargenes.total_P6[0].contador > 0
                    ? datosMargenes.total_P6[0].precio_TG /
                      datosMargenes.total_P6[0].contador
                    : 0;
            if (mediaPrecioP1TG > 0) elemMedios++;
            if (mediaPrecioP2TG > 0) elemMedios++;
            if (mediaPrecioP3TG > 0) elemMedios++;
            if (mediaPrecioP4TG > 0) elemMedios++;
            if (mediaPrecioP5TG > 0) elemMedios++;
            if (mediaPrecioP6TG > 0) elemMedios++;
            let mediaPrecioTotalTG = ( elemMedios === 0 ? 0 : (
                (mediaPrecioP1TG +
                    mediaPrecioP2TG +
                    mediaPrecioP3TG +
                    mediaPrecioP4TG +
                    mediaPrecioP5TG +
                    mediaPrecioP6TG) /
                elemMedios));
            
            let tmp4 = {
                p1: mediaPrecioP1TG,
                p2: mediaPrecioP2TG,
                p3: mediaPrecioP3TG,
                p4: mediaPrecioP4TG,
                p5: mediaPrecioP5TG,
                p6: mediaPrecioP6TG,
                total: mediaPrecioTotalTG ? mediaPrecioTotalTG : 0,
            };
            setPrecioPotenciaTG(tmp4);

            //////////////////////////////////////////////////////////
            // Seteamos los globales
            let tmpGlboales = {
                consumoTotal: datosMargenes.total_consumo_fijo
                    ? datosMargenes.total_consumo_fijo
                    : 0,
                porcentajeCubierto: datosMargenes.porcentajeCubierto
                    ? datosMargenes.porcentajeCubierto
                    : 0,
                porcentajeCubiertoTopeGas:
                    datosMargenes.porcentajeCubiertoTopeGas
                        ? datosMargenes.porcentajeCubiertoTopeGas
                        : 0,
                periodoCubierto: datosMargenes.periodoCubierto
                    ? datosMargenes.periodoCubierto
                    : 0,
                periodoCubiertoTopeGas: datosMargenes.periodoCubiertoTopeGas
                    ? datosMargenes.periodoCubiertoTopeGas
                    : 0,
                diasPeriodo: datosMargenes.diasPeriodo
                    ? datosMargenes.diasPeriodo
                    : 0,
                margen: 0,
            };
            setDatosGlobales(tmpGlboales);
            //////////////////////////////////////////////////////////

            // Calculamos el total de la cobertura de OMIE
            totalCoberturaOMIE = datosMargenes.periodoCubierto * (mediaPrecioTotalOMIE - precioMargen);
            setTotalCoberturaFijoOMIE(
                totalCoberturaOMIE ? totalCoberturaOMIE : 0
            );

            // Calculamos el total de la cobertura de TG
            totalCoberturaTG = datosMargenes.periodoCubiertoTopeGas * mediaPrecioTotalTG;

            setTotalCoberturaFijoTG(totalCoberturaTG ? totalCoberturaTG : 0);

            
            const totalCoberturaFijo = totalCoberturaOMIE + totalCoberturaTG;

            totalCobertura = totalCoberturaOMIE + totalCoberturaTG 
            + totalServicio +  totalDescuentos + totalPotencia ;
            
            setTotalCoberturaFijo(totalCoberturaFijo);
            setTotalCobertura(datosMargenes.total_margen + totalCoberturaFijo);

            // Añadimos el temporal
            tmp = {
                numElementosTmp: total_documentos,
                numElementosLuz: total_documentos_luz,
                consumoTotal: [
                    {
                        _id: "consumo",
                        consumo: datosMargenes.total_consumo,
                    },
                ],
            };
            setTemporales(tmp);
            setTotalMargen(datosMargenes.total_margen);
            setTotalPotencia(datosMargenes.margen_potencia);
            setTotalEnergia(datosMargenes.margen_energia + totalCoberturaFijo );
            setTotalDescuentos(datosMargenes.descuentos_recargos);
            setTotalServicio(datosMargenes.servicios);
            setTotalFacturado(datosMargenes.total_facturado);
            setTotalCosteEnergia(datosMargenes.total_energia);
            setTotalCostePotencia(datosMargenes.total_potencia);
            if (aplicaFiltro) {
                // console.log('DATOS :>> ', datosMargenes.total_consumo, consumoTotalFiltro, datosMargenes.porcentajeCubierto, mediaPrecioTotalOMIE, precioMargen, datosMargenes.porcentajeCubiertoTopeGas, mediaPrecioTotalTG);

                let consumoTotalFiltro = datosMargenes.total_consumo;

                setConsumoTotalFiltro(
                    consumoTotalFiltro ? consumoTotalFiltro : 0
                );

                // Calculamos el total de la cobertura de OMIE
                // totalCoberturaOMIE = consumoTotalFiltro * (datosMargenes.porcentajeCubierto / 100) * (mediaPrecioTotalOMIE - precioMargen); //ORIGINAL
                totalCoberturaOMIE = (datosMargenes.periodoCubierto) * (mediaPrecioTotalOMIE - precioMargen);
                
                setTotalCoberturaFijoOMIE( totalCoberturaOMIE ? totalCoberturaOMIE : 0 );

                // Calculamos el total de la cobertura de TG
                totalCoberturaTG = consumoTotalFiltro * (datosMargenes.porcentajeCubiertoTopeGas / 100) * mediaPrecioTotalTG;
                setTotalCoberturaFijoTG( totalCoberturaTG ? totalCoberturaTG : 0 );

                totalCobertura = totalCoberturaOMIE + totalCoberturaTG;

                setTotalCoberturaFiltro(totalCobertura ? totalCobertura : 0);

                setTotalCobertura( totalCobertura + datosMargenes.total_margen );
                setTotalCoberturaFiltroOMIE( totalCoberturaOMIE ? totalCoberturaOMIE : 0 );
                setTotalCoberturaFiltroTG( totalCoberturaTG ? totalCoberturaTG : 0 );

                console.log(
                    "aplicamos filtros :>> ",
                    totalCobertura,
                    totalCoberturaOMIE,
                    totalCoberturaTG
                );
            }
        } else {
            // PERIODO EMISION
            setTotalElementos(datosMargenes.datos.length);

            let consumoTotalFiltro = 0;

            //////////////////////////////////////////////////////////
                setTotalMargen(datosMargenes.totales.totalMargen);
                setTotalPotencia(datosMargenes.totales.totalPotencia);
                // setTotalEnergia(datosMargenes.totales.totalEnergia);
                setTotalDescuentos(datosMargenes.totales.totalDescuentos);
                setTotalServicio(datosMargenes.totales.totalServicio);
                setTotalFacturado(datosMargenes.totales.totalFacturado);
    
                setTotalCosteEnergia(datosMargenes.totales.totalCosteEnergia);
                setTotalCostePotencia(datosMargenes.totales.totalCostePotencia);
            //////////////////////////////////////////////////////////
            if (aplicaFiltro) setConsumoTotalFiltro(datosMargenes.totales.consumoTotalFiltro);

            // console.log('consumoTotalFiltro :>> ', consumoTotalFiltro);

            //////////////////////////////////////////////////////////
            // Seteamos los globales
            let tmpGlboales = {
                consumoTotal: datosMargenes.consumoTotal
                    ? datosMargenes.consumoTotal
                    : 0,
                porcentajeCubierto: datosMargenes.porcentajeCubierto
                    ? datosMargenes.porcentajeCubierto
                    : 0,
                porcentajeCubiertoTopeGas:
                    datosMargenes.porcentajeCubiertoTopeGas
                        ? datosMargenes.porcentajeCubiertoTopeGas
                        : 0,
                periodoCubierto: datosMargenes.periodoCubierto
                    ? datosMargenes.periodoCubierto
                    : 0,
                periodoCubiertoTopeGas: datosMargenes.periodoCubiertoTopeGas
                    ? datosMargenes.periodoCubiertoTopeGas
                    : 0,
                diasPeriodo: datosMargenes.diasPeriodo
                    ? datosMargenes.diasPeriodo
                    : 0,
                margen: 0,
            };
            setDatosGlobales(tmpGlboales);
            //////////////////////////////////////////////////////////

            // console.log('datosMargenes.consumoTotal :>> ', datosMargenes.consumoTotal);

            //////////////////////////////////////////////////////////
            // Calculamos la media de la potencia desde p1 a p6
            let tmp = datosMargenes.medias;

            setMediaPotencia(tmp);
            //////////////////////////////////////////////////////////

            //////////////////////////////////////////////////////////
            // Calculamos el precio por potencia
            let precioP1 = 0;
            let precioP2 = 0;
            let precioP3 = 0;
            let precioP4 = 0;
            let precioP5 = 0;
            let precioP6 = 0;

            let precioP1OMIE = 0;
            let precioP2OMIE = 0;
            let precioP3OMIE = 0;
            let precioP4OMIE = 0;
            let precioP5OMIE = 0;
            let precioP6OMIE = 0;

            let precioP1TG = 0;
            let precioP2TG = 0;
            let precioP3TG = 0;
            let precioP4TG = 0;
            let precioP5TG = 0;
            let precioP6TG = 0;

            let precioTotal = 0;
            let precioTotalOMIE = 0;
            let precioTotalTG = 0;

            let contadorP1 = 0;
            let contadorP2 = 0;
            let contadorP3 = 0;
            let contadorP4 = 0;
            let contadorP5 = 0;
            let contadorP6 = 0;
            let contadorTotal = 0;
            const potenciaResult = datosMargenes.potencias;
                
            contadorP1 = potenciaResult.P1.count;
            precioP1 = potenciaResult.P1.sumPrecio;
            precioP1OMIE = potenciaResult.P1.sumPrecioOMIE;
            precioP1TG = potenciaResult.P1.sumPrecioTG;

            contadorP2 = potenciaResult.P2.count;
            precioP2 = potenciaResult.P2.sumPrecio;
            precioP2OMIE = potenciaResult.P2.sumPrecioOMIE;
            precioP2TG = potenciaResult.P2.sumPrecioTG;

            contadorP3 = potenciaResult.P3.count;
            precioP3 = potenciaResult.P3.sumPrecio;
            precioP3OMIE = potenciaResult.P3.sumPrecioOMIE;
            precioP3TG = potenciaResult.P3.sumPrecioTG;

            contadorP4 = potenciaResult.P4.count;
            precioP4 = potenciaResult.P4.sumPrecio;
            precioP4OMIE = potenciaResult.P4.sumPrecioOMIE;
            precioP4TG = potenciaResult.P4.sumPrecioTG;

            contadorP5 = potenciaResult.P5.count;
            precioP5 = potenciaResult.P5.sumPrecio;
            precioP5OMIE = potenciaResult.P5.sumPrecioOMIE;
            precioP5TG = potenciaResult.P5.sumPrecioTG;

            contadorP6 = potenciaResult.P6.count;
            precioP6 = potenciaResult.P6.sumPrecio;
            precioP6OMIE = potenciaResult.P6.sumPrecioOMIE;
            precioP6TG = potenciaResult.P6.sumPrecioTG;
            
            contadorTotal = potenciaResult.TOTALES.count;
            precioTotal = potenciaResult.TOTALES.sumPrecio;
            precioTotalOMIE = potenciaResult.TOTALES.sumPrecioOMIE;
            precioTotalTG = potenciaResult.TOTALES.sumPrecioTG;

            // TOTALES
            let mediaPrecioP1 = contadorP1 === 0 ? 0 : precioP1 / contadorP1;
            let mediaPrecioP2 = contadorP2 === 0 ? 0 : precioP2 / contadorP2;
            let mediaPrecioP3 = contadorP3 === 0 ? 0 : precioP3 / contadorP3;
            let mediaPrecioP4 = contadorP4 === 0 ? 0 : precioP4 / contadorP4;
            let mediaPrecioP5 = contadorP5 === 0 ? 0 : precioP5 / contadorP5;
            let mediaPrecioP6 = contadorP6 === 0 ? 0 : precioP6 / contadorP6;
            let mediaPrecioTotal =
                contadorTotal === 0 ? 0 : precioTotal / contadorTotal;

            let tmp2 = {
                p1: mediaPrecioP1,
                p2: mediaPrecioP2,
                p3: mediaPrecioP3,
                p4: mediaPrecioP4,
                p5: mediaPrecioP5,
                p6: mediaPrecioP6,
                total: mediaPrecioTotal ? mediaPrecioTotal : 0,
            };

            setPrecioPotencia(tmp2);

            // OMIE
            let mediaPrecioP1OMIE = contadorP1 === 0 ? 0 : precioP1OMIE / contadorP1;
            let mediaPrecioP2OMIE = contadorP2 === 0 ? 0 : precioP2OMIE / contadorP2;
            let mediaPrecioP3OMIE = contadorP3 === 0 ? 0 : precioP3OMIE / contadorP3;
            let mediaPrecioP4OMIE = contadorP4 === 0 ? 0 : precioP4OMIE / contadorP4;
            let mediaPrecioP5OMIE = contadorP5 === 0 ? 0 : precioP5OMIE / contadorP5;
            let mediaPrecioP6OMIE = contadorP6 === 0 ? 0 : precioP6OMIE / contadorP6;
            let mediaPrecioTotalOMIE = contadorTotal === 0 ? 0 : precioTotalOMIE / contadorTotal;

            let tmp3 = {
                p1: mediaPrecioP1OMIE,
                p2: mediaPrecioP2OMIE,
                p3: mediaPrecioP3OMIE,
                p4: mediaPrecioP4OMIE,
                p5: mediaPrecioP5OMIE,
                p6: mediaPrecioP6OMIE,
                total: mediaPrecioTotalOMIE,
            };

            setPrecioPotenciaOMIE(tmp3);

            // Tope de Gas (TG)
            let mediaPrecioP1TG = contadorP1 === 0 ? 0 : precioP1TG / contadorP1;
            let mediaPrecioP2TG = contadorP2 === 0 ? 0 : precioP2TG / contadorP2;
            let mediaPrecioP3TG = contadorP3 === 0 ? 0 : precioP3TG / contadorP3;
            let mediaPrecioP4TG = contadorP4 === 0 ? 0 : precioP4TG / contadorP4;
            let mediaPrecioP5TG = contadorP5 === 0 ? 0 : precioP5TG / contadorP5;
            let mediaPrecioP6TG = contadorP6 === 0 ? 0 : precioP6TG / contadorP6;
            let mediaPrecioTotalTG = contadorTotal === 0 ? 0 : precioTotalTG / contadorTotal;

            let tmp4 = {
                p1: mediaPrecioP1TG,
                p2: mediaPrecioP2TG,
                p3: mediaPrecioP3TG,
                p4: mediaPrecioP4TG,
                p5: mediaPrecioP5TG,
                p6: mediaPrecioP6TG,
                total: mediaPrecioTotalTG,
            };

            setPrecioPotenciaTG(tmp4);


            // Calculamos el total de la cobertura de OMIE
            totalCoberturaOMIE = datosMargenes.periodoCubierto * (mediaPrecioTotalOMIE - precioMargen);
            setTotalCoberturaFijoOMIE(totalCoberturaOMIE);
            // Calculamos el total de la cobertura de TG
            totalCoberturaTG =
                datosMargenes.periodoCubiertoTopeGas * mediaPrecioTotalTG;
            setTotalCoberturaFijoTG(totalCoberturaTG);

            // ___totalCobertura = __totalCoberturaOMIE + __totalCoberturaTG;
            // ___totalCobertura = __totalCoberturaOMIE + __totalCoberturaTG 
            // + datosMargenes.totales.totalServicio +  datosMargenes.totales.totalDescuentos + datosMargenes.totales.totalPotencia ;

            let totalCoberturaFijo = (totalCoberturaOMIE + totalCoberturaTG ) ;
            setTotalEnergia( datosMargenes.totales.totalEnergia + totalCoberturaFijo ) // Margen energia

             //Margen sin coberturas es datosMargenes.totales.totalMargen.
             //totalMargen ya viene con -> margen energia + margen potencia + servicios + descuentos;

            setTotalCoberturaFijo( totalCoberturaFijo  ); // Margen cobertura
            setTotalCobertura( datosMargenes.totales.totalMargen + totalCoberturaFijo ); // Margen con coberturas 

            if (aplicaFiltro) {
                // Calculamos el total de la cobertura de OMIE
                // totalCoberturaOMIE = consumoTotalFiltro * (datosMargenes.porcentajeCubierto / 100) * (mediaPrecioTotalOMIE - precioMargen); // ORIGINAL
                totalCoberturaOMIE = (datosMargenes.periodoCubierto) * (mediaPrecioTotalOMIE - precioMargen);
                setTotalCoberturaFijoOMIE( totalCoberturaOMIE ? totalCoberturaOMIE : 0);

                // Calculamos el total de la cobertura de TG
                totalCoberturaTG = consumoTotalFiltro * (datosMargenes.porcentajeCubiertoTopeGas / 100) * mediaPrecioTotalTG;
                setTotalCoberturaFijoTG( totalCoberturaTG ? totalCoberturaTG : 0 );

                totalCobertura = totalCoberturaOMIE + totalCoberturaTG;

                setTotalCoberturaFiltro( totalCobertura ? totalCobertura : 0);
                
                setTotalCobertura( totalCobertura + datosMargenes.totales.totalMargen );
                setTotalCoberturaFiltroOMIE( totalCoberturaOMIE ? totalCoberturaOMIE : 0);
                setTotalCoberturaFiltroTG( totalCoberturaTG ? totalCoberturaTG : 0 );
            } else {
                // Limpiamos los valores de los filtros
                setConsumoTotalFiltro(0);
                setTotalCoberturaFiltro(0);
                setTotalCoberturaFiltroOMIE(0);
                setTotalCoberturaFiltroTG(0);
            }
        }


        let porcentaje =
            totalCosteEnergia > 0
                ? (totalEnergia / totalCosteEnergia) * 100
                : 0;
        setTotalPorcentajeEnergia(porcentaje);

        porcentaje =
        totalCostePotencia > 0
                ? (totalPotencia / totalCostePotencia) * 100
                : 0;
        setTotalPorcentajePotencia(porcentaje);

        porcentaje =
            totalFacturado > 0 ? (totalMargen / totalFacturado) * 100 : 0;
        setTotalPorcentajeMargen(porcentaje);

        porcentaje =
            totalFacturado > 0 ? (totalCobertura / totalFacturado) * 100 : 0;
        setTotalPorcentajeCobertura(porcentaje);

        // console.log('totalCobertura, totalMargen, totalFacturado :>> ', totalCobertura, totalMargen, totalFacturado);

        porcentaje =
            totalFacturado > 0
                ? ((totalCobertura + totalMargen) / totalFacturado) * 100
                : 0;
        setPorcentajeTotal(porcentaje ? porcentaje : 0);

        setCargando(false);
        mensaje("success", "Cálculo realizado correctamente.");
    };

    const limpiar = async () => {
        setMargenes([]);

        setFacturas([]);
        setDnis([]);
        setCups([]);
        setTarifas([]);
        setAtrs([]);

        setOrigenes("Todos");
        setFijo("Ambos");
        setLuz("Ambos");

        setPeriodoEmision("");
        setPeriodoFacturacion("");

        setTotalMargen(0);
        setTotalPotencia(0);
        setTotalEnergia(0);
        setTotalDescuentos(0);
        setTotalServicio(0);
        setTotalFacturado(0);

        setTotalPorcentajeMargen(0);

        let tmp = {
            p1: 0,
            p2: 0,
            p3: 0,
            p4: 0,
            p5: 0,
            p6: 0,
            total: 0,
        };

        setMediaPotencia(tmp);
        setTotalCosteEnergia(0);
        setTotalPorcentajeEnergia(0);
        setTotalCostePotencia(0);
        setTotalPorcentajePotencia(0);
        setPorcentajeTotal(0);

        setPrecioPotencia(tmp);
        setPrecioPotenciaOMIE(tmp);
        setPrecioPotenciaTG(tmp);

        setDatosGlobales({
            consumoTotal: 0,
            porcentajeCubierto: 0,
            porcentajeCubiertoTopeGas: 0,
            periodoCubierto: 0,
            periodoCubiertoTopeGas: 0,
            diasPeriodo: 0,
        });

        setTotalCobertura(0);

        setTotalCoberturaFijo(0);
        setTotalCoberturaFijoOMIE(0);
        setTotalCoberturaFijoTG(0);

        setTemporales(null);

        setTotalElementos(0);

        setConsumoTotalFiltro(0);
        setTotalCoberturaFiltro(0);
        setTotalCoberturaFiltroOMIE(0);
        setTotalCoberturaFiltroTG(0);
    };

    const paginar = async (page, pageSize) => {
        let body = {
            facturas: facturas,
            dnis: dnis,
            cups: cups,
            tarifas: tarifas,
            atrs: atrs,
            origenes: origenes,
            fijo: fijo,
            luz: luz,
            periodoFacturacion: periodoFacturacion,
            periodoEmision: periodoEmision,
            descuentos: descuentos,
            excluyente: excluyente,
            pagina: page,
            cuantos: pageSize,
        };

        // LISTADO DE MARGENES
        if (periodoFacturacion) {
            const datosMargenes = await apiRequest(
                "post",
                "/datospaginas",
                body,
                true
            );
            setMargenes([]);
        }
    };

    let columnasMargenes = [
        {
            title: "Factura",
            dataIndex: "id_factura",
            render: (value, data) => {
                let url =
                    "https://crm.ganaenergia.com/design/web/pdfjs/web/viewer.html#option=clientes&view=contrato&task=verFacturaGooStoNew&file=";
                return (
                    <a
                        href={url + data.fichero}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {data.id_factura}
                    </a>
                );
            },
            // https://crm.ganaenergia.com/design/web/pdfjs/web/viewer.html#option=clientes&view=contrato&task=verFacturaGooStoNew&file=36462/2022/N_22000071062.pdf
        },
        {
            title: "Fecha creación",
            dataIndex: "fecha_creacion",
            render: (value, data) => {
                return dayjs.unix(data.fecha_creacion).format("DD/MM/YYYY");
            },
        },
        {
            title: () => (periodoEmision ? "Fecha desde" : "Fecha dia"),
            // dataIndex: "fecha_desde",
            render: (value, data) => {
                if (data.fecha_desde) {
                    return dayjs.unix(data.fecha_desde).format("DD/MM/YYYY");
                } else {
                    return dayjs.unix(data.fecha_dia).format("DD/MM/YYYY");
                }
            },
        },
        {
            title: () => (periodoEmision ? "Fecha factura" : "Fecha hasta"),
            // dataIndex: "fecha_hasta",
            render: (value, data) => {
                if (data.fecha_hasta) {
                    return dayjs.unix(data.fecha_hasta).format("DD/MM/YYYY");
                } else {
                    return dayjs.unix(data.fecha_factura).format("DD/MM/YYYY");
                }
            },
        },
        {
            title: "Margen energia",
            dataIndex: "energia.margen_energia",
            render: (value, data) => {
                return numToStr(
                    data.energia.margen_energia.toFixed(2) + " €",
                    true
                );
            },
            sorter: (a, b) => {
                return a.energia.margen_energia - b.energia.margen_energia;
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
        {
            title: "Total energia",
            dataIndex: "energia.coste_energia",
            render: (value, data) => {
                return numToStr(
                    data.energia.coste_energia.toFixed(2) + " €",
                    true
                );
            },
            sorter: (a, b) => {
                return a.energia.coste_energia - b.energia.coste_energia;
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
        {
            title: "% Energia",
            dataIndex: "porcentajeEnergia",
            render: (value, data) => {
                let porcentaje =
                    data.energia.coste_energia <= 0
                        ? 0
                        : (data.energia.margen_energia /
                              data.energia.coste_energia) *
                          100;
                return numToStr(porcentaje.toFixed(2) + " %", true);
            },
            sorter: (a, b) => {
                let p1 =
                    a.energia.coste_energia <= 0
                        ? 0
                        : (a.energia.margen_energia / a.energia.coste_energia) *
                          100;
                let p2 =
                    b.energia.coste_energia <= 0
                        ? 0
                        : (b.energia.margen_energia / b.energia.coste_energia) *
                          100;
                return p1 - p2;
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
        {
            title: "Margen potencia",
            dataIndex: "potencia.margen_potencia",
            render: (value, data) => {
                return numToStr(
                    data.potencia.margen_potencia.toFixed(2) + " €",
                    true
                );
            },
            sorter: (a, b) => {
                return a.potencia.margen_potencia - b.potencia.margen_potencia;
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },

        {
            title: "Total potencia",
            dataIndex: "potencia.coste_potencia",
            render: (value, data) => {
                return numToStr(
                    data.potencia.coste_potencia.toFixed(2) + " €",
                    true
                );
            },
            sorter: (a, b) => {
                return a.potencia.coste_potencia - b.potencia.coste_potencia;
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
        {
            title: "% Energia",
            dataIndex: "porcentajeEnergia",
            render: (value, data) => {
                let porcentaje =
                    data.potencia.coste_potencia <= 0
                        ? 0
                        : (data.potencia.margen_potencia /
                              data.potencia.coste_potencia) *
                          100;
                return numToStr(porcentaje.toFixed(2) + " %", true);
            },
            sorter: (a, b) => {
                let p1 =
                    a.potencia.coste_potencia <= 0
                        ? 0
                        : (a.potencia.margen_potencia /
                              a.potencia.coste_potencia) *
                          100;
                let p2 =
                    b.potencia.coste_potencia <= 0
                        ? 0
                        : (b.potencia.margen_potencia /
                              b.potencia.coste_potencia) *
                          100;
                return p1 - p2;
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
        {
            title: "Servicio",
            dataIndex: "totales.margen_servicio_ganaenergia",
            render: (value, data) => {
                return numToStr(
                    data.totales.margen_servicio_ganaenergia.toFixed(2) + " €",
                    true
                );
            },
            sorter: (a, b) => {
                return (
                    a.totales.margen_servicio_ganaenergia -
                    b.totales.margen_servicio_ganaenergia
                );
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
        {
            title: "Descuentos",
            dataIndex: "totales.descuentos",
            render: (value, data) => {
                return numToStr(
                    data.totales.descuentos.toFixed(2) + " €",
                    true
                );
            },
            sorter: (a, b) => {
                return a.totales.descuentos - b.totales.descuentos;
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
        {
            title: "Margen",
            dataIndex: "margen",
            render: (value, data) => {
                return numToStr(data.totales.margen.toFixed(2) + " €", true);
            },
            sorter: (a, b) => {
                return a.totales.margen - b.totales.margen;
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
        {
            title: "Cobertura",
            dataIndex: "cobertura",
            render: (value, data) => {
                // FUNCION PARA CALCULAR LA COBERTURA USADA
                // consumoTotalFiltro * (datosGlobales.porcentajeCubierto / 100) * (mediaPrecioTotal - precioMargen)
                let porcentajeConsumo =
                    data.consumos.TOTALES.consumo *
                    (datosGlobales.porcentajeCubierto / 100);
                let precioCubierto =
                    porcentajeConsumo *
                    (data.consumos.TOTALES.precio - precioMargen);
                return numToStr(precioCubierto.toFixed(2) + " €", true);
            },
            sorter: (a, b) => {
                let porcentajeConsumoA =
                    a.consumos.TOTALES.consumo *
                    (datosGlobales.porcentajeCubierto / 100);
                let porcentajeConsumoB =
                    b.consumos.TOTALES.consumo *
                    (datosGlobales.porcentajeCubierto / 100);
                let precioCubiertoA =
                    porcentajeConsumoA *
                    (a.consumos.TOTALES.precio - precioMargen);
                let precioCubiertoB =
                    porcentajeConsumoB *
                    (b.consumos.TOTALES.precio - precioMargen);
                return precioCubiertoA - precioCubiertoB;
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
        {
            title: "Total Factura",
            dataIndex: "totales.factura_base_antes_ie",
            render: (value, data) => {
                return numToStr(
                    data.totales.factura_base_antes_ie.toFixed(2) + " €",
                    true
                );
            },
            sorter: (a, b) => {
                return (
                    a.totales.factura_base_antes_ie -
                    b.totales.factura_base_antes_ie
                );
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
        {
            title: "% Margen",
            dataIndex: "porcentaje",
            render: (value, data) => {
                let porcentaje =
                    data.totales.factura_base_antes_ie <= 0
                        ? 0
                        : (data.totales.margen /
                              data.totales.factura_base_antes_ie) *
                          100;
                return numToStr(porcentaje.toFixed(2) + " %", true);
            },
            sorter: (a, b) => {
                let p1 =
                    a.totales.factura_base_antes_ie <= 0
                        ? 0
                        : (a.totales.margen / a.totales.factura_base_antes_ie) *
                          100;
                let p2 =
                    b.totales.factura_base_antes_ie <= 0
                        ? 0
                        : (b.totales.margen / b.totales.factura_base_antes_ie) *
                          100;
                return p1 - p2;
            },
            sortDirections: ["descend", "ascend"],
            align: "right",
        },
    ];

    // console.log('temporales',temporales)

    return (
        <>
            {contextHolder}
            {/* añadir un div para que bloquee cualquier interaccion hasta que el calculo no acabe o se recargue la pagina */}
            <div className="contenedor"></div>
            <Row flex={1}>
                <Col flex={1} className="bloque">
                    <div className="linea">
                        Factura
                        <DebounceSelect
                            className="select"
                            mode="multiple"
                            value={facturas}
                            placeholder="Introduce factura"
                            fetchOptions={(value) =>
                                fetchList(value, "factura")
                            }
                            onChange={(newValue) => {
                                setFacturas(newValue);
                            }}
                            disabled={cargando}
                        />
                    </div>
                    <div className="linea">
                        DNI
                        <DebounceSelect
                            className="select"
                            mode="multiple"
                            value={dnis}
                            placeholder="Introduce DNI"
                            fetchOptions={(value) => fetchList(value, "dni")}
                            onChange={(newValue) => {
                                setDnis(newValue);
                            }}
                            disabled={cargando}
                        />
                    </div>
                    <div className="linea">
                        CUPS
                        <DebounceSelect
                            className="select"
                            mode="multiple"
                            value={cups}
                            placeholder="Introduce cups"
                            fetchOptions={(value) => fetchList(value, "cups")}
                            onChange={(newValue) => {
                                setCups(newValue);
                            }}
                            disabled={cargando}
                        />
                    </div>
                    <div className="linea">
                        Tarifa
                        <DebounceSelect
                            className="select"
                            mode="multiple"
                            value={tarifas}
                            placeholder="Introduce tarifa"
                            fetchOptions={(value) => fetchList(value, "tarifa")}
                            onChange={(newValue) => {
                                setTarifas(newValue);
                            }}
                            start={tarifas.length === 0 ? true : false}
                            disabled={cargando}
                        />
                    </div>
                </Col>
                <Col flex={1} className="bloque">
                    <div className="linea">
                        Atr
                        <DebounceSelect
                            className="select"
                            mode="multiple"
                            value={atrs}
                            placeholder="Introduce atr"
                            fetchOptions={(value) => fetchList(value, "atr")}
                            onChange={(newValue) => {
                                setAtrs(newValue);
                            }}
                            start={atrs.length === 0 ? true : false}
                            disabled={cargando}
                        />
                    </div>
                    <div className="linea">
                        Origen_venta{" "}
                        <Select
                            className="selector"
                            defaultValue={"Todos"}
                            value={origenes}
                            onChange={(value) => {
                                setOrigenes(value);
                            }}
                            disabled={cargando}
                        >
                            <Option value="-1">Todos</Option>
                            <Option value="0">Interno</Option>
                            <Option value="1">Colaborador</Option>
                            <Option value="2">Agencia</Option>
                            <Option value="3">Web</Option>
                        </Select>
                    </div>
                    <div className="linea">
                        Fijo / Indexado
                        <Select
                            className="selector"
                            defaultValue={"Ambos"}
                            value={fijo}
                            onChange={(value) => {
                                setFijo(value);
                            }}
                            disabled={cargando}
                        >
                            <Option value="-1" selected>
                                Ambos
                            </Option>
                            <Option value="0">Fijo</Option>
                            <Option value="1">Indexado</Option>
                        </Select>
                    </div>
                    <div className="linea">
                        Luz / Gas
                        <Select
                            className="selector"
                            defaultValue={"Ambos"}
                            value={luz}
                            onChange={(value) => {
                                setLuz(value);
                            }}
                            disabled={cargando}
                        >
                            <Option value="-1">Ambos</Option>
                            <Option value="0">Luz</Option>
                            <Option value="1">Gas</Option>
                        </Select>
                    </div>
                </Col>
                <Col flex={1} className="bloque">
                    <div className="linea">
                        Periodo facturación
                        <RangePicker
                            className="rangePicker"
                            onChange={(fecha, data1) => {
                                // comprobamos si la diferencia es mayor a 31 dias
                                // si es asi, no dejamos seleccionar
                                let f = fecha ? fecha : "";
                                let finicio = f[0];
                                let ffin = f[1];
                                let diferencia =
                                    dayjs(ffin).diff(dayjs(finicio), "day") + 1;

                                if (diferencia > 31) {
                                    mensaje("error", "El periodo de facturación no puede ser mayor a 31 días");
                                    return;
                                }

                                setPeriodoFacturacion(f);
                                
                            }}
                            disabledDate={(current) => {
                                return (
                                    current &&
                                    current >= dayjs().subtract(5, "days")
                                );
                            }}
                            disabled={periodoEmision !== ""}
                            value={periodoFacturacion}
                            ranges={ranges}
                        ></RangePicker>
                    </div>
                    <div className="linea">
                        Periodo emisión
                        <RangePicker
                            className="rangePicker"
                            onChange={(fecha, data1) => {
                                let f = fecha ? fecha : "";
                                setPeriodoEmision(f);
                            }}
                            disabled={periodoFacturacion !== ""}
                            disabledDate={(current) => {
                                return (
                                    current &&
                                    current >= dayjs().subtract(5, "days")
                                );
                            }}
                            value={periodoEmision}
                            ranges={ranges}
                        ></RangePicker>
                    </div>
                    <div className="linea">
                        Con descuentos
                        <Radio.Group
                            defaultValue={descuentos}
                            className="fullWidth"
                            size="medium"
                            value={descuentos}
                            onChange={(opcion) => {
                                setDescuentos(opcion.target.value);
                            }}
                            disabled={cargando}
                        >
                            <Radio.Button value="-1">Indiferente</Radio.Button>
                            <Radio.Button value="1">Si</Radio.Button>
                            <Radio.Button value="0"> No</Radio.Button>
                        </Radio.Group>
                        Tipo consulta
                        <Radio.Group
                            defaultValue={excluyente}
                            className="fullWidth"
                            size="medium"
                            value={excluyente}
                            onChange={(opcion) => {
                                setExcluyente(opcion.target.value);
                            }}
                            disabled={cargando}
                        >
                            <Radio.Button value="0">
                                Incluyente (... Y ...)
                            </Radio.Button>
                            <Radio.Button value="1">
                                Excluyente (... O ...)
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className="linea right">
                        <Button onClick={limpiar} disabled={cargando}>
                            Limpiar
                        </Button>
                        <Button
                            type="primary"
                            onClick={calcular}
                            disabled={cargando}
                        >
                            Calcular
                        </Button>
                    </div>
                </Col>
            </Row>
            <Divider />
            {!cargando && (
                <>
                    <Row>
                        <Col span={24} style={{ fontSize: "28px" }}>
                            TOTAL FACTURAS:{" "}
                            {temporales
                                ? temporales.numElementosTmp
                                : totalElementos}{" "}
                            {temporales
                                ? `CONSUMO: ${numToStr(
                                      temporales.consumoTotal[0].consumo.toFixed(
                                          2
                                      ),
                                      true
                                  )} kWh CONSUMO PROMEDIO: ${numToStr(
                                      (
                                          temporales.consumoTotal[0].consumo /
                                          (temporales.numElementosLuz
                                              ? temporales.numElementosLuz
                                              : temporales.numElementosTmp)
                                      ).toFixed(2),
                                      true
                                  )} kWh`
                                : ""}
                        </Col>

                        {/* <Col span={6} style={{fontSize:"28px"}}>
                            <Statistic title="Total Facturas" value={temporales ? temporales.numElementosTmp : totalElementos} />
                        </Col>    
                        <Col span={6} style={{fontSize:"28px"}}>
                            <Statistic title="Total Facturas Gas" value={temporales ? temporales.numElementosTmp : totalElementos} />
                        </Col>    
                        <Col span={6} style={{fontSize:"28px"}}>
                            <Statistic title="Total Facturas" value={temporales ? temporales.numElementosTmp : totalElementos} />
                        </Col>    
                        <Col span={6} style={{fontSize:"28px"}}>
                            <Statistic title="Total Facturas" value={temporales ? temporales.numElementosTmp : totalElementos} />
                        </Col>     */}
                    </Row>
                    <Row gutter={24} className="bloque">
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <h3 className="negrita">Margen Energia:</h3>
                            <p className={totalEnergia < 0 ? "negativo" : ""}>
                                {numToStr(totalEnergia.toFixed(2) + " €", true)}
                            </p>

                            <h3 className="negrita">Total Energia:</h3>
                            <p
                                className={
                                    totalCosteEnergia < 0 ? "negativo" : ""
                                }
                            >
                                {numToStr(
                                    totalCosteEnergia.toFixed(2) + " €",
                                    true
                                )}
                            </p>

                            <h3 className="negrita">% Energia:</h3>
                            <p
                                className={
                                    (totalCosteEnergia > 0
                                        ? (totalEnergia / totalCosteEnergia) * 100
                                        : 0) < 0 ? "negativo" : ""
                                }
                            >
                                {numToStr(
                                    (totalCosteEnergia > 0
                                        ? (totalEnergia / totalCosteEnergia) * 100
                                        : 0).toFixed(2) + " %",
                                    true
                                )}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <h3 className="negrita">Margen Potencia:</h3>
                            <p className={totalPotencia < 0 ? "negativo" : ""}>
                                {numToStr(
                                    totalPotencia.toFixed(2) + " €",
                                    true
                                )}
                            </p>
                            <h3 className="negrita">Precio Medio Ponderado:</h3>
                            <p className={totalPotencia < 0 ? "negativo" : ""}>
                                {precioMargen} €/kWh
                            </p>

                            <h3 className="negrita">Total Potencia:</h3>
                            <p
                                className={
                                    totalCostePotencia < 0 ? "negativo" : ""
                                }
                            >
                                {numToStr(
                                    totalCostePotencia.toFixed(2) + " €",
                                    true
                                )}
                            </p>

                            <h3 className="negrita">% Potencia:</h3>
                            <p
                                className={
                                    (totalCostePotencia > 0
                                        ? (totalPotencia / totalCostePotencia) * 100
                                        : 0) < 0
                                        ? "negativo"
                                        : ""
                                }
                            >
                                {numToStr(
                                    (totalCostePotencia > 0
                                        ? (totalPotencia / totalCostePotencia) * 100
                                        : 0).toFixed(2) + " %",
                                    true
                                )}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={2}>
                            <h3 className="negrita">Servicios:</h3>
                            <p className={totalServicio < 0 ? "negativo" : ""}>
                                {numToStr(
                                    totalServicio.toFixed(2) + " €",
                                    true
                                )}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <h3 className="negrita">Descuentos / Recargos:</h3>
                            <p
                                className={
                                    totalDescuentos < 0 ? "negativo" : ""
                                }
                            >
                                {numToStr(
                                    totalDescuentos.toFixed(2) + " €",
                                    true
                                )}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">Margen Sin Coberturas:</h3>
                            <p className={totalMargen < 0 ? "negativo" : ""}>
                                {numToStr(totalMargen.toFixed(2) + " €", true)}
                            </p>
                            <h3 className="negrita">Margen Con Coberturas:</h3>
                            <p className={totalCobertura < 0 ? "negativo" : ""}>
                                {numToStr(totalCobertura.toFixed(2), true)} €
                            </p>
                            {/*<h3 className="negrita">Total:</h3>
                             <p
                                className={
                                    totalCobertura + totalMargen < 0
                                        ? "negativo"
                                        : ""
                                }
                            >
                                {numToStr(
                                    (totalCobertura + totalMargen).toFixed(2),
                                    true
                                )}{" "}
                                €
                            </p> */}
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">Facturado:</h3>
                            <p className={totalFacturado < 0 ? "negativo" : ""}>
                                {numToStr(
                                    totalFacturado.toFixed(2) + " €",
                                    true
                                )}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <h3 className="negrita">% de margen sin coberturas sobre el facturado:</h3>
                            <p
                                className={
                                    (totalFacturado > 0 ? (totalMargen / totalFacturado) * 100 : 0) < 0 ? "negativo" : ""
                                }
                            >
                                {numToStr(
                                    (totalFacturado > 0 ? (totalMargen / totalFacturado) * 100 : 0).toFixed(2) + " %",
                                    true
                                )}
                            </p>
                            <h3 className="negrita">% de margen con coberturas Sobre el facturado:</h3>
                            <p
                                className={
                                    (totalFacturado > 0 ? (totalCobertura / totalFacturado) * 100 : 0) < 0 ? "negativo" : ""
                                }
                            >
                                {numToStr(
                                    (totalFacturado > 0 ? (totalCobertura / totalFacturado) * 100 : 0).toFixed(2) + " %",
                                    true
                                )}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ fontSize: "28px" }}>
                            MEDIA POTENCIAS
                        </Col>
                    </Row>
                    <Row gutter={24} className="bloque">
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">P1:</h3>
                            <p>
                                {numToStr(mediaPotencia.p1.toFixed(2), true) +
                                    " kW"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">P2:</h3>
                            <p>
                                {numToStr(mediaPotencia.p2.toFixed(2), true) +
                                    " kW"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">P3:</h3>
                            <p>
                                {numToStr(mediaPotencia.p3.toFixed(2), true) +
                                    " kW"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">P4:</h3>
                            <p>
                                {numToStr(mediaPotencia.p4.toFixed(2), true) +
                                    " kW"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">P5:</h3>
                            <p>
                                {numToStr(mediaPotencia.p5.toFixed(2), true) +
                                    " kW"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">P6:</h3>
                            <p>
                                {numToStr(mediaPotencia.p6.toFixed(2), true) +
                                    " kW"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <h3 className="negrita">Totales:</h3>
                            <p>
                                {numToStr(
                                    mediaPotencia.total.toFixed(2),
                                    true
                                ) + " kW"}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ fontSize: "28px" }}>
                            PRECIO MEDIO ENERGIAS
                        </Col>
                    </Row>
                    <Row gutter={24} className="bloque">
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">Energía P1:</h3>
                            <p>
                                {numToStr(precioPotencia.p1.toFixed(6), true) +
                                    " €"}
                            </p>
                            <h3 className="negrita">P1 OMIE:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaOMIE.p1.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                            <h3 className="negrita">P1 Tope Gas:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaTG.p1.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">Energía P2:</h3>
                            <p>
                                {numToStr(precioPotencia.p2.toFixed(6), true) +
                                    " €"}
                            </p>
                            <h3 className="negrita">P2 OMIE:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaOMIE.p2.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                            <h3 className="negrita">P2 Tope Gas:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaTG.p2.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">Energía P3:</h3>
                            <p>
                                {numToStr(precioPotencia.p3.toFixed(6), true) +
                                    " €"}
                            </p>
                            <h3 className="negrita">P3 OMIE:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaOMIE.p3.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                            <h3 className="negrita">P3 Tope Gas:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaTG.p3.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">Energía P4:</h3>
                            <p>
                                {numToStr(precioPotencia.p4.toFixed(6), true) +
                                    " €"}
                            </p>
                            <h3 className="negrita">P4 OMIE:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaOMIE.p4.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                            <h3 className="negrita">P4 Tope Gas:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaTG.p4.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">Energía P5:</h3>
                            <p>
                                {numToStr(precioPotencia.p5.toFixed(6), true) +
                                    " €"}
                            </p>
                            <h3 className="negrita">P5 OMIE:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaOMIE.p5.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                            <h3 className="negrita">P5 Tope Gas:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaTG.p5.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={3}>
                            <h3 className="negrita">Energía P6:</h3>
                            <p>
                                {numToStr(precioPotencia.p6.toFixed(6), true) +
                                    " €"}
                            </p>
                            <h3 className="negrita">P6 OMIE:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaOMIE.p6.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                            <h3 className="negrita">P6 Tope Gas:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaTG.p6.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <h3 className="negrita">Totales:</h3>
                            <p>
                                {numToStr(
                                    precioPotencia.total.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                            <h3 className="negrita">Totales OMIE:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaOMIE.total.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                            <h3 className="negrita">Totales Tope Gas:</h3>
                            <p>
                                {numToStr(
                                    precioPotenciaTG.total.toFixed(6),
                                    true
                                ) + " €"}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ fontSize: "28px" }}>
                            COBERTURA FIJO
                        </Col>
                    </Row>
                    <Row gutter={24} className="bloque">
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <h3 className="negrita">Consumo total:</h3>
                            <p>
                                {numToStr(
                                    datosGlobales.consumoTotal.toFixed(2),
                                    true
                                )}{" "}
                                kWh
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <h3 className="negrita">Consumo cubierto OMIE:</h3>
                            <p>
                                {numToStr(
                                    datosGlobales.periodoCubierto.toFixed(2),
                                    true
                                )}{" "}
                                kWh
                            </p>
                            <h3 className="negrita">Consumo cubierto TG:</h3>
                            <p>
                                {numToStr(
                                    datosGlobales.periodoCubiertoTopeGas.toFixed(
                                        2
                                    ),
                                    true
                                )}{" "}
                                kWh
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            {/* <h3 className="negrita">% cubierto:</h3> 
                            <p> 
                                {numToStr(datosGlobales.porcentajeCubierto.toFixed(2), true)} %
                            </p> */}
                            <h3 className="negrita">% cubierto OMIE:</h3>
                            <p>
                                {numToStr(
                                    datosGlobales.porcentajeCubierto.toFixed(2),
                                    true
                                )}{" "}
                                %
                            </p>
                            <h3 className="negrita">% cubierto TG:</h3>
                            <p>
                                {numToStr(
                                    datosGlobales.porcentajeCubiertoTopeGas.toFixed(
                                        2
                                    ),
                                    true
                                )}{" "}
                                %
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={2}>
                            <h3 className="negrita">Dias:</h3>
                            <p>{datosGlobales.diasPeriodo} dias</p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <h3 className="negrita">Margen Cobertura:</h3>
                            <p>
                                {numToStr(totalCoberturaFijo.toFixed(2), true)}{" "}
                                €
                            </p>
                            <h3 className="negrita">Margen Cobertura OMIE:</h3>
                            <p>
                                {numToStr(
                                    totalCoberturaFijoOMIE.toFixed(2),
                                    true
                                )}{" "}
                                €
                            </p>
                            <h3 className="negrita">Margen Cobertura TG:</h3>
                            <p>
                                {numToStr(
                                    totalCoberturaFijoTG.toFixed(2),
                                    true
                                )}{" "}
                                €
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ fontSize: "28px" }}>
                            COBERTURA FILTRO
                        </Col>
                    </Row>
                    <Row gutter={24} className="bloque">
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <h3 className="negrita">Consumo filtro:</h3>
                            <p>
                                {numToStr(consumoTotalFiltro.toFixed(2), true)}{" "}
                                kWh
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <h3 className="negrita">Consumo cubierto OMIE:</h3>
                            <p>
                                {numToStr(
                                    (
                                        consumoTotalFiltro *
                                        (datosGlobales.porcentajeCubierto / 100)
                                    ).toFixed(2),
                                    true
                                )}{" "}
                                kWh
                            </p>
                            <h3 className="negrita">
                                Consumo cubierto Tope Gas:
                            </h3>
                            <p>
                                {numToStr(
                                    (
                                        consumoTotalFiltro *
                                        (datosGlobales.porcentajeCubiertoTopeGas /
                                            100)
                                    ).toFixed(2),
                                    true
                                )}{" "}
                                kWh
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <h3 className="negrita">Margen:</h3>
                            <p>
                                {numToStr(
                                    totalCoberturaFiltro.toFixed(2),
                                    true
                                )}{" "}
                                €
                            </p>
                            <h3 className="negrita">Margen OMIE:</h3>
                            <p>
                                {numToStr(
                                    totalCoberturaFiltroOMIE.toFixed(2),
                                    true
                                )}{" "}
                                €
                            </p>
                            <h3 className="negrita">Margen TG:</h3>
                            <p>
                                {numToStr(
                                    totalCoberturaFiltroTG.toFixed(2),
                                    true
                                )}{" "}
                                €
                            </p>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col flex={1} className="tac">
                            {/* {margenes.length === 0 && <h3>NO HAY DATOS</h3>}
                            {margenes.length > 0 && (
                                <Table
                                    rowKey={(data) => data._id}
                                    loading={cargando}
                                    dataSource={margenes}
                                    columns={columnasMargenes}
                                    scroll={{ y: 560 }}
                                    pagination={{
                                        total: totalElementos,
                                        current: pagina,
                                        onChange: (page, pageSize) => {
                                            setPaginas(page);
                                            setCuantos(pageSize);
                                            paginar(page, pageSize);
                                        },
                                    }}
                                />
                            )} */}
                        </Col>
                    </Row>
                </>
            )}
            {cargando && (
                <div className="fullWidth tac">
                    {" "}
                    <Spin size="large" className="tac" />
                    <br />
                    <br />
                    Calculando
                </div>
            )}
        </>
    );
};

export default MargenesCambiados;
