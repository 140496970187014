
export const generadorColor = (hashtag = true)=>{
	const color = Math.floor(Math.random()*16777215).toString(16);
	if ( hashtag ) return '#' + color
	return color
}

export const shadeGenerator = ({
	color = generadorColor(),
	intensity = 1
}) => {
	const decimalColor = parseInt( color.replace(`#`, ``), 16)
	const darken = (10 * intensity)

	let red = (decimalColor >> 16) - darken
	let blue = (decimalColor & 0x0000ff) - darken
	let green = ((decimalColor >> 8) & 0x00ff) - darken

	if ( red < 0 ) red = 0;
	if ( blue < 0 ) blue = 0;
	if ( green < 0 ) green = 0;

	return {
		light: color,
		dark:`#${(blue | (green << 8) | (red << 16)).toString(16)}`
	};

};
