import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
export default function BoxQuestion({
  loading = true,
  title = "",
  subtitle = "",
  halfSize = false,
  children = <></>,
}) {
  const extraClasses = halfSize === true ? "half-box" : "";
  return (
    <div className={"box-question " + extraClasses}>
      <p className="box-title">
        <b>{loading ? <Skeleton.Input active /> : title}</b>
      </p>
      <p className="box-title">{loading ? <Skeleton active /> : subtitle}</p>
      {loading ? <Skeleton active /> : children}
    </div>
  );
}
