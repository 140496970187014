
import FlatCard from "components/FlatCard/FlatCard";
import React from "react";
import getParamaterURL from "utils/urlParser";


export default function Error() {
	const id = getParamaterURL('id');

	let mensaje;
	switch (id){
		case "soloPuedesUnLead":
			mensaje = <>
				<h1 className="fs6">1️⃣</h1>
				<h1 className="fs5 bold">Ya tienes un lead abierto en otra pestaña.</h1>
				<h1 className="fs3 mt2">Sólo puedes tener un lead abierto.</h1>
				<h1 className="fs1 colGriCla">ID: soloPuedesUnLead</h1>			
			</>
			break;

		default:
			mensaje = <>
				<h1 className="fs6">🔮</h1>
				<h1 className="fs5 bold">Se produjo un error.</h1>
				<h1 className="fs3 mt2"></h1>
				<h1 className="fs1 colGriCla">ID: seProdujoUnError</h1>			
			</>; 
	}


	return (
		<div className="fdc aic pt5">
			<FlatCard mainClassName="jcc tac">
				{mensaje}
				<small className="bold">
					Por favor cierre esta pestaña
				</small>
			</FlatCard>
		</div>
	);
	
};


