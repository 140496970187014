import { useEffect, useState } from "react";
import apiRequest from "utils/apiRequest";

const useTopData = (id) => {
  const [top, setTop] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (id !== null) {
      apiRequest(
        "get",
        `/top/some?filter=${JSON.stringify({ id: Number(id) })}`,
        null,
        true
      )
        .then((response) => {
          if (response.error === undefined) {
            setTop(response[0]);
          } else {
            setError(true);
          }
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    } else {
      setError(true);
    }
  }, [id]);
  return { top, loading, error };
};
export default useTopData;
