import { Button } from "antd";
import Image from "atomicComponents/Image";
import Void from "atomicComponents/Void";
import dayjs from "dayjs";
import React from "react";
import tiposPausa from "utils/tiposPausa";

const { mbxUi, mbxSocket } = require("MobX/root");



export default function compruebaPausa(usuario) {
	
	if (!usuario.pausa) {
			
		mbxUi.modalPausa = null;
		
	} else {
		
		const tipoPausa = tiposPausa[usuario?.pausa?.tipo];
		const datePausa = dayjs.unix(usuario?.pausa?.cuando.ts);
		
		
		const contenidoModalPausa = <div className="fdc aic">
			
			<Image
				src="./img/undraw_a_moment_to_relax_bbpa.svg"
			/>
			
			<Void
				space={2}
			/>
			
			<h1 className="bolder">Estás en pausa</h1>
			<h2 className="colGri">{tipoPausa.emoji} {tipoPausa.nombre}</h2>
			<Void space={2} />
			
			{/* <span className="colGri">
				Tiempo de pausa:
				<span className="ml1 colVerCor">{ dayjs.duration( datePausa.diff(dayjs()) ).humanize() }</span>	
			</span> */}
			
			<span className="colGri">
				Inicio de la pausa:
				<span className="ml1 colVerCor">
					{datePausa.isToday() ? datePausa.format("HH:mm") : datePausa.format("DD/MM/YYYY HH:mm")}
				</span>	
			</span>	
			
			<Void space={2} />
			
			<p className="colRoj fs5 bold">Recuerda pausarte en 3CX</p>
			
			
			<Void
				space={2}
			/>
			
			
			<Button
				className="w100"
				type="primary"
				size="large"
				onClick={ () => mbxSocket.socket.emit("set:usuario_pausa", null) }
			>
				Despausar
			</Button>
			
		</div>
		
		mbxUi.modalPausa = {
			content: contenidoModalPausa,
			closable: false,
			footer: null,
			destroyOnClose: true,
		};
		
	};
	
};
