import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import React from "react"
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Button } from "antd";
import axios from "axios";
import { useRef } from "react";


export const KPIJosep = () => {

    const dataTEST = [
        {
            type: "Tipo 1",
            country: "ES",
            money: 1225,
            unshowed:"Sisi",
            object:{
                text:"holis"
            }
        },
        {
            type: "Tipo 1",
            country: "ES",
            money: 1250,
            unshowed:"Sisi",
            object:{
                text:"holis"
            }
        },
        {
            type: "Tipo 2",
            country: "FR",
            money: -1000,
            unshowed:"Sisi",
            object:{
                text:"holis"
            }
        },
        {
            type: "Tipo 3",
            country: "GER",
            money: 50000,
            unshowed:"Sisi",
            object:{
                text:"holis"
            }
        },
    ]

    const [values, setValues] = useState(dataTEST);
    const gridRef = useRef();

    const [defaultColumns, setdefaultColumns] = useState([
        { field: "type", headerName: "Tipo", rowGroup: true, hide: true, filter: true },
        { field: "country", headerName: "Pais", rowGroup: true,/* hide:true, */filter: true },
        { field: "money", headerName: "Money", filter: false,  aggFunc: "sum"  },
        { field: "object.text", headerName: "Text", filter: false,  aggFunc: "sum"  },
    ]);

    console.log( values );

    useEffect( () => {
        ( async() => {
            const { data } =await axios.get("https://pokeapi.co/api/v2/pokemon/pikachu");
            console.log( data );
            setValues([data]);

        } )();

    }, []);


    useEffect( () => {
        
        console.log( values );

        setdefaultColumns([
            { field : "abilities.ability.name",headerName: "Habilidad",rowGroup:true },
            { field : "base_experience",headerName: "EXP",rowGroup:true },
            { field : "game_indices",headerName: "INDICES",rowGroup:true }
        ])

    }, [values]);

   

   

    

    const onClick = () => {
        
        if ( defaultColumns.find( _f => _f.field === "unshowed")) return;

        setdefaultColumns([...defaultColumns, { field:"unshowed",headerName:"Nuevo",rowGroup: true, filter: true }]);
        gridRef.current.api.redrawRows();
    };

    return (



        <div
            className="ag-theme-alpine"
            style={{ width: "100%", height: 500 }}
        >
            <Button
                onClick={onClick}
                children={"Botón"}
            />
            <AgGridReact
                ref={gridRef}
                columnDefs={defaultColumns}
                rowData={values}
                style={{ width: "100%" }}
                enableCharts={true}
                enableRangeSelection={true}
                defaultColDef={{ sortable: true }} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection="custom"
            />

        </div>


    );

};


