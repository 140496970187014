
import { makeAutoObservable } from "mobx";



export default class MbxSocket {
	
	socket = null; // para hacer mbxSocket.socket.emit(...) o mbxSocket.socket.on("evento", ...)
	ready = false;
	
	
	constructor() {
		makeAutoObservable(this);
		// persist(this, "socket");
	};
	
};
