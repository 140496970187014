
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import Image from "./Image";



/**
 * @typedef {"éxito" | "fracaso" | "meta" | "sobre" | "post"} MapImg
*/

// Convierto un string a una ruta de una imagen
const mapImg = {
	"éxito": "img/undraw_happy_announcement_ac67.svg",
	"fracaso": "img/undraw_cancel_u1it.svg",
	"meta": "img/undraw_finish_line_katerina_limpitsouni_xy20.svg",
	"sobre": "img/undraw_Opened_re_i38e.svg",
	"sobreEnviado": "img/undraw_Mail_sent_re_0ofv.svg",
	"post": "img/undraw_wall_post_83ul",
};



/*
	Ejemplos típicos:
		
	if (!res || res._error) return dispatch({type: "GLO_SET", key: "modal", value: {
		body: "Ha ocurrido un error",
		imgSrc: "fracaso",
	}});
	
	
	dispatch({type: "GLO_SET", key: "modal", value: {
		body: "Se eliminado la alerta correctamente",
		imgSrc: "éxito",
	}});
	
*/



/**
 * @typedef {Object} props
 * @property {string} title Título del modal. Null para que no aparezca.
 * @property {string | MapImg } imgSrc Source de la imagen.
 * @property {string} hImg Altura de la imagen. Por ejemplo "15em".
 * @property {*} body Nodo HTML que será el body del modal, debajo de la imagen.
 * @property {string} bodyClassName ClassName del body.
 * @property {boolean} showModal Si se ve el modal o no.
 * @property {function} setShowModal Setter de `showModal`.
 * @property {function} onOk Función que se lanzará al aceptar el modal.
 * @property {function} onCancel Función que se lanzará al cancelar el modal.
 * @property {boolean} cancelOnOk Establece si se lanzará `onCancel` al ejecutar `onOk`.
 * @property {*} okText Elemento HTML que se renderizará en el botón "Aceptar" del footer.
 * @property {*} footer Elemento HTML que se renderizará en el footer.
*/

/**
 * Modal simple para mostrar mensajes "finalizadores" con una imagen. Por ejemplo: "Acción realizado con éxito".
 * 
 * @param {props} props
*/

export default function SimpleModal({
	title,
	imgSrc,
	hImg,
	body,
	
	bodyClassName = "mt5 fs3 bold tac",
	
	showModal,
	setShowModal = () => {},
	
	onOk = () => setShowModal(null),
	onCancel = () => setShowModal(null),
	
	cancelOnOk = true,
	
	okText = "Aceptar",
	
	footer = null,
	
}) {
	
	if (!footer && footer !== false) footer = (
		<Button
			className="w100"
			type="primary"
			size="large"
			onClick={ () => {
				if (onOk) onOk();
				if (onCancel && cancelOnOk) onCancel();
			}}
		>
			{okText}
		</Button>
	);	
	
	
	
	let encontrado = mapImg[imgSrc];
	if (encontrado) imgSrc = encontrado;
	
	
	return (
		
		<Modal
			destroyOnClose
			title={title}
			centered
			visible={showModal}
			footer={footer}
			onCancel={onCancel}
		>
			{imgSrc && 
				<Image
					src={imgSrc}
					h={hImg}
				/>
			}
			
			<div className={bodyClassName}>
				{body}
			</div>
			
		</Modal>
		
	);
	
};

